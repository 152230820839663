import { useState, useEffect } from 'react';
import {
    Stack,
    Typography,
    TextField,
    Autocomplete,
    Chip,
    InputAdornment,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { enqueueSnackbar, closeSnackbar } from 'notistack';

import { useQuery } from '@tanstack/react-query';
import { searchData } from 'utils/http.js';

import useDebounce from 'hooks/useDebounce.js';
import { getColorRGBA } from 'utils/getColorRGBA.js';

import {
    CustomButton,
    CustomAvatar,
    CustomAlertBlock,
    CustomHighlightText,
} from 'UI';

function getStyles({
    size = 'medium',
    color = 'disabled',
    variant = 'outlined',
    multiline = false,
}) {
    let chosenColor = getColorRGBA(color);

    let css = {
        standard: {
            '& label': {
                borderRadius: '5px',
                px: 1,
                ml: -0.85,
                mt: size === 'small' ? -0.5 : 0.25,
                '&.MuiInputLabel-shrink': {
                    mt: 0,
                },

                '&.Mui-focused': {
                    color: chosenColor,
                },
                ':hover': {
                    color: chosenColor,
                },
                '&.Mui-error': {
                    color: 'rgb(211, 47, 47)',
                },
                '&.Mui-disabled': {
                    color: 'rgb(0,0,0,0.26)',
                },
            },
            '& p': {
                '&.MuiFormHelperText-root': {
                    ml: 0,
                },
            },
            '& .MuiInputBase-root': {
                height: multiline ? 'auto' : size === 'small' ? 33 : 32,
                borderRadius: '5px',
                color: chosenColor,
                ...(size === 'small' && { mt: multiline ? '13px' : 1.5 }),
                ...(multiline === false && { overflow: 'hidden' }),
                ':hover:not(.Mui-disabled, .Mui-error):before': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                },
                '::after': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                    '&:hover': {
                        borderBottom: `1px solid ${chosenColor}`,
                    },
                },
                '&.Mui-disabled:before': {
                    borderBottom: '1px solid rgb(0,0,0,0.26)',
                },
            },
        },
        filled: {
            '& label': {
                borderRadius: '5px',
                px: 1,
                ml: -0.75,
                mt: size === 'small' ? -0.25 : 0,
                '&.MuiInputLabel-shrink': {
                    mt: -0.5,
                },
                '&.Mui-focused': {
                    color: chosenColor,
                },
                ':hover': {
                    color: chosenColor,
                },
                '&.Mui-error': {
                    color: 'rgb(211, 47, 47)',
                },
                '&.Mui-disabled': {
                    color: 'rgb(0, 0, 0, 0.26)',
                },
            },
            '& .MuiFilledInput-root': {
                height: multiline ? 'auto' : size === 'small' ? 48 : 56,
                ...(size === 'small' && {
                    paddingTop: multiline ? '17px' : '19px',
                    '& input': { mt: -0.75 },
                }),
                ...(multiline === false && { overflow: 'hidden' }),
                backgroundColor: 'aliceblue',
                borderRadius: '5px 5px 0 0',
                color: chosenColor,
                ':hover:not(.Mui-disabled, .Mui-focused)': {
                    backgroundColor: 'rgba(0,40,139,0.1)',
                },
                '&.Mui-focused': {
                    backgroundColor: 'aliceblue',
                },
                ':hover:not(.Mui-disabled, .Mui-error):before': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                },
                '::after': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                    '&:hover': {
                        borderBottom: `1px solid ${chosenColor}`,
                    },
                },
                '&.Mui-error:focus': {
                    borderBottom: '1px solid rgb(176, 0, 32)',
                },
                '&.Mui-disabled': {
                    backgroundColor: 'lightgrey',
                },
                '&.Mui-disabled:before': {
                    borderBottom: '1px solid rgb(0,0,0,0.26)',
                },
                '&.Mui-disabled:after': {
                    borderBottom: '1px solid rgb(0,0,0,0.26)',
                },
            },
        },
        outlined: {
            '& label': {
                borderRadius: '5px',
                px: 1,
                ml: -0.75,
                mt: size === 'small' ? -0.75 : 0,
                '&.MuiInputLabel-shrink': {
                    mt: 0,
                },
                '&.Mui-focused': {
                    color: chosenColor,
                },
                ':hover': {
                    color: chosenColor,
                },
                '&.Mui-error': {
                    color: 'rgb(211, 47, 47)',
                },
                '&.Mui-disabled': {
                    color: 'rgb(0,0,0,0.26)',
                },
            },
            '& .MuiOutlinedInput-root': {
                height: multiline ? 'auto' : size === 'small' ? 41 : 56,
                ...(size === 'small' && {
                    padding: multiline ? '6px 8px' : '9px',
                    '& input': { mt: -1 },
                }),
                ...(multiline === false && { overflow: 'hidden' }),
                borderRadius: '5px',
                color: chosenColor,
                '& fieldset': {
                    borderRadius: '5px',
                    borderWidth: '1px',
                },
                '&:hover:not(.Mui-disabled, .Mui-error) fieldset': {
                    border: `1px solid ${chosenColor}`,
                },
                '&.Mui-focused fieldset': {
                    border: `1px solid ${chosenColor}`,
                },
                '&.Mui-error fieldset': {
                    border: '1px solid rgb(211, 47, 47)',
                },
                '&.Mui-disabled fieldset': {
                    border: '1px solid rgb(0,0,0,0.26)',
                },
            },
        },
    };

    return css[variant];
}
function TemplateOption({
    label,
    sublabel = null,
    compareString,
    icon = null,
    avatar = null,
    useHighlight = true,
    selected = false,
    ...otherProps
}) {
    return (
        <Stack
            direction="row"
            alignItems="flex-start"
            spacing={1}
            width="100%"
            sx={{
                p: 1,
                ':hover': {
                    backgroundColor: '#EDEDED',
                },
            }}>
            {icon && <CustomAvatar>{icon}</CustomAvatar>}
            {avatar &&
                (typeof avatar === 'string' ? (
                    <CustomAvatar
                        size={100}
                        variant="rounded"
                        alt="avatar-data-thumbnail"
                        src={avatar}
                        styleProps={{ p: 1 }}
                    />
                ) : (
                    avatar
                ))}
            <Stack justifyContent="flex-start" width="100%">
                {label && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%">
                        <CustomHighlightText
                            label={label}
                            keyword={useHighlight ? compareString : ''}
                        />
                        {selected && (
                            <span
                                key={
                                    'parts-selected-value' + label
                                }>{`✔ `}</span>
                        )}
                    </Stack>
                )}
                {sublabel &&
                    (typeof sublabel === 'string' ? (
                        <Typography
                            variant="caption"
                            fontStyle="italic"
                            whiteSpace="normal">
                            {sublabel}
                        </Typography>
                    ) : (
                        sublabel
                    ))}
            </Stack>
        </Stack>
    );
}

export default function CustomSearch({
    name = 'name', // required
    id = `${name}-${Math.floor(Math.random() * 1000)}-custom-search`,
    pathname = '/...', // required
    value, // required if controlled (initialState = null if !multiple and = [] if multiple)
    onChange = () => {}, // required if controlled
    convertArrOptions = (value) => value,
    /*
        required, this should be convert data from back end to 
        [{
            value: 'test',                   required, must be unique value
            label: 'Content',                required
            sublabel,                        optional, use for TemplateOption, accept: string, node
            avatar                           optional, use for TemplateOption, accept: string, node
            icon                             optional, use for TemplateOption
        }...]
    */
    multiple, // required
    multiline, // required
    disabled = false,
    readOnly = false,
    required = false,
    fullWidth = true,
    disableCloseOnSelect = true,
    clearOnEscape = true,
    label = '',
    error = '',
    helperText = '',
    placeholder = '',
    size = 'medium', // accepted: medium, small
    color = 'default', // accepted: default, success, error, info, warning
    margin = 'normal', // accepted: normal, dense, none
    limitTags = 10,
    variant = 'outlined', // accepted: filled, outlined, standard
    inputProps,
    InputLabelProps,
    inputRef,
    useHighlight = true, // use for highlight characters match inputValue
    initialText = <Typography>Nhập từ khóa tìm kiếm...</Typography>,
    allowAddNewValue = false, // allow display box add new value if no option matchs
    onAddNewValue = () => {},
    styleProps = {},
    getInputValue = () => {},
    startAdornment = null,
    endAdornment = null,
    ...otherProps
}) {
    const [inputValue, setInputValue] = useState('');
    const debouncedInputValue = useDebounce(inputValue, 500);
    const styles = getStyles({
        size,
        color,
        variant,
        multiline: multiple ? true : multiline,
    });

    const {
        data: dataFetching,
        isLoading: isFetching,
        isError: isErrorFetching,
        error: errorFetching,
    } = useQuery({
        queryKey: ['search-data', { pathname, debouncedInputValue }],
        queryFn: ({ signal }) =>
            searchData({
                signal,
                apiUrl: `${
                    pathname.includes('?') ? pathname + '&' : pathname + '?'
                }keyword=${debouncedInputValue.trim()}`,
            }),

        // placeholderData: keepPreviousData,
        staleTime: 5000,
        retry: 0,
        enabled: debouncedInputValue.length > 0,
    });
    useEffect(() => {
        if (isErrorFetching && errorFetching) {
            enqueueSnackbar(
                <CustomAlertBlock
                    id={'search-fail-snackbar'}
                    title={errorFetching.message}
                    children={errorFetching.errorInfo}
                    onClose={() => {
                        closeSnackbar('search-fail-snackbar');
                    }}
                />,
                {
                    key: 'search-fail-snackbar',
                }
            );
        }
    }, [dataFetching, errorFetching, isErrorFetching]);

    return (
        <Autocomplete
            id={id}
            loading={isFetching}
            loadingText="Đang tải..."
            clearOnBlur
            disableCloseOnSelect={disableCloseOnSelect}
            clearOnEscape={clearOnEscape}
            autoHighlight
            getOptionLabel={(option) => option?.label || ''}
            isOptionEqualToValue={(option, value) =>
                option.value === value.value
            }
            disabled={disabled}
            readOnly={readOnly}
            required={required}
            multiple={multiple}
            fullWidth={fullWidth}
            limitTags={limitTags}
            options={convertArrOptions(dataFetching?.data || [])}
            value={value}
            onChange={(event, newValue, reason) => {
                onChange(newValue, reason);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                getInputValue(newInputValue);
            }}
            noOptionsText={
                debouncedInputValue.trim() ? (
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        width="100%"
                        px={1}
                        justifyContent={'space-between'}>
                        <Typography>Không có kết quả phù hợp.</Typography>
                        {allowAddNewValue && (
                            <CustomButton
                                size="small"
                                margin="none"
                                id="add-new-data-custom-button"
                                onClick={onAddNewValue}
                                startIcon={<AddIcon />}>
                                Thêm
                            </CustomButton>
                        )}
                    </Stack>
                ) : (
                    initialText
                )
            }
            clearText="Xóa"
            renderInput={(params) => {
                const helperTextId =
                    helperText && id ? `${id}-helper-text` : undefined;
                return (
                    <TextField
                        variant={variant}
                        {...params}
                        aria-describedby={helperTextId}
                        {...{
                            autoComplete: 'new-password',
                            autoFocus: false,
                            inputRef,
                            placeholder,
                            InputLabelProps,
                            disabled,
                            required,
                            error: Boolean(error),
                            margin,
                            label,
                        }}
                        helperText={error ? error : helperText}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: startAdornment ? (
                                <InputAdornment position="start">
                                    {startAdornment}
                                </InputAdornment>
                            ) : undefined,
                            endAdornment: endAdornment ? (
                                <InputAdornment position="end">
                                    {endAdornment}
                                </InputAdornment>
                            ) : undefined,
                        }}
                        inputProps={{
                            ...params.inputProps,
                            ...inputProps,
                        }}
                        sx={{ ...styles, ...styleProps }}
                    />
                );
            }}
            renderOption={(props, option, { selected }) => {
                const {
                    value: optValue,
                    label: optLabel,
                    sublabel = null,
                    avatar = null,
                    icon = null,
                } = option;
                return (
                    <li
                        {...props}
                        style={{ padding: 0 }}
                        key={optValue + '-' + optLabel}>
                        <TemplateOption
                            label={optLabel}
                            sublabel={sublabel}
                            compareString={debouncedInputValue}
                            avatar={avatar}
                            icon={icon}
                            useHighlight={useHighlight}
                            selected={selected}
                        />
                    </li>
                );
            }}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        size={size}
                        label={option.label}
                        {...getTagProps({ index })}
                    />
                ))
            }
            {...otherProps}
        />
    );
}
