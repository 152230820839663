import { Box, Typography, Grid } from '@mui/material';

import { underlineWithTransition } from 'config/customCSS.js';

import CategoryCardItem from './CategoryCardItem.jsx';

export default function CategorySection({
    subCatgories = [],
    parentCategories = [],
    currentLevel = 1,
    ...otherProps
}) {
    if (!currentLevel) {
        return parentCategories.map((parentCate, index) => {
            return (
                <Box mt={1} key={Math.random() * 1000}>
                    <Typography
                        variant="caption"
                        fontSize="16px"
                        sx={{
                            pl: { xs: 0, sm: 3 },
                            color: 'rgba(0, 0, 139, 0.8)',
                            ...underlineWithTransition,
                        }}
                        fontWeight={500}>
                        {parentCate.name}
                    </Typography>
                    <Box
                        sx={{
                            width: '100%',
                            pl: { xs: 0, sm: 3 },
                            py: 1,
                            pr: { xs: 3, sm: 0 },
                        }}>
                        <Grid container spacing={2}>
                            {subCatgories
                                .filter((i) => i.parentName === parentCate.name)
                                .map((subCate) => {
                                    return (
                                        <Grid
                                            item
                                            xs={6}
                                            md={4}
                                            xl={3}
                                            zeroMinWidth
                                            key={Math.random() * 1000}>
                                            <CategoryCardItem
                                                id={subCate.id}
                                                name={subCate.name}
                                                slug={subCate.slug}
                                            />
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </Box>
                </Box>
            );
        });
    } else if (currentLevel === 1) {
        return (
            <Box sx={{ width: 'calc(100% - 12px)' }}>
                <Grid container spacing={2}>
                    {subCatgories.map((subCate) => {
                        return (
                            <Grid
                                item
                                xs={6}
                                md={4}
                                xl={3}
                                zeroMinWidth
                                key={Math.random() * 1000}>
                                <CategoryCardItem
                                    id={subCate.id}
                                    name={subCate.name}
                                    slug={subCate.slug}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        );
    } else return null;
}
