import { createSlice } from '@reduxjs/toolkit';
import { getAuthToken } from 'utils/auth.js';
import domain from 'config/domain';

const initialState = { value: {} };

const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        updateUserInfo(state, action) {
            state.value = action.payload;
        },
        deleteUserInfo(state) {
            state.value = {};
        },
    },
});

export const fetchUserInfo = () => {
    return async (dispatch) => {
        const fetchData = async (userId) => {
            const response = await fetch(
                domain + `/user/user-info?userId=${userId}`
            );
            if (!response.ok) {
                return;
            }
            const data = await response.json();
            return data;
        };

        try {
            let token = getAuthToken();
            const userId = localStorage.getItem('userId');

            if (!!token && token !== 'EXPIRED' && userId) {
                const response = await fetchData(userId);

                if (response.status === 200) {
                    dispatch(
                        userInfoActions.updateUserInfo(response.data.userInfo)
                    );
                }
            }
        } catch (error) {
            return;
        }
    };
};

export const userInfoActions = userInfoSlice.actions; // name 'userInfoActions' used for dispatch(userInfoActions...);

export default userInfoSlice.reducer;
