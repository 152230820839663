import {
    List,
    ListSubheader,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemAvatar,
    ListItemText,
} from '@mui/material';

export default function CustomList({
    id, // required
    subheader = '',
    options = [],
    /*
        required
        [{
            onClick,            // required if useListItemButton = true
            secondaryAction,    // accpet a node element like icon button
            avatar,             // display avatar at the start 
            icon,               // display icon at the start
            children,           // create a node element like ListItemText...
        }]
    */
    useListItemButton = true,
    alignItems = 'flex-start',
    disablePaddingListItem = false,
    ListProps,
    ...otherProps
}) {
    const StyledListItem = useListItemButton ? ListItemButton : ListItem;
    return (
        <List
            component={useListItemButton ? 'nav' : 'ul'}
            aria-labelledby={id}
            subheader={
                subheader ? (
                    <ListSubheader
                        disableGutters
                        component="li"
                        id={id}
                        key={Math.random() * 1000}>
                        {subheader}
                    </ListSubheader>
                ) : undefined
            }
            {...ListProps}
            {...otherProps}>
            {options.map((opt) => {
                if (!opt) {
                    return null;
                }
                const {
                    onClick = useListItemButton ? () => {} : undefined,
                    ListItemProps = {},
                    secondaryAction = undefined,
                    avatar = null,
                    icon = null,
                    children = <ListItemText></ListItemText>,
                } = opt;
                return (
                    <StyledListItem
                        key={Math.random() * 1000}
                        alignItems={alignItems}
                        onClick={onClick}
                        {...(!useListItemButton && {
                            secondaryAction,
                            disablePadding: disablePaddingListItem,
                        })}
                        {...ListItemProps}>
                        {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
                        {icon && (
                            <ListItemIcon
                                sx={{
                                    minWidth: 20,
                                    mr: 1.5,
                                }}>
                                {icon}
                            </ListItemIcon>
                        )}
                        {children}
                    </StyledListItem>
                );
            })}
        </List>
    );
}
