import { Alert, AlertTitle } from '@mui/material';

export default function CustomAlertBlock({
    id, // required
    title = '', // optional, accept a string or a node
    children = null, // required, accept a string or a node
    severity = 'error', // accepted: error, warning, success, info
    variant = 'standard', // accepted: standard, outlined, filled
    action,
    onClose = () => {}, // required
    ...otherProps
}) {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        onClose();
    };

    return (
        <Alert
            id={id}
            onClose={handleClose}
            severity={severity}
            action={action}
            variant={variant}
            sx={{ width: '100%' }}
            {...otherProps}>
            {title && <AlertTitle>{title}</AlertTitle>}
            {children}
        </Alert>
    );
}
