import {
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    Switch,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { getColorRGBA } from 'utils/getColorRGBA.js';

const StyledSwitch = styled((props) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme, ...props }) => {
    const { size, color = 'disabled', margin } = props;
    let chosenColor = getColorRGBA(color);

    return {
        width: size === 'medium' ? 36 : 29,
        height: size === 'medium' ? 20 : 13,
        padding: 0,
        margin:
            margin === 'normal'
                ? `${size === 'small' ? '6px' : '3px'} 12px`
                : margin === 'dense'
                ? `${size === 'small' ? '6px' : '3px'} 6px`
                : `${size === 'small' ? '6px' : '3px'} 3px`,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 1,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: chosenColor,
                    opacity: 0.8,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: chosenColor,
                border: '6px solid white',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: '#E9E9EA',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.7,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: size === 'medium' ? 18 : 11,
            height: size === 'medium' ? 18 : 11,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    };
});
export default function CustomSwitch({
    options = [],
    /*
        required
        [{
            name: 'test',
            label: 'Content',    // required, accept string only
            checked: true/false, // required
            disabled: true/false,
            required: true/false,
        }]
    */
    onChange, // required
    id, // required
    size = 'medium', // accepted: medium, small
    color = 'default', // accepted: default, success, error, info, warning
    margin = 'normal', // accepted: normal, dense, none
    formLabel = '',
    helperText = '',
    error = '',
    disabled: formDisabled = false,
    required = false,
    labelPlacement = 'end', // accepted: top, bottom, start, end
    //variant // not supported
    row = false,
    FormControlProps,
    FormLabelProps,
    FormGroupProps,
    FormHelperTextProps,
    ...otherProps
}) {
    const handleChange = (event) => {
        let name = event.target.name;
        let checked = event.target.checked;
        const newOptions = options.map((i) =>
            i.name === name ? { ...i, checked: checked } : i
        );
        onChange(newOptions);
    };
    return (
        <FormControl
            component="fieldset"
            variant={'standard'}
            disabled={formDisabled}
            required={required}
            {...FormControlProps}
            {...otherProps}>
            <FormLabel component="legend" {...FormLabelProps}>
                {formLabel}
            </FormLabel>
            {options.length > 0 && (
                <FormGroup row={row} {...FormGroupProps}>
                    {options.map((opt) => {
                        const {
                            name = '',
                            label = '',
                            disabled: formControlDisabled = false,
                            checked = false,
                            required = false,
                        } = opt;
                        return (
                            <FormControlLabel
                                key={name || label}
                                disabled={formDisabled || formControlDisabled}
                                labelPlacement={labelPlacement}
                                sx={{
                                    '&.MuiFormControlLabel-root': {
                                        mx: 0,
                                        my:
                                            margin === 'normal'
                                                ? 1
                                                : margin === 'dense'
                                                ? 0.5
                                                : 0,
                                        display: 'flex',
                                        alignItems:
                                            labelPlacement === 'top' ||
                                            labelPlacement === 'bottom'
                                                ? 'center'
                                                : 'flex-start',
                                    },
                                    ...(labelPlacement === 'start' && {
                                        '.MuiFormControlLabel-label': {
                                            direction: 'rtl',
                                        },
                                    }),
                                }}
                                control={
                                    <StyledSwitch
                                        size={size}
                                        color={color}
                                        margin={margin}
                                        checked={checked}
                                        disableRipple
                                        onChange={handleChange}
                                        name={name || label}
                                    />
                                }
                                label={label + (required ? ' *' : '')}
                            />
                        );
                    })}
                </FormGroup>
            )}
            <FormHelperText id={`${id}-helper-text`} {...FormHelperTextProps}>
                {error ? error : helperText}
            </FormHelperText>
        </FormControl>
    );
}
