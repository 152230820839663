import {
    Divider,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

const PaperProps = {
    elevation: 0,
    sx: {
        minWidth: 200,
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    },
};
export default function CustomMenu({
    id, // required
    anchorEl, // required
    open = false, // required
    onClose = () => {}, // required
    options = [],
    /*
        required
        [{
            //     value: 'example',            // required
            //     disabled: true / false,
            //     selected: true / false,      // needed if controlling the value
            //     onClick,                     // fn to execute when user click, for example: navigate to a page
            //     icon,                        // <Material-icon />
            //     children,                    // accept a text or a node element
            //     useDivider: true/false       // if true, render a divider instead of MenuItem
        }]
    */
    transformOrigin = { horizontal: 'right', vertical: 'top' },
    anchorOrigin = { horizontal: 'right', vertical: 'bottom' },
    ...otherProps
}) {
    return (
        <Menu
            id={id}
            open={open}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            PaperProps={PaperProps}
            {...otherProps}>
            {options.map((opt, idx) => {
                const {
                    useDivider = false,
                    value,
                    disabled: menuDisabled = false,
                    selected = false,
                    onClick = () => {
                        onClose();
                    },
                    icon = null,
                    children = `Menu item ${idx + 1}`,
                } = opt;
                if (useDivider) {
                    return <Divider key={value} />;
                }
                return (
                    <MenuItem
                        key={value}
                        disabled={menuDisabled}
                        selected={selected}
                        onClick={onClick}>
                        {icon && <ListItemIcon>{icon}</ListItemIcon>}
                        {typeof children === 'string' ? (
                            <ListItemText>{children}</ListItemText>
                        ) : (
                            children
                        )}
                    </MenuItem>
                );
            })}
        </Menu>
    );
}
