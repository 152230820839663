import { useEffect, useState } from 'react';

import { Box, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from 'store/userInfo-slice.js';

import SectionDrawer from '../section/SectionDrawer.jsx';
import SectionAppbar from '../section/SectionAppbar.jsx';
import SectionFooter from '../section/SectionFooter.jsx';

import ShopNavbar from '../navbar/ShopNavbar.jsx';
import AdminNavbar from '../navbar/AdminNavbar.jsx';

import LoginDialog from '../../auth/LoginDialog.jsx';
import LogoutDialog from '../../auth/LogoutDialog.jsx';

import { CustomBackToTopButton } from 'UI';

let isInitial = true;

export default function PageLayout({
    route = 'shop',
    children,
    ...otherProps
}) {
    const dimBackground = useSelector((state) => state.system.dimBackground);

    let dispatch = useDispatch();
    const location = useLocation().pathname;
    const theme = useTheme();
    const biggerMD = useMediaQuery(theme.breakpoints.up('md')); // >= 800

    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setDrawerOpen((prevState) => !prevState);
    };
    let navbar = biggerMD ? (
        location.startsWith('/admin') ? (
            <AdminNavbar />
        ) : (
            <ShopNavbar />
        )
    ) : null;

    const [openLoginDialog, setOpenLoginDialog] = useState(false);
    const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

    useEffect(() => {
        if (isInitial) {
            isInitial = false;
            return;
        }
        dispatch(fetchUserInfo());
    }, [dispatch]);

    return (
        <Box
            component="main"
            sx={{ width: '100%', backgroundColor: 'rgb(6, 45, 148, 0.1 )' }}>
            <SectionAppbar
                handleLogin={() => setOpenLoginDialog(true)}
                handleLogout={() => setOpenLogoutDialog(true)}
                toggleDrawer={toggleDrawer}
                route={route}
            />
            <SectionDrawer
                handleLogin={() => setOpenLoginDialog(true)}
                handleLogout={() => setOpenLogoutDialog(true)}
                toggleDrawer={toggleDrawer}
                open={drawerOpen}
            />
            <Toolbar variant="dense" id="back-to-top-anchor" />
            <Toolbar variant="dense" sx={{ mt: { xs: 7, md: 0 } }} />
            <Box
                width="100%"
                sx={{
                    position: 'relative',
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: dimBackground
                            ? 'rgba(0, 0, 0, 0.5)'
                            : 'transparent',
                        transition: 'background-color 0.3s ease',
                        pointerEvents: 'none',
                        zIndex: 200,
                    },
                }}>
                {navbar}
                {children}
                <CustomBackToTopButton />
                <SectionFooter />
            </Box>
            {openLoginDialog && (
                <LoginDialog
                    open={openLoginDialog}
                    onClose={() => {
                        setOpenLoginDialog(false);
                    }}
                />
            )}
            {openLogoutDialog && (
                <LogoutDialog
                    open={openLogoutDialog}
                    onClose={() => {
                        setOpenLogoutDialog(false);
                    }}
                />
            )}
        </Box>
    );
}
