import { InputAdornment } from '@mui/material';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import moment from 'moment';

import { getColorRGBA } from 'utils/getColorRGBA.js';

moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});

function getStyles({
    size = 'medium',
    color = 'disabled',
    variant = 'outlined',
}) {
    let chosenColor = getColorRGBA(color);

    let css = {
        standard: {
            '& label': {
                borderRadius: '5px',
                '&.Mui-focused': {
                    color: chosenColor,
                },
                ':hover': {
                    color: chosenColor,
                },
                '&.Mui-error': {
                    color: 'rgb(211, 47, 47)',
                },
                '&.Mui-disabled': {
                    color: 'rgb(0,0,0,0.26)',
                },
            },
            '& p': {
                '&.MuiFormHelperText-root': {
                    ml: 0,
                },
            },
            '& .MuiInputBase-root': {
                height: size === 'small' ? 29 : 32,
                borderRadius: '5px',
                color: chosenColor,
                ':hover:not(.Mui-disabled, .Mui-error):before': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                },
                '::after': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                    '&:hover': {
                        borderBottom: `1px solid ${chosenColor}`,
                    },
                },
                '&.Mui-disabled:before': {
                    borderBottom: '1px solid rgb(0,0,0,0.26)',
                },
            },
        },
        filled: {
            '& label': {
                borderRadius: '5px',
                px: 1,
                ml: -0.75,
                '&.Mui-focused': {
                    color: chosenColor,
                },
                ':hover': {
                    color: chosenColor,
                },
                '&.Mui-error': {
                    color: 'rgb(211, 47, 47)',
                },
                '&.Mui-disabled': {
                    color: 'rgb(0, 0, 0, 0.26)',
                },
            },
            '& .MuiFilledInput-root': {
                height: size === 'small' ? 48 : 56,
                backgroundColor: 'aliceblue',
                borderRadius: '5px 5px 0 0',
                color: chosenColor,
                ':hover:not(.Mui-disabled, .Mui-focused)': {
                    backgroundColor: 'rgba(0,40,139,0.1)',
                },
                '&.Mui-focused': {
                    backgroundColor: 'aliceblue',
                },
                ':hover:not(.Mui-disabled, .Mui-error):before': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                },
                '::after': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                    '&:hover': {
                        borderBottom: `1px solid ${chosenColor}`,
                    },
                },
                '&.Mui-error:focus': {
                    borderBottom: '1px solid rgb(176, 0, 32)',
                },
                '&.Mui-disabled': {
                    backgroundColor: 'lightgrey',
                },
                '&.Mui-disabled:before': {
                    borderBottom: '1px solid rgb(0,0,0,0.26)',
                },
                '&.Mui-disabled:after': {
                    borderBottom: '1px solid rgb(0,0,0,0.26)',
                },
                '& div.MuiInputAdornment-root': {
                    alignItems: 'start',
                },
            },
        },
        outlined: {
            '& label': {
                borderRadius: '5px',
                backgroundColor: 'white',
                px: 1,
                ml: -0.75,
                '&.Mui-focused': {
                    color: chosenColor,
                },
                ':hover': {
                    color: chosenColor,
                },
                '&.Mui-error': {
                    color: 'rgb(211, 47, 47)',
                },
                '&.Mui-disabled': {
                    color: 'rgb(0,0,0,0.26)',
                },
            },
            '& .MuiOutlinedInput-root': {
                height: size === 'small' ? 41 : 56,
                borderRadius: '5px',
                color: chosenColor,
                '& fieldset': {
                    borderRadius: '5px',
                    borderWidth: '1px',
                },
                '&:hover:not(.Mui-disabled, .Mui-error) fieldset': {
                    border: `1px solid ${chosenColor}`,
                },
                '&.Mui-focused fieldset': {
                    border: `1px solid ${chosenColor}`,
                },
                '&.Mui-error fieldset': {
                    border: '1px solid rgb(211, 47, 47)',
                },
                '&.Mui-disabled fieldset': {
                    border: '1px solid rgb(0,0,0,0.26)',
                },
            },
        },
    };

    return {
        ...css[variant],
        '& input[type=number]::-webkit-inner-spin-button': {
            '-WebkitAppearance': 'none',
            margin: 0,
        },
        '& input[type=number]::-webkit-outer-spin-button ': {
            '-WebkitAppearance': 'none',
            margin: 0,
        },
    };
}
export default function CustomDatePicker({
    name = 'name', // required
    id = `${name}-${Math.floor(Math.random() * 1000)}-custom-date-picker`,
    value, // required if controlled
    onChange = () => {}, // required if controlled
    onError = () => {},
    label = '',
    error = '',
    helperText = '',
    placeholder = '',
    inputProps,
    InputLabelProps,
    inputRef,
    fullWidth = true,
    required = false,
    readOnly = false,
    disabled = false,
    disableFuture = false,
    disablePast = false,
    onBlur,
    onFocus,
    format = 'DD-MM-YYYY',
    minDate = moment('2000-01-01'),
    maxDate = moment('2099-12-31'),
    variant = 'outlined', // accepted: filled, outlined, standard
    color = 'default', // accepted: default, success, error, info, warning
    size = 'medium', // accepted: medium, small
    margin = 'normal', // accepted: normal, dense, none
    startAdornment = null,
    endAdornment = null,
    ...otherProps
}) {
    const styles = getStyles({ size, color, variant });

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                label={label}
                required={required}
                readOnly={readOnly}
                disabled={disabled}
                disableFuture={disableFuture}
                disablePast={disablePast}
                format={format}
                error={Boolean(error)}
                minDate={minDate}
                maxDate={maxDate}
                value={value}
                onChange={(newValue, context) => {
                    onChange(newValue);
                }}
                onError={(newError) => {
                    onError(newError);
                }}
                slotProps={{
                    textField: {
                        helperText: error || helperText,
                        InputLabelProps,
                        variant,
                        size,
                        margin,
                        fullWidth,
                        sx: styles,
                        inputProps,
                    },
                    inputAdornment: {
                        sx: {
                            '& .MuiButtonBase-root': {
                                marginRight: -1,
                            },
                        },
                    },
                    leftArrowIcon: {
                        fontSize: 'small',
                        sx: { color: 'darkblue' },
                    },
                    rightArrowIcon: {
                        fontSize: 'small',
                        sx: { color: 'darkblue' },
                    },
                    switchViewIcon: {
                        fontSize: 'small',
                        sx: { color: 'darkblue' },
                    },
                    previousIconButton: {
                        size: 'medium',
                        sx: {
                            ':hover': {
                                backgroundColor: 'transparent',
                            },
                        },
                    },
                    nextIconButton: {
                        size: 'medium',
                        sx: {
                            ':hover': {
                                backgroundColor: 'transparent',
                            },
                        },
                    },
                    switchViewButton: {
                        size: 'medium',
                        sx: {
                            ':hover': {
                                backgroundColor: 'transparent',
                            },
                        },
                    },
                }}
                dayOfWeekFormatter={(day) => {
                    let dayMapping = {
                        Sun: 'CN',
                        Sat: 'T7',
                        Fri: 'T6',
                        Thu: 'T5',
                        Wed: 'T4',
                        Tue: 'T3',
                        Mon: 'T2',
                    };
                    return dayMapping[moment(day).format('ddd')];
                }}
                startAdornment={
                    startAdornment ? (
                        <InputAdornment position="start">
                            {startAdornment}
                        </InputAdornment>
                    ) : undefined
                }
                endAdornment={
                    endAdornment ? (
                        <InputAdornment position="end">
                            {endAdornment}
                        </InputAdornment>
                    ) : undefined
                }
                {...otherProps}
            />
        </LocalizationProvider>
    );
}
