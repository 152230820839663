import {
    FormControl,
    FormLabel,
    FormControlLabel,
    FormHelperText,
    RadioGroup,
    Radio,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { getColorRGBA } from 'utils/getColorRGBA.js';

const StyledRadio = styled((props) => (
    <Radio focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, ...props }) => {
    const { color = 'disabled', margin } = props;
    let chosenColor = getColorRGBA(color);

    return {
        '&.MuiRadio-root': {
            padding:
                margin === 'normal'
                    ? '9px'
                    : margin === 'dense'
                    ? '5px'
                    : '1px',
            color: 'lightgrey',
            '&.Mui-checked': {
                color: chosenColor,
            },
            '&.Mui-disabled': {
                color: '#E9E9EA',
            },
        },
    };
});

export default function CustomRadio({
    options = [],
    /*
        required
        [{
            value: 'test',
            label: 'Content',
            disabled: true/false
        }]
    */
    value = '', // required
    onChange = () => {}, // required
    id, // required
    size = 'medium', // accepted: medium, small
    color = 'default', // accepted: default, success, error, info, warning
    margin = 'normal', // accepted: normal, dense, none
    icon,
    checkedIcon,
    formLabel = '',
    helperText = '',
    error = '',
    disabled: formDisabled = false,
    required = false,
    labelPlacement = 'end', // accepted: top, bottom, start, end
    row = false,
    // variant // not supported
    FormControlProps,
    FormLabelProps,
    RadioGroupProps,
    // FormGroupProps  // not supported, use RadioGroupProps
    FormHelperTextProps,
    ...otherProps
}) {
    const handleChange = (event) => {
        onChange(event.target.value);
    };
    return (
        <FormControl
            component="fieldset"
            variant={'standard'}
            disabled={formDisabled}
            required={required}
            margin={margin}
            error={Boolean(error)}
            {...FormControlProps}
            {...otherProps}>
            <FormLabel component="legend" {...FormLabelProps}>
                {formLabel}
            </FormLabel>
            {options.length > 0 && (
                <RadioGroup
                    row={row}
                    aria-labelledby={`${id ? id + '-' : ''}radio-buttons-group`}
                    name={`${id ? id + '-' : ''}radio-buttons-group`}
                    value={value}
                    onChange={handleChange}
                    {...RadioGroupProps}>
                    {options.map((opt, idx) => {
                        const {
                            value = `Radio-${idx + 1}`,
                            label = `Radio-${idx + 1}`,
                            disabled: formControlDisabled = false,
                        } = opt;
                        return (
                            <FormControlLabel
                                key={value || label}
                                {...{
                                    disabled:
                                        formDisabled || formControlDisabled,
                                    labelPlacement,
                                }}
                                sx={{
                                    '&.MuiFormControlLabel-root': {
                                        ml: 0,
                                    },
                                }}
                                value={value}
                                control={
                                    <StyledRadio
                                        size={size}
                                        color={color}
                                        margin={margin}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        disableRipple
                                    />
                                }
                                label={label}
                            />
                        );
                    })}
                </RadioGroup>
            )}
            <FormHelperText id={`${id}-helper-text`} {...FormHelperTextProps}>
                {error ? error : helperText}
            </FormHelperText>
        </FormControl>
    );
}
