import { Typography } from '@mui/material';

export default function PageTitle({
    totalProducts = 0,
    cateTitle = '',
    ...otherProps
}) {
    return (
        <Typography component="h1" fontSize="18px" fontWeight={600}>
            {`${cateTitle} (${Number(
                totalProducts
            ).toLocaleString()} sản phẩm)`}
        </Typography>
    );
}
