import { Stack, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { CustomChip, CustomButton } from 'UI';

export default function ActiveFilterBox({
    activeFilterCount = 0,
    dataFilter = {},
    resetFilter = () => {},
    ...otherProps
}) {
    if (activeFilterCount === 0) {
        return null;
    }
    let chips = Object.keys(dataFilter).reduce((acc, key) => {
        acc = acc.concat(
            dataFilter[key].checkList.map((chip) => {
                return (
                    <CustomChip
                        key={Math.random() * 1000}
                        label={chip}
                        styleProps={{
                            '&.MuiChip-root': {
                                border: `none`,
                                backgroundColor: '#EDEDED',
                                color: 'black',
                                ':hover': {
                                    backgroundColor: '#EDEDED',
                                },
                                my: 0.5,
                            },
                        }}
                        deleteIcon={<CloseIcon fontSize="small" />}
                        variant="filled"
                        onDelete={() => {
                            dataFilter[key].onCheck((data) =>
                                data.filter((i) => i !== chip)
                            );
                        }}
                    />
                );
            })
        );
        return acc;
    }, []);
    return (
        <Stack
            sx={{
                backgroundColor: 'white',
                p: 1.5,
                mx: 2,
                mt: 1.5,
                borderRadius: '10px',
            }}
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            spacing={1}>
            <Typography fontSize="16px">{`Lọc theo (${activeFilterCount})`}</Typography>
            {chips}
            <CustomButton
                id={'clear-all-filter-custom-button'}
                variant="base"
                color="blue"
                onClick={resetFilter}
                children={
                    <Typography fontSize="14px" fontWeight={500}>
                        Xóa tất cả
                    </Typography>
                }
            />
        </Stack>
    );
}
