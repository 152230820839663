import LinearProgress, {
    linearProgressClasses,
} from '@mui/material/LinearProgress';

import { getColorRGBA } from 'utils/getColorRGBA.js';

export default function CustomLinearProgress({
    color = getColorRGBA('default'), // accept any color names
    ...otherProps
}) {
    return (
        <LinearProgress
            sx={{
                minHeight: 3,
                borderRadius: 5,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor: 'aliceblue',
                },
                [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: 5,
                    backgroundColor: color,
                },
            }}
            {...otherProps}
        />
    );
}
