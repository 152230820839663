import { Badge } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBadge = styled((props) => <Badge {...props} />)(
    ({ theme, ...props }) => {
        return {
            '& .MuiBadge-badge': {
                backgroundColor: '#44b700',
                color: '#44b700',
                boxShadow: `0 0 0 2px white`,
                '&::after': {
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    animation: 'ripple 1.2s infinite ease-in-out',
                    border: '1px solid currentColor',
                    content: '""',
                },
            },
            '@keyframes ripple': {
                '0%': {
                    transform: 'scale(.8)',
                    opacity: 1,
                },
                '100%': {
                    transform: 'scale(2.4)',
                    opacity: 0,
                },
            },
        };
    }
);

export default function StatusBadge({ children, ...otherProps }) {
    return (
        <StyledBadge
            overlap="circular"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            variant="dot"
            {...otherProps}>
            {children}
        </StyledBadge>
    );
}
