import {
    Stack,
    Card,
    CardContent,
    CardActions,
    Typography,
    Link,
    Box,
} from '@mui/material';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { recentWatchProdActions } from 'store/recentWatchProd-slice.js';

import { borderWithTransition } from 'config/customCSS.js';

import { CustomAvatar, CustomButton } from 'UI';

const customTheme = (outerTheme) =>
    createTheme({
        components: {
            MuiTypography: {
                styleOverrides: {
                    noWrap(styles) {
                        return {
                            whiteSpace: 'initial',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: String(
                                styles.ownerState['data-lines'] || '3'
                            ),
                            WebkitBoxOrient: 'vertical',
                        };
                    },
                },
            },
        },
    });

export default function ProductCard({
    prodData = {},
    viewType = 'vertical',
    ...otherProps
}) {
    let dispatch = useDispatch();
    let {
        _id = Math.random() * 1000,
        category = [],
        sku = '',
        slug = '',
        webName = '',
        primaryImage = {},
        specification = '',
    } = prodData;
    const navigate = useNavigate();
    const theme = useTheme();

    let categoryMaxLevelData =
        category.length > 0
            ? category.reduce((max, current) => {
                  if (!max) {
                      return current;
                  }
                  return current.level > max.level ? current : max;
              }, null)
            : null;
    let categoryName = categoryMaxLevelData?.name || null;
    let categorySlug = categoryMaxLevelData?.slug;
    let categoryId = categoryMaxLevelData?.id || null;

    let href = `/san-pham${slug}`;

    const handleClick = (e) => {
        e.preventDefault();
        dispatch(recentWatchProdActions.addRecentWatch(sku));
        navigate(href);
    };
    return (
        <Card
            variant="outlined"
            key={_id + '-card-product-item'}
            sx={{
                backgroundColor: 'white',
                ...borderWithTransition,
                display: 'flex',
                flexDirection: viewType === 'vertical' ? 'column' : 'row',
                width: '100%',
                borderRadius: '12px',
                // boxShadow: 1,
                border: 'none',
            }}>
            <Link
                component={RouterLink}
                to={href}
                onClick={handleClick}
                underline="none"
                sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: 'black',
                }}>
                <CustomAvatar
                    styleProps={{ mx: 'auto', p: 1 }}
                    variant="square"
                    src={primaryImage?.mainSrc || ''}
                    size={viewType === 'vertical' ? 160 : 200}
                    alt={primaryImage?.altText || ''}
                />
            </Link>
            <Box>
                {specification && (
                    <Stack spacing={1} ml={viewType === 'vertical' ? -2 : 0}>
                        <Typography
                            fontSize={12}
                            sx={
                                viewType === 'vertical'
                                    ? {
                                          p: '5px 20px 5px 32px',
                                          borderRadius: '15px',
                                          backgroundColor: 'aliceblue',
                                          color: 'blue',
                                          width: 'fit-content',
                                          maxWidth: '80%',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                      }
                                    : {
                                          p: '10px 0 8px 18px',
                                          borderRadius: '0 9px 0 9px',
                                          backgroundColor: 'aliceblue',
                                          maxWidth: '100%',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          fontWeight: 500,
                                      }
                            }>
                            {specification}
                        </Typography>
                    </Stack>
                )}
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        mt: viewType === 'vertical' ? 0 : -1,
                    }}>
                    <Link
                        component={RouterLink}
                        to={href}
                        onClick={handleClick}
                        underline="none"
                        sx={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: 'black',
                        }}>
                        <ThemeProvider theme={customTheme(theme)}>
                            <Typography
                                color={'darkblue'}
                                component="h3"
                                noWrap
                                data-lines={'3'}
                                sx={{
                                    textTransform: 'initial',
                                    textAlign: 'left',
                                    height: 62,
                                }}
                                fontSize={14}
                                fontWeight={600}>
                                {webName}
                            </Typography>
                        </ThemeProvider>
                    </Link>
                    <Link
                        component={RouterLink}
                        to={categorySlug}
                        underline="none">
                        <Typography
                            component="h6"
                            sx={{
                                textTransform: 'initial',
                                textAlign: 'left',
                                color: 'grey',
                                ':hover': {
                                    color: 'darkblue',
                                    textDecoration: 'underline',
                                },
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '98%',
                            }}
                            fontSize={14}>
                            {categoryName}
                        </Typography>
                    </Link>
                </CardContent>
                <CardActions disableSpacing>
                    <CustomButton
                        id={_id + '-view-detail-custom-button'}
                        styleProps={{
                            borderRadius: '20px',
                            fontWeight: 500,
                            mx: 1,
                            mb: 0.5,
                            minWidth: '150px',
                        }}
                        fullWidth={viewType === 'vertical'}
                        onClick={handleClick}>
                        Xem chi tiết
                    </CustomButton>
                </CardActions>
            </Box>
        </Card>
    );
}
