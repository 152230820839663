import { useState } from 'react';

import { AppBar, Typography, Stack, useMediaQuery, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    Menu as MenuIcon,
    Person as PersonIcon,
    Logout as LogoutIcon,
    ShoppingBasket as ShoppingBasketIcon,
    PinDrop as PinDropIcon,
    PersonPin as PersonPinIcon,
    EmojiEvents as EmojiEventsIcon,
    ArrowBackIosNew as ArrowBackIosNewIcon,
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { systemActions } from 'store/system-slice.js';

import StatusBadge from '../StatusBadge.jsx';
import SearchAppbar from '../SearchAppbar.jsx';
import { CustomMenu, CustomAvatar, CustomButton } from 'UI';

export default function SectionAppbar({
    route = 'shop',
    handleLogin = () => {},
    toggleDrawer = () => {},
    handleLogout = () => {},
    ...props
}) {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo.value);
    let { _id = null, username = '', tel = '', rewardPoint = null } = userInfo;

    const navigate = useNavigate();
    const theme = useTheme();
    const biggerMD = useMediaQuery(theme.breakpoints.up('md')); // >= 800

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const openMenu = Boolean(menuAnchorEl);

    const [openSearch, setOpenSearch] = useState(false);

    const mobileAppbar = (
        <Stack
            direction="row"
            justifyContent="space-between"
            px={2}
            pb={1}
            pt={{ xs: 1, md: 0 }}
            width="100%"
            sx={{
                display: { xs: openSearch ? 'none' : 'flex', sm: 'flex' },
            }}>
            <Stack justifyContent="center" alignItems="center">
                <CustomButton
                    size="small"
                    useIconButton={true}
                    onClick={toggleDrawer}>
                    <MenuIcon sx={{ color: 'darkblue' }} />
                </CustomButton>
                <Typography variant="caption" color="darkblue">
                    Menu
                </Typography>
            </Stack>
            <CustomAvatar
                variant="square"
                src="../assets/images/khanhtranglogo-02.png"
                styleProps={{
                    cursor: 'pointer',
                    width: 220,
                    height: 80,
                }}
                onClick={() => {
                    if (route === 'admin') {
                        navigate('/admin');
                    } else {
                        navigate('/');
                    }
                }}
            />
            <Stack justifyContent="center" alignItems="center">
                <CustomButton
                    id="account-avatar-icon-button"
                    color="primary"
                    useIconButton={true}
                    onClick={(event) => {
                        if (!_id) {
                            return handleLogin();
                        }
                        setMenuAnchorEl(event.currentTarget);
                    }}
                    aria-controls={openMenu ? 'avatar-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}>
                    {_id ? (
                        <StatusBadge>
                            <CustomAvatar>
                                <PersonPinIcon
                                    fontSize="small"
                                    sx={{ color: 'darkblue' }}
                                />
                            </CustomAvatar>
                        </StatusBadge>
                    ) : (
                        <PersonIcon sx={{ color: 'darkblue' }} />
                    )}
                </CustomButton>
                <Typography
                    variant="caption"
                    color="darkblue"
                    noWrap
                    sx={{
                        maxWidth: 80,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                    {_id ? (username ? username : tel) : 'Đăng nhập'}
                </Typography>
            </Stack>
        </Stack>
    );
    const desktopAppbar = (
        <Stack
            sx={{
                maxWidth: 1260,
                mx: 'auto',
                px: { xs: 0, sm: 3 },
            }}
            direction={'row'}
            justifyContent="center"
            alignItems="flex-start"
            width="100%"
            mt={1}
            pb={1}>
            <Box sx={{ overflow: 'hidden' }}>
                <CustomAvatar
                    src="../assets/images/khanhtranglogo-02.png"
                    variant="square"
                    onClick={() => {
                        if (route === 'admin') {
                            navigate('/admin');
                        } else {
                            navigate('/');
                        }
                    }}
                    styleProps={{
                        marginLeft: '-35px',
                        cursor: 'pointer',
                        width: 200,
                        height: 80,
                    }}
                />
            </Box>
            <SearchAppbar open={openSearch} onOpen={setOpenSearch} />
            <Stack
                justifyContent="center"
                alignItems="center"
                flexShrink={0}
                mt={2}
                mx={1}>
                <CustomButton
                    id="account-avatar-icon-button"
                    size="small"
                    useIconButton={true}
                    onClick={(event) => {
                        if (!_id) {
                            return handleLogin();
                        }
                        setMenuAnchorEl(event.currentTarget);
                    }}
                    aria-controls={openMenu ? 'avatar-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}>
                    {_id ? (
                        <StatusBadge>
                            <CustomAvatar>
                                <PersonPinIcon
                                    fontSize="small"
                                    sx={{ color: 'darkblue' }}
                                />
                            </CustomAvatar>
                        </StatusBadge>
                    ) : (
                        <PersonIcon sx={{ color: 'darkblue' }} />
                    )}
                </CustomButton>
                <Typography
                    variant="caption"
                    color="darkblue"
                    noWrap
                    sx={{
                        maxWidth: 80,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                    {_id ? (username ? username : tel) : 'Đăng nhập'}
                </Typography>
            </Stack>
        </Stack>
    );
    const menuAvatar = (
        <CustomMenu
            id="account-menu"
            anchorEl={menuAnchorEl}
            open={openMenu}
            onClose={() => setMenuAnchorEl(null)}
            options={(rewardPoint
                ? [
                      {
                          value: 'reward-point',
                          onClick: () => {},
                          icon: (
                              <EmojiEventsIcon
                                  fontSize="small"
                                  sx={{ color: 'orange' }}
                              />
                          ),
                          children: `Điểm tích lũy: ${Number(
                              rewardPoint
                          ).toLocaleString()} điểm`,
                      },
                      {
                          value: 'divider-1',
                          useDivider: true,
                      },
                  ]
                : []
            ).concat([
                {
                    value: 'account-information',
                    onClick: () => {
                        // navigate('/tai-khoan/thong-tin-tai-khoan');
                        setMenuAnchorEl(null);
                    },
                    icon: (
                        <PersonIcon fontSize="small" sx={{ color: 'orange' }} />
                    ),
                    children: 'Thông tin tài khoản',
                },
                {
                    value: 'shipping-address',
                    onClick: () => {
                        // navigate('/tai-khoan/dia-chi-nhan-hang');
                        setMenuAnchorEl(null);
                    },
                    icon: (
                        <PinDropIcon
                            fontSize="small"
                            sx={{ color: 'orange' }}
                        />
                    ),
                    children: 'Địa chỉ nhận hàng',
                },
                {
                    value: 'shipping-history',
                    onClick: () => {
                        // navigate('/tai-khoan/lich-su-mua-hang');
                        setMenuAnchorEl(null);
                    },
                    icon: (
                        <ShoppingBasketIcon
                            fontSize="small"
                            sx={{ color: 'orange' }}
                        />
                    ),
                    children: 'Lịch sử mua hàng',
                },
                {
                    value: 'divider-2',
                    useDivider: true,
                },
                {
                    value: 'logout',
                    onClick: () => {
                        handleLogout();
                        setMenuAnchorEl(null);
                    },
                    icon: (
                        <LogoutIcon fontSize="small" sx={{ color: 'orange' }} />
                    ),
                    children: 'Đăng xuất',
                },
            ])}
        />
    );

    return (
        <AppBar
            component="nav"
            sx={{
                '&.MuiAppBar-root': {
                    backgroundColor: openSearch ? 'aliceblue' : 'white',
                    top: 0,
                },
            }}
            elevation={0}
            position="absolute">
            {biggerMD ? desktopAppbar : mobileAppbar}
            {!biggerMD && (
                <Stack
                    p={2}
                    pt={openSearch ? { xs: 2, sm: 0 } : 0}
                    direction="row"
                    alignItems="center"
                    spacing={1}>
                    <CustomButton
                        id="exit-search-appbar-custom-button"
                        variant="base"
                        styleProps={{
                            display: {
                                xs: openSearch ? 'flex' : 'none',
                                sm: 'none',
                            },
                        }}
                        onClick={() => {
                            dispatch(systemActions.toggleDimBackground(false));
                            document.body.style.overflow = '';
                            setOpenSearch(false);
                        }}
                        useIconButton={true}>
                        <ArrowBackIosNewIcon
                            fontSize="small"
                            sx={{ color: 'darkblue' }}
                        />
                    </CustomButton>
                    <SearchAppbar open={openSearch} onOpen={setOpenSearch} />
                </Stack>
            )}
            {openMenu && menuAvatar}
        </AppBar>
    );
}
