import { useState } from 'react';
import { Stack, Box, Typography, Collapse } from '@mui/material';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    FilterListOutlined as FilterListOutlinedIcon,
    Search as SearchIcon,
} from '@mui/icons-material';

import { CustomInput, CustomList, CustomCheckbox } from 'UI';

function replaceVietnameseCharacters(inputString) {
    // Define a mapping of Vietnamese characters to normal characters
    const charMap = {
        á: 'a',
        à: 'a',
        ả: 'a',
        ã: 'a',
        ạ: 'a',
        ă: 'a',
        ắ: 'a',
        ằ: 'a',
        ẳ: 'a',
        ẵ: 'a',
        ặ: 'a',
        â: 'a',
        ấ: 'a',
        ầ: 'a',
        ẩ: 'a',
        ẫ: 'a',
        ậ: 'a',
        é: 'e',
        è: 'e',
        ẻ: 'e',
        ẽ: 'e',
        ẹ: 'e',
        ê: 'e',
        ế: 'e',
        ề: 'e',
        ể: 'e',
        ễ: 'e',
        ệ: 'e',
        í: 'i',
        ì: 'i',
        ỉ: 'i',
        ĩ: 'i',
        ị: 'i',
        ó: 'o',
        ò: 'o',
        ỏ: 'o',
        õ: 'o',
        ọ: 'o',
        ô: 'o',
        ố: 'o',
        ồ: 'o',
        ổ: 'o',
        ỗ: 'o',
        ộ: 'o',
        ơ: 'o',
        ớ: 'o',
        ờ: 'o',
        ở: 'o',
        ỡ: 'o',
        ợ: 'o',
        ú: 'u',
        ù: 'u',
        ủ: 'u',
        ũ: 'u',
        ụ: 'u',
        ư: 'u',
        ứ: 'u',
        ừ: 'u',
        ử: 'u',
        ữ: 'u',
        ự: 'u',
        ý: 'y',
        ỳ: 'y',
        ỷ: 'y',
        ỹ: 'y',
        ỵ: 'y',
        Á: 'A',
        À: 'A',
        Ả: 'A',
        Ã: 'A',
        Ạ: 'A',
        Ă: 'A',
        Ắ: 'A',
        Ằ: 'A',
        Ẳ: 'A',
        Ẵ: 'A',
        Ặ: 'A',
        Â: 'A',
        Ấ: 'A',
        Ầ: 'A',
        Ẩ: 'A',
        Ẫ: 'A',
        Ậ: 'A',
        É: 'E',
        È: 'E',
        Ẻ: 'E',
        Ẽ: 'E',
        Ẹ: 'E',
        Ê: 'E',
        Ế: 'E',
        Ề: 'E',
        Ể: 'E',
        Ễ: 'E',
        Ệ: 'E',
        Í: 'I',
        Ì: 'I',
        Ỉ: 'I',
        Ĩ: 'I',
        Ị: 'I',
        Ó: 'O',
        Ò: 'O',
        Ỏ: 'O',
        Õ: 'O',
        Ọ: 'O',
        Ô: 'O',
        Ố: 'O',
        Ồ: 'O',
        Ổ: 'O',
        Ỗ: 'O',
        Ộ: 'O',
        Ơ: 'O',
        Ớ: 'O',
        Ờ: 'O',
        Ở: 'O',
        Ỡ: 'O',
        Ợ: 'O',
        Ú: 'U',
        Ù: 'U',
        Ủ: 'U',
        Ũ: 'U',
        Ụ: 'U',
        Ư: 'U',
        Ứ: 'U',
        Ừ: 'U',
        Ử: 'U',
        Ữ: 'U',
        Ự: 'U',
        Ý: 'Y',
        Ỳ: 'Y',
        Ỷ: 'Y',
        Ỹ: 'Y',
        Ỵ: 'Y',
        Đ: 'D',
        đ: 'd',
    };

    // Use the replace method with a callback function
    const result = inputString.replace(
        /[^A-Za-z0-9]/g,
        (match) => charMap[match] || match
    );
    return result;
}
function includesTerm(term, comparedString) {
    let replacedCharacterTerm = replaceVietnameseCharacters(term).toLowerCase();
    let replacedCharacterComparedString = replaceVietnameseCharacters(
        comparedString.trim()
    ).toLowerCase();

    return replacedCharacterTerm.includes(replacedCharacterComparedString)
        ? term
        : null;
}
function handleKeyDown(event) {
    const forbiddenKeys = [
        '!',
        '@',
        '#',
        '$',
        '%',
        '^',
        '&',
        '*',
        '(',
        ')',
        '-',
        '_',
        '=',
        '+',
        '[',
        ']',
        '{',
        '}',
        '\\',
        '|',
        ';',
        ':',
        // "'",
        '"',
        ',',
        '.',
        '<',
        '>',
        '/',
        '?',
        '`',
        '~',
    ];

    if (forbiddenKeys.includes(event.key)) {
        event.preventDefault();
    }
}
function CustomListItem({
    idx = 0,
    label = '',
    checkList = [],
    options = [],
    onChange = () => {},
    isCollapsed = false,
    onCollapse = () => {},
    ...otherProps
}) {
    const [keyword, setKeyword] = useState('');
    let filterOptions = options
        .filter((i) =>
            keyword.trim().length > 0 ? includesTerm(i, keyword) : i
        )
        .map((i) => ({
            name: i,
            label: i,
            checked: checkList.indexOf(i) > -1,
        }));
    return (
        <Box width={'100%'} px={1.5} key={label}>
            <Stack
                direction="row"
                sx={{
                    py: 0.5,
                    borderTop: idx !== 0 ? '1px solid #EDEDED' : 'none',
                    cursor: 'pointer',
                }}
                onClick={onCollapse}
                alignItems="center"
                justifyContent="space-between">
                <Typography
                    sx={{
                        pt: 0.5,
                        pl: 1,
                        fontWeight: 500,
                        fontSize: 16,
                    }}>
                    {label}
                </Typography>
                {isCollapsed ? (
                    <KeyboardArrowUpIcon fontSize="small" />
                ) : (
                    <KeyboardArrowDownIcon fontSize="small" />
                )}
            </Stack>
            <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
                {filterOptions.length > 5 && (
                    <CustomInput
                        name={label + '-search-custom-input'}
                        margin="none"
                        size="small"
                        color="black"
                        InputLabelProps={{ shrink: true }}
                        value={keyword}
                        onChange={(value) => {
                            setKeyword(value);
                        }}
                        onKeyDown={handleKeyDown}
                        startAdornment={<SearchIcon fontSize="small" />}
                        placeholder="Tìm theo tên"
                        styleProps={{
                            '& .MuiOutlinedInput-root': {
                                mt: 1,
                                '& fieldset': {
                                    borderRadius: '20px !important',
                                },
                            },
                        }}
                    />
                )}
                <CustomCheckbox
                    id={Math.random() * 1000}
                    variant="filled"
                    FormControlProps={{
                        sx: {
                            mt: 0,
                            mb: 1,
                            minWidth: '100%',
                        },
                    }}
                    options={(filterOptions.length > 3
                        ? [
                              {
                                  name: 'Tất cả',
                                  label: 'Tất cả',
                                  checked: checkList.length === 0,
                              },
                          ]
                        : []
                    ).concat(filterOptions)}
                    onChange={onChange}
                    size="small"
                    margin="dense"
                />
            </Collapse>
        </Box>
    );
}
export default function FilterSidebar({ dataFilter = {}, ...otherProps }) {
    let options = Object.keys(dataFilter)
        .filter((key) => dataFilter[key].options.length > 0)
        .map((key) => ({
            ...dataFilter[key],
            onChange: ({ name, checked }) => {
                if (
                    name === 'Tất cả' ||
                    (checked &&
                        dataFilter[key].options.length > 1 &&
                        dataFilter[key].checkList.length ===
                            dataFilter[key].options.length - 1)
                ) {
                    dataFilter[key].onCheck([]);
                } else {
                    dataFilter[key].onCheck((prevValue) => {
                        let updatedValue = [...prevValue];
                        if (checked) {
                            updatedValue.push(name);
                        } else {
                            updatedValue = updatedValue.filter(
                                (i) => i !== name
                            );
                        }
                        return updatedValue;
                    });
                }
            },
        }));
    return (
        <Stack
            minWidth="250px"
            maxWidth="250px"
            sx={{
                backgroundColor: 'white',
                border: '1px solid #EDEDED',
            }}>
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                px={2}
                pt={1}
                sx={{
                    position: '-webkit-sticky',
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 100,
                    py: 1,
                    borderBottom: '1px solid #EDEDED',
                }}>
                <FilterListOutlinedIcon fontSize="small" />
                <Typography component="h2" fontSize="16px" fontWeight={600}>
                    Bộ lọc nâng cao
                </Typography>
            </Stack>
            <CustomList
                useListItemButton={false}
                disablePaddingListItem={true}
                id="page-product-filter-custom-list"
                ListProps={{
                    sx: { width: '100%' },
                }}
                options={options.map(
                    (
                        {
                            label = '',
                            checkList = [],
                            options = [],
                            onChange = () => {},
                            isCollapsed = false,
                            onCollapse = () => {},
                        },
                        idx
                    ) => {
                        return {
                            children: (
                                <CustomListItem
                                    idx={idx}
                                    label={label}
                                    checkList={checkList}
                                    options={options}
                                    onChange={onChange}
                                    isCollapsed={isCollapsed}
                                    onCollapse={onCollapse}
                                />
                            ),
                        };
                    }
                )}
            />
        </Stack>
    );
}
