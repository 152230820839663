import * as React from 'react';

import {
    Button,
    Box,
    Typography,
    Input,
    TextField,
    Grid,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
    typeBoxSx,
    cellBoxSx,
    cellTypographySx,
    lineBoxSx,
    lineTypographySx,
    boxPropsNameMap,
    typographyPropsNameMap,
} from 'config/customCSS.js';

import { CustomCircularProgress } from 'UI';
import useAWS from 'hooks/useAWS.js';
import { useSnackbar } from 'notistack';

const numberToRoman = (num, uppercase = false) => {
    // num = 0 => return ''
    // num = 1 => return 'i'
    // num = 3 => return 'iii'
    const romanNumerals = [
        { value: 1000, numeral: 'M' },
        { value: 900, numeral: 'CM' },
        { value: 500, numeral: 'D' },
        { value: 400, numeral: 'CD' },
        { value: 100, numeral: 'C' },
        { value: 90, numeral: 'XC' },
        { value: 50, numeral: 'L' },
        { value: 40, numeral: 'XL' },
        { value: 10, numeral: 'X' },
        { value: 9, numeral: 'IX' },
        { value: 5, numeral: 'V' },
        { value: 4, numeral: 'IV' },
        { value: 1, numeral: 'I' },
    ];

    let result = '';

    for (let i = 0; i < romanNumerals.length; i++) {
        const { value, numeral } = romanNumerals[i];
        while (num >= value) {
            result += uppercase
                ? `${numeral}`.toString().toLocaleUpperCase()
                : `${numeral}`.toString().toLocaleLowerCase();
            num -= value;
        }
    }

    return result;
};
const numberToAlphabet = (num, uppercase = false) => {
    // num = 0 => return 'a'
    // num = 3 => return 'd'
    let result = '';

    while (num >= 0) {
        result = String.fromCharCode((num % 26) + 97) + result;
        num = Math.floor(num / 26) - 1;
    }

    return uppercase ? result.toLocaleUpperCase() : result.toLocaleLowerCase();
};
function CustomTextField(props) {
    const {
        details,
        setDetails,
        content,
        typographyProps,
        boxInfo,
        setSelectedBoxData,
    } = props;
    const [isTyping, setIsTyping] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');

    React.useEffect(() => {
        let input;
        if (!isTyping && content && content !== inputValue) {
            setInputValue(content);
        }
        return () => {
            clearTimeout(input);
        };
    }, [content, inputValue, isTyping]);
    // edit content for cell in type table
    React.useEffect(() => {
        let input;
        if (
            content !== inputValue.trim() &&
            boxInfo?.type === 'table' &&
            parseInt(boxInfo?.idxData) >= 0 &&
            parseInt(boxInfo?.rowKey) >= 0 &&
            parseInt(boxInfo?.idxColumn) >= 0 &&
            isTyping
        ) {
            input = setTimeout(() => {
                setDetails((details) =>
                    [].concat(
                        details.slice(0, boxInfo?.idxData),
                        [
                            {
                                ...details[boxInfo?.idxData],
                                rows: {
                                    ...details[boxInfo?.idxData].rows,
                                    [boxInfo?.rowKey]: details[
                                        boxInfo?.idxData
                                    ].rows[boxInfo?.rowKey].map((i, x) =>
                                        x === boxInfo?.idxColumn
                                            ? {
                                                  ...i,
                                                  content: inputValue,
                                              }
                                            : i
                                    ),
                                },
                            },
                        ],
                        details.slice(boxInfo?.idxData + 1, details.length)
                    )
                );
                setIsTyping(false);
            }, 1000);
        }
        return () => {
            clearTimeout(input);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        content,
        inputValue,
        boxInfo?.idxColumn,
        boxInfo?.idxData,
        boxInfo?.rowKey,
        boxInfo?.type,
        setDetails,
        // isTyping,
    ]);

    // edit content for listcontent in type list
    React.useEffect(() => {
        let input;
        if (
            content !== inputValue.trim() &&
            boxInfo?.type === 'list' &&
            parseInt(boxInfo?.idxData) >= 0 &&
            parseInt(boxInfo?.idxList) >= 0 &&
            isTyping
        ) {
            input = setTimeout(() => {
                setDetails((details) =>
                    [].concat(
                        details.slice(0, boxInfo?.idxData),
                        [
                            {
                                ...details[boxInfo?.idxData],
                                listcontent: details[
                                    boxInfo?.idxData
                                ].listcontent.map((i, x) => {
                                    if (x === boxInfo?.idxList) {
                                        return {
                                            ...i,
                                            content: inputValue,
                                        };
                                    } else return i;
                                }),
                            },
                        ],
                        details.slice(boxInfo?.idxData + 1, details.length)
                    )
                );
                setIsTyping(false);
            }, 1000);
        }
        return () => {
            clearTimeout(input);
        };
    }, [
        content,
        inputValue,
        boxInfo?.idxData,
        boxInfo?.idxList,
        boxInfo?.type,
        setDetails,
        isTyping,
    ]);
    // edit content in type header1, header2, paragraph, quote
    React.useEffect(() => {
        let input;
        if (
            content !== inputValue.trim() &&
            ['header1', 'header2', 'paragraph', 'quote'].includes(
                boxInfo?.type
            ) &&
            parseInt(boxInfo?.idxData) >= 0 &&
            isTyping
        ) {
            input = setTimeout(() => {
                setDetails((details) =>
                    [].concat(
                        details.slice(0, boxInfo?.idxData),
                        [
                            {
                                ...details[boxInfo?.idxData],
                                content: inputValue,
                            },
                        ],
                        details.slice(boxInfo?.idxData + 1, details.length)
                    )
                );
                setIsTyping(false);
            }, 1000);
        }

        return () => {
            clearTimeout(input);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        content,
        inputValue,
        boxInfo?.idxData,
        boxInfo?.type,
        boxInfo.typeBox,
        setDetails,
        // isTyping,
    ]);
    const handleSelectedBoxData = (activeElement) => {
        if (activeElement.getAttribute('type') === 'table') {
            setSelectedBoxData({
                type: activeElement.getAttribute('type'),
                idxData: Number(activeElement.getAttribute('idxdata')),
                rowKey: activeElement.getAttribute('rowkey'),
                idxColumn: Number(activeElement.getAttribute('idxcolumn')),
            });
        } else if (activeElement.getAttribute('type') === 'list') {
            setSelectedBoxData({
                type: activeElement.getAttribute('type'),
                idxData: Number(activeElement.getAttribute('idxdata')),
                idxList: Number(activeElement.getAttribute('idxlist')),
            });
        } else if (
            ['header1', 'header2', 'quote', 'paragraph', 'image'].includes(
                activeElement.getAttribute('type')
            )
        ) {
            setSelectedBoxData({
                type: activeElement.getAttribute('type'),
                idxData: Number(activeElement.getAttribute('idxdata')),
            });
        }
    };
    const focusBack = (activeId) => {
        let textareas = document.querySelectorAll('textarea[tabindex="2"]');
        let pos = Array.from(textareas).findIndex((i) => i.id === activeId);
        let prevId = Array.from(textareas)[pos - 1].id;
        let activeElement = document.getElementById(prevId);
        activeElement.focus();
        handleSelectedBoxData(activeElement);
    };
    const focusNext = (activeId) => {
        let textareas = document.querySelectorAll('textarea[tabindex="2"]');
        let pos = Array.from(textareas).findIndex((i) => i.id === activeId);
        let nextId = Array.from(textareas)[pos + 1].id;
        let activeElement = document.getElementById(nextId);
        activeElement.focus();
        handleSelectedBoxData(activeElement);
    };
    const handleKeyDownArrowUp = async (e) => {
        if (boxInfo?.type === 'table') {
            if (document.activeElement.getAttribute('rowkey') === '0') {
                // lấy danh sách các bảng
                let tableTextareas = document.querySelectorAll(
                    'textarea[type="table"]'
                );
                // tìm vị trí của ô đầu tiên thoả: bảng đúng idxData
                let positionOfFirstCell = Array.from(tableTextareas).findIndex(
                    (i) =>
                        Number(i.getAttribute('idxdata')) ===
                            Number(
                                document.activeElement.getAttribute('idxdata')
                            ) &&
                        Number(i.getAttribute('rowkey')) === 0 &&
                        Number(i.getAttribute('idxcolumn')) === 0
                );
                // lấy id của ô đầu tiên
                let cellId = Array.from(tableTextareas)[positionOfFirstCell].id;
                // focus lùi lại 1 vị trí
                focusBack(cellId);
            } else {
                // lấy danh sách các bảng
                let tableTextareas = document.querySelectorAll(
                    'textarea[type="table"]'
                );
                // tìm vị trí của ô phía trên thoả: bảng đúng idxData, cột đúng idxColumn
                let positionOfAboveCell = Array.from(tableTextareas).findIndex(
                    (i) =>
                        Number(i.getAttribute('idxdata')) ===
                            Number(
                                document.activeElement.getAttribute('idxdata')
                            ) &&
                        Number(i.getAttribute('rowkey')) ===
                            Number(
                                document.activeElement.getAttribute('rowkey')
                            ) -
                                1 &&
                        Number(i.getAttribute('idxcolumn')) ===
                            Number(
                                document.activeElement.getAttribute('idxcolumn')
                            )
                );
                // lấy id của ô tìm được và focus
                let prevId = Array.from(tableTextareas)[positionOfAboveCell].id;
                let activeElement = document.getElementById(prevId);
                activeElement.focus();
                handleSelectedBoxData(activeElement);
            }
        } else {
            let textBeforeCursor = e.target.value.substr(
                0,
                document.activeElement.selectionStart
            );
            if (textBeforeCursor.length === 0) {
                await focusBack(document.activeElement.id);
                let x = document.getElementById(document.activeElement.id);
                x.setSelectionRange(0, 0);
            }
        }
    };
    const handleKeyDownArrowDown = async (e) => {
        if (boxInfo?.type === 'table') {
            // lấy danh sách các bảng
            let tableTextareas = document.querySelectorAll(
                'textarea[type="table"]'
            );
            // lấy các textarea thuộc bảng đang chọn
            let selectedTextareas = Array.from(tableTextareas).filter(
                (i) =>
                    Number(i.getAttribute('idxdata')) ===
                    Number(document.activeElement.getAttribute('idxdata'))
            );
            // lấy danh sách các rowKey và tìm max
            let max = Math.max(
                ...selectedTextareas.map((i) =>
                    Number(i.getAttribute('rowkey'))
                )
            );
            // nếu rowkey của hiện tại trùng với max (tức đang ở hàng cuối)
            if (Number(document.activeElement.getAttribute('rowkey')) === max) {
                // tìm vị trí và lấy id của ô cuối cùng thoả: bảng đúng idxData
                let cellId = Array.from(tableTextareas)
                    .filter(
                        (i) =>
                            Number(i.getAttribute('idxdata')) ===
                            Number(
                                document.activeElement.getAttribute('idxdata')
                            )
                    )
                    .slice(-1)[0].id;
                // focus tiến tới 1 vị trí
                focusNext(cellId);
            } else {
                // tìm vị trí của ô phía dưới thoả: bảng đúng idxData, cột đúng idxColumn
                let positionOfUnderCell = Array.from(tableTextareas).findIndex(
                    (i) =>
                        Number(i.getAttribute('idxdata')) ===
                            Number(
                                document.activeElement.getAttribute('idxdata')
                            ) &&
                        Number(i.getAttribute('rowKey')) ===
                            Number(
                                document.activeElement.getAttribute('rowkey')
                            ) +
                                1 &&
                        Number(i.getAttribute('idxColumn')) ===
                            Number(
                                document.activeElement.getAttribute('idxcolumn')
                            )
                );
                // lấy id của ô tìm được và focus
                let nextId = Array.from(tableTextareas)[positionOfUnderCell].id;
                let activeElement = document.getElementById(nextId);
                activeElement.focus();
                handleSelectedBoxData(activeElement);
            }
        } else {
            let textBeforeCursor = e.target.value.substr(
                0,
                document.activeElement.selectionStart
            );
            if (textBeforeCursor.length === e.target.value.length) {
                await focusNext(document.activeElement.id);
                let x = document.getElementById(document.activeElement.id);
                x.setSelectionRange(x.value.length, x.value.length);
            }
        }
    };
    const handleKeyDownArrowRight = async (e) => {
        let textBeforeCursor = e.target.value.substr(
            0,
            document.activeElement.selectionStart
        );
        if (textBeforeCursor.length === e.target.value.length) {
            e.preventDefault();
            focusNext(document.activeElement.id);
        }
    };
    const handleKeyDownArrowLeft = async (e) => {
        let textBeforeCursor = e.target.value.substr(
            0,
            document.activeElement.selectionStart
        );
        if (textBeforeCursor.length === 0) {
            e.preventDefault();
            focusBack(document.activeElement.id);
        }
    };
    const handleKeyDownTab = async (e) => {
        if (boxInfo?.type === 'table') {
            e.preventDefault();
            // lấy danh sách các bảng
            let tableTextareas = document.querySelectorAll(
                'textarea[type="table"]'
            );
            // tìm vị trí và lấy id  của ô đầu tiên thoả: bảng đúng idxData
            let firstCellId = Array.from(tableTextareas).filter(
                (i) =>
                    Number(i.getAttribute('idxdata')) ===
                    Number(document.activeElement.getAttribute('idxdata'))
            )[0].id;
            // tìm vị trí và lấy id của ô cuối cùng thoả: bảng đúng idxData
            let lastCellId = Array.from(tableTextareas)
                .filter(
                    (i) =>
                        Number(i.getAttribute('idxdata')) ===
                        Number(document.activeElement.getAttribute('idxdata'))
                )
                .slice(-1)[0].id;
            if (document.activeElement.id === firstCellId && e.shiftKey) {
                return;
            } else {
                if (document.activeElement.id === lastCellId && !e.shiftKey) {
                    let x = Number(
                        document.activeElement.getAttribute('idxdata')
                    );
                    await setDetails((details) =>
                        [].concat(
                            details.slice(0, x),
                            [
                                {
                                    ...details[x],
                                    rows: {
                                        ...details[x].rows,
                                        [Object.keys(details[x].rows).length]:
                                            details[x].rows[0].map((i) => {
                                                return { content: '' };
                                            }),
                                    },
                                },
                            ],
                            details.slice(x + 1, details.length)
                        )
                    );
                }
                let textareas = document.querySelectorAll(
                    'textarea[tabindex="2"]'
                );
                let pos = Array.from(textareas).findIndex(
                    (i) => i.id === document.activeElement.id
                );
                let nextId;
                if (!e.shiftKey) {
                    nextId = Array.from(textareas)[pos + 1].id;
                } else {
                    nextId = Array.from(textareas)[pos - 1].id;
                }
                let activeElement = document.getElementById(nextId);
                activeElement.focus();
                activeElement.select();
                handleSelectedBoxData(activeElement);
            }
        } else {
            e.preventDefault();
        }
    };
    const handleKeyDownEnter = async (e) => {
        if (boxInfo?.type === 'list') {
            let { idxData, idxList } = boxInfo;
            if (!(parseInt(idxData) >= 0) || !(parseInt(idxList) >= 0)) {
                return;
            } else {
                if (!e.shiftKey) {
                    e.preventDefault();
                    let isEmptyValue = !e.target.value;
                    let pos = details[idxData];
                    let isAtFinalLine = idxList === pos.listcontent.length - 1;
                    switch (true) {
                        case !isEmptyValue && isAtFinalLine:
                            setDetails((details) => [
                                ...details.slice(0, idxData),
                                {
                                    ...pos,
                                    listcontent: [
                                        ...pos.listcontent,
                                        {
                                            content: ' ',
                                        },
                                    ],
                                },
                                ...details.slice(idxData + 1, details.length),
                            ]);
                            break;
                        case !isEmptyValue && !isAtFinalLine:
                            setDetails((details) => [
                                ...details.slice(0, idxData),
                                {
                                    ...pos,
                                    listcontent: [
                                        ...pos.listcontent.slice(
                                            0,
                                            idxList + 1
                                        ),
                                        { content: ' ' },
                                        ...pos.listcontent.slice(
                                            idxList + 1,
                                            pos.listcontent.length
                                        ),
                                    ],
                                },
                                ...details.slice(idxData + 1, details.length),
                            ]);
                            break;
                        case isEmptyValue && isAtFinalLine:
                            setDetails((details) => [
                                ...details.slice(0, idxData),
                                {
                                    ...pos,
                                    listcontent: [
                                        ...pos.listcontent.slice(
                                            0,
                                            pos.listcontent.length - 1
                                        ),
                                    ],
                                },
                                { type: 'paragraph', content: ' ' },
                                ...details.slice(idxData + 1, details.length),
                            ]);
                            break;
                        case isEmptyValue && !isAtFinalLine:
                            setDetails((details) => [
                                ...details.slice(0, idxData),
                                {
                                    ...pos,
                                    listcontent: [
                                        ...pos.listcontent.slice(0, idxList),
                                    ],
                                },
                                { type: 'paragraph', content: ' ' },
                                {
                                    ...pos,
                                    listcontent: [
                                        ...pos.listcontent.slice(
                                            idxList + 1,
                                            pos.listcontent.length
                                        ),
                                    ],
                                },
                                ...details.slice(idxData + 1, details.length),
                            ]);
                            break;
                        default:
                            break;
                    }
                    return;
                }
            }
        } else if (
            ['header1', 'header2', 'quote', 'paragraph'].includes(boxInfo?.type) // test ok
        ) {
            let { idxData } = boxInfo;
            if (!(parseInt(idxData) >= 0)) {
                return;
            } else {
                if (!e.shiftKey) {
                    e.preventDefault();
                    await setDetails((details) => {
                        return [].concat(
                            details.slice(0, idxData + 1),
                            { type: 'paragraph', content: ' ' },
                            details.slice(idxData + 1, details.length)
                        );
                    });
                    focusNext(document.activeElement.id);
                }
            }
        }
    };
    const handleKeyDownBackspace = async (e) => {
        if (
            ['header1', 'header2', 'quote', 'paragraph'].includes(boxInfo?.type)
        ) {
            let { idxData } = boxInfo;
            if (!(parseInt(idxData) >= 0)) {
                return;
            } else {
                if (!e.target.value) {
                    focusBack(document.activeElement.id);
                    e.preventDefault();
                    setDetails((details) =>
                        [].concat(
                            details.slice(0, idxData),
                            details.slice(idxData + 1, details.length)
                        )
                    );
                }
            }
        } else if (boxInfo?.type === 'list') {
            let { idxData, idxList } = boxInfo;
            if (!(parseInt(idxData) >= 0) || !(parseInt(idxList) >= 0)) {
                return;
            } else {
                if (!e.target.value) {
                    let pos = details[idxData];
                    setDetails((details) => [
                        ...details.slice(0, idxData),
                        {
                            ...pos,
                            listcontent: [
                                ...pos.listcontent.slice(0, idxList),
                                ...pos.listcontent.slice(
                                    idxList + 1,
                                    pos.listcontent.length
                                ),
                            ],
                        },
                        ...details.slice(idxData + 1, details.length),
                    ]);
                }
            }
        }
    };

    return (
        <TextField
            label=""
            fullWidth
            variant="standard"
            size="small"
            multiline
            value={inputValue}
            onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                    handleKeyDownBackspace(e);
                }
                if (e.key === 'Enter') {
                    handleKeyDownEnter(e);
                }
                if (e.key === 'ArrowUp') {
                    handleKeyDownArrowUp(e);
                }
                if (e.key === 'ArrowDown') {
                    handleKeyDownArrowDown(e);
                }
                if (e.key === 'ArrowRight') {
                    handleKeyDownArrowRight(e);
                }
                if (e.key === 'ArrowLeft') {
                    handleKeyDownArrowLeft(e);
                }
                if (e.key === 'Tab') {
                    handleKeyDownTab(e);
                }
            }}
            onChange={(event) => {
                setIsTyping(true);
                setInputValue(event.target.value);
            }}
            sx={{
                width: '100%',
                height: '100%',
                my: 0,
            }}
            placeholder="Nhập nội dung..."
            inputProps={{
                autoComplete: 'off',
                style: {
                    ...typographyProps,
                },
                tabIndex: 2,
                ...(boxInfo && { ...boxInfo }),
            }}
            InputProps={{
                disableUnderline: true,
            }}
        />
    );
}

export default function CustomBoxDataElement(props) {
    const {
        details,
        setDetails,
        selectedBoxData,
        setSelectedBoxData,
        data,
        idxData,
    } = props;

    const { uploadImage, deleteImage } = useAWS();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const biggerSM = useMediaQuery(theme.breakpoints.up('sm')); // >= 600
    const biggerMD = useMediaQuery(theme.breakpoints.up('md')); // >= 800
    const biggerXL = useMediaQuery(theme.breakpoints.up('md')); // >= 1200
    //error show when uploading image not follow rules
    const [imgaeError, setImageError] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    // upload image function
    const handleUploadImage = async (e) => {
        const imageData = e.target.files[0];
        if (
            !['image/png', 'image/jpeg', 'image/jpg'].includes(
                imageData.type
            ) ||
            imageData.size > 5242880
        ) {
            return setImageError(true);
        } else {
            setImageError(false);
            setIsUploading(true);
            let result = await uploadImage(imageData);
            if (result.hasError) {
                enqueueSnackbar(result.errorMessage, { variant: 'error' });
                setIsUploading(false);
            } else {
                let res = [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            src: result.data.src,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
                enqueueSnackbar('Tải ảnh thành công!', { variant: 'info' });
                setIsUploading(false);
            }
        }
    };
    const handleDeleteImage = async (key) => {
        setImageError(false);
        let result = await deleteImage(key);
        if (result.hasError) {
            enqueueSnackbar(result.errorMessage, { variant: 'error' });
            setIsDeleting(false);
        } else {
            enqueueSnackbar('Xóa ảnh thành công!', { variant: 'info' });
            setIsDeleting(false);
        }
        // xóa thành công trên AWS hay không thì cũng xóa trên client
        setDetails((details) =>
            [].concat(
                details.slice(0, idxData),
                details.slice(idxData + 1, details.length)
            )
        );
    };

    if (!data || !data?.type) {
        return null;
    } else if (data?.type === 'table') {
        const { type, rows } = data;

        return (
            <Box
                component={'div'}
                key={'CustomBoxDataElement-type-table'}
                sx={{
                    ...(selectedBoxData?.idxData === idxData && {
                        boxShadow: 'inset 0 0 2px #ff5349',
                    }),
                    ...typeBoxSx,
                    position: 'relative',
                    ...(data?.boxProps ? data?.boxProps : {}),
                }}>
                {Object.keys(rows).map((rowNumber, j) => {
                    return (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            flexWrap="nowrap"
                            key={
                                'CustomBoxDataElement-type-table-rows-' +
                                rowNumber +
                                '-' +
                                j
                            }>
                            {rows[rowNumber].map((cell, k) => {
                                const { content } = cell;
                                const { width, mergeCount, ...otherBoxProps } =
                                    cell?.boxProps ?? {};

                                return (
                                    <Grid
                                        item
                                        sx={{
                                            ...cellBoxSx,
                                            width:
                                                width ??
                                                (Number(mergeCount ?? 0) >= 1
                                                    ? `calc(${
                                                          100 *
                                                          (Number(mergeCount) +
                                                              1)
                                                      }% + ${
                                                          58 *
                                                          Number(mergeCount)
                                                      }px)`
                                                    : '100%'),
                                            borderRadius:
                                                j === 0 && k === 0
                                                    ? '3px 0 0 0'
                                                    : j ===
                                                          Object.keys(rows)
                                                              .length -
                                                              1 && k === 0
                                                    ? '0 0 0 3px'
                                                    : j === 0 &&
                                                      k ===
                                                          rows[rowNumber]
                                                              .length -
                                                              1
                                                    ? '0 3px 0 0'
                                                    : j ===
                                                          Object.keys(rows)
                                                              .length -
                                                              1 &&
                                                      k ===
                                                          rows[rowNumber]
                                                              .length -
                                                              1
                                                    ? '0 0 3px 0'
                                                    : '0 0 0 0',
                                            backgroundColor:
                                                rowNumber === '0'
                                                    ? 'aliceblue'
                                                    : 'transparent',
                                            ...(otherBoxProps ?? {}),
                                        }}
                                        onClick={() => {
                                            setSelectedBoxData({
                                                type,
                                                idxData,
                                                rowKey: j,
                                                idxColumn: k,
                                            });
                                        }}
                                        key={
                                            'CustomBoxDataElement-type-table-cell-' +
                                            k
                                        }>
                                        <CustomTextField
                                            typographyProps={{
                                                ...cellTypographySx,
                                                fontWeight:
                                                    rowNumber === '0'
                                                        ? 500
                                                        : 400,
                                                ...(cell?.typographyProps
                                                    ? cell?.typographyProps
                                                    : {}),
                                            }}
                                            {...{
                                                content,
                                                details,
                                                setDetails,
                                                boxInfo: {
                                                    type,
                                                    idxData,
                                                    rowKey: j,
                                                    idxColumn: k,
                                                },
                                                setSelectedBoxData,
                                            }}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    );
                })}
            </Box>
        );
    } else if (data?.type === 'list') {
        const { type, listcontent, bulletPoint } = data;

        return (
            <Box
                sx={{
                    ...(selectedBoxData?.idxData === idxData && {
                        boxShadow: 'inset 0 0 2px #ff5349',
                    }),
                    position: 'relative',
                    ...typeBoxSx,
                    justifyContent: 'flex-start',
                    ...(data?.boxProps ? data?.boxProps : {}),
                }}
                component={'div'}
                key={'CustomBoxDataElement-type-list'}>
                {listcontent.map((element, k) => {
                    const { content } = element;
                    let newBulletPoint =
                        bulletPoint === 'uppercaseRoman'
                            ? numberToRoman(k + 1, true) + '.'
                            : bulletPoint === 'lowercaseRoman'
                            ? numberToRoman(k + 1) + '.'
                            : bulletPoint === 'uppercaseAlphabet'
                            ? numberToAlphabet(k, true) + '.'
                            : bulletPoint === 'lowercaseAlphabet'
                            ? numberToAlphabet(k) + '.'
                            : bulletPoint === 'number'
                            ? Number(k + 1).toString() + '.'
                            : bulletPoint === 'circle'
                            ? '● '
                            : bulletPoint === 'radio'
                            ? '⦿ '
                            : bulletPoint === 'square'
                            ? '▪ '
                            : bulletPoint === 'check'
                            ? '✔ '
                            : bulletPoint === 'add'
                            ? '﹢ '
                            : bulletPoint === 'minus'
                            ? '﹣ '
                            : bulletPoint === 'diamond'
                            ? '◆ '
                            : '';
                    return (
                        <Box
                            sx={{
                                ...lineBoxSx,
                                ...(!biggerSM && { pl: 0.5 }),
                                ...(element?.boxProps ? element?.boxProps : {}),
                            }}
                            onClick={() => {
                                setSelectedBoxData({
                                    type,
                                    idxData,
                                    idxList: k,
                                });
                            }}
                            component={'div'}
                            key={
                                'CustomBoxDataElement-type-list-listcontent-' +
                                k
                            }>
                            <CustomTextField
                                typographyProps={{
                                    ...lineTypographySx,
                                    ...(element?.typographyProps
                                        ? element?.typographyProps
                                        : {}),
                                }}
                                {...{
                                    content: `${newBulletPoint} ${content}`,
                                    details,
                                    setDetails,
                                    boxInfo: { type, idxData, idxList: k },
                                    setSelectedBoxData,
                                }}
                            />
                        </Box>
                    );
                })}
            </Box>
        );
    } else if (data?.type === 'image') {
        const { type, src } = data;
        let { width, ...styleProps } = data?.imageProps ? data?.imageProps : {};
        let finalWidth = biggerXL
            ? width
                ? parseInt(width) * (0.4).toString() + '%'
                : '40%'
            : biggerMD
            ? width
                ? parseInt(width) * (0.6).toString() + '%'
                : '60%'
            : biggerSM
            ? width
                ? parseInt(width) * (0.8).toString() + '%'
                : '80%'
            : width && width !== '150%' && width !== '200%'
            ? parseInt(width).toString() + '%'
            : '100%';
        return (
            <Box
                sx={{
                    ...(selectedBoxData?.idxData === idxData && {
                        boxShadow: 'inset 0 0 2px #ff5349',
                    }),
                    ...typeBoxSx,
                    alignItems: 'center',
                    justifyContent: 'center',
                    my: 1,
                    position: 'relative',
                }}
                component={'div'}
                key={'CustomBoxDataElement-type-image'}
                onClick={() => {
                    setSelectedBoxData({
                        type,
                        idxData,
                    });
                }}>
                {src && (
                    <Box
                        component={'div'}
                        sx={{
                            ...typeBoxSx,
                            alignItems: 'center',
                            justifyContent: 'center',
                            ...(data?.boxProps ? data?.boxProps : {}),
                        }}>
                        <img
                            src={src}
                            loading="lazy"
                            alt={'abstract'}
                            width={finalWidth}
                            height="auto"
                            style={{
                                borderRadius: '3px',
                                ...styleProps,
                            }}
                        />
                    </Box>
                )}
                {isUploading && (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            my: 1,
                        }}>
                        <CustomCircularProgress size={22} color="orange" />
                        <Typography variant="caption" sx={{ ml: 1 }}>
                            Đang tải dữ liệu...
                        </Typography>
                    </Box>
                )}
                {isDeleting && (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            my: 1,
                        }}>
                        <CustomCircularProgress size={22} color="orange" />
                        <Typography variant="caption" sx={{ ml: 1 }}>
                            Đang xóa dữ liệu...
                        </Typography>
                    </Box>
                )}
                {(!src || imgaeError) && (
                    <Typography
                        variant="caption"
                        fontSize={12}
                        sx={{
                            maxWidth: 300,
                            textAlign: 'center',
                            whiteSpace: 'normal',
                        }}
                        color={imgaeError ? 'red' : 'black'}>
                        Lưu ý: Hình ảnh phải có định dạng JPG, JPEG, PNG. Dung
                        lượng tối đa 5MB.
                    </Typography>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: 320,
                    }}>
                    <Button
                        sx={{
                            color: 'white',
                            opacity: 0.8,
                            border: '0.5px solid darkblue',
                            borderRadius: '20px',
                            backgroundColor: 'darkblue',
                            width: 150,
                            my: 1,
                            mx: 'auto',

                            '&:hover': {
                                borderColor: 'darkblue',
                                backgroundColor: 'darkblue',
                                color: 'white',
                                boxShadow: 3,
                                cursor: 'pointer',
                            },
                            textTransform: 'initial',
                        }}>
                        <Input
                            style={{
                                opacity: 0,
                                zIndex: 1,
                                position: 'absolute',
                            }}
                            sx={{
                                '&.MuiInputBase-input': {
                                    cursor: 'pointer',
                                },
                                '& input': {
                                    cursor: 'pointer',
                                },
                                cursor: 'pointer',
                            }}
                            type="file"
                            name="file"
                            accept="image/*"
                            onChange={handleUploadImage}
                        />
                        Tải ảnh lên
                    </Button>
                    <Button
                        onClick={() => handleDeleteImage(src)}
                        sx={{
                            color: 'black',
                            opacity: 0.8,
                            border: '0.5px solid grey',
                            borderRadius: '20px',
                            backgroundColor: 'lightgrey',
                            width: 150,
                            my: 1,
                            mx: 'auto',
                            '&:hover': {
                                borderColor: 'white',
                                backgroundColor: 'grey',
                                color: 'white',
                                boxShadow: 3,
                                cursor: 'pointer',
                            },
                            textTransform: 'initial',
                        }}>
                        Bỏ chèn ảnh
                    </Button>
                </Box>
            </Box>
        );
    } else {
        const { type, content } = data;
        return (
            <Box
                sx={{
                    ...(selectedBoxData?.idxData === idxData && {
                        boxShadow: 'inset 0 0 2px #ff5349',
                    }),
                    ...boxPropsNameMap[type],
                    position: 'relative',
                    ...(data?.boxProps ? data?.boxProps : {}),
                    ...(type === 'quote' && !biggerSM && { width: '100%' }),
                }}
                component={'div'}
                key={'CustomBoxDataElement-type' + type}
                onClick={() => {
                    setSelectedBoxData({
                        type,
                        idxData,
                    });
                }}>
                <CustomTextField
                    typographyProps={{
                        ...typographyPropsNameMap[type],
                        ...(data?.typographyProps ? data?.typographyProps : {}),
                    }}
                    {...{
                        content,
                        details,
                        setDetails,
                        boxInfo: {
                            type,
                            idxData,
                        },
                        setSelectedBoxData,
                    }}
                />
            </Box>
        );
    }
}
