import { Stack, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { DataGrid, gridClasses } from '@mui/x-data-grid';
import {
    FilterAlt as FilterAltIcon,
    ArrowDownward as ArrowDownwardIcon,
    ArrowUpward as ArrowUpwardIcon,
} from '@mui/icons-material';

import { CustomLinearProgress } from 'UI';
import { getColorRGBA } from 'utils/getColorRGBA.js';

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                style={{ flexShrink: 0 }}
                width="240"
                height="200"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false">
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g
                        className="ant-empty-img-4"
                        transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>No Data</Box>
        </StyledGridOverlay>
    );
}

export default function CustomDataGrid({
    columnsDef = [],
    /*
        required
        [{
            field: required, value is a string which must be the same key of object in dataset
            ... other config (references below)
        }]
    */
    rowsDef = [],
    /*
        required
        [{
            id: uniqued, required
            ... other value from dataset
        }]
    */
    isLoading = false,
    sortModel = [],
    filterModel = [],
    ...otherProps
}) {
    let columns = columnsDef.map((config) => ({
        align: 'center',
        headerName: config.field,
        headerAlign: 'center',
        minWidth: 100, // accept: number
        maxWidth: 200, // accept: number
        renderHeader: (params) => {
            let color = getColorRGBA('orangered', 0.5);
            let isSort =
                sortModel.filter((i) => i.hasOwnProperty(config.field)).length >
                0;
            let renderSortIcon = null;
            if (isSort) {
                let sortValue = sortModel.filter((i) =>
                    i.hasOwnProperty(config.field)
                )[0][config.field];
                renderSortIcon =
                    sortValue === 1 ? (
                        <ArrowUpwardIcon fontSize="small" sx={{ color }} />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" sx={{ color }} />
                    );
            }
            let isFilter =
                filterModel.filter((i) => i.field === config.field).length > 0;
            let renderFilterIcon = null;
            if (isFilter) {
                renderFilterIcon = (
                    <FilterAltIcon fontSize="small" sx={{ color }} />
                );
            }

            return (
                <Stack
                    direction="row"
                    spacing={1}
                    width="100%"
                    alignItems="center">
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: '14px',
                            color: 'teal',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>
                        {config?.headerName || config.field}
                    </Typography>
                    {renderSortIcon}
                    {renderFilterIcon}
                </Stack>
            );
        },
        /*   
            description: '', // tooltip when hover header, default = '' will display headerName
            cellClassName: (params) => '',
            headerClassName: (params) => '',
            colSpan: (value, row, column) => {
                return 1;
            },
            flex: 1,
            renderCell: (params) => {return (<node element>)},
            renderHeader: (params) => {return (<node element>)},
            valueFormatter: (value, row) => { return <string> },
            valueGetter: (value, row) => { return <string> },
            valueOptions: ({ row }) => {
                if (row === undefined) {
                    return ['default 1', 'default 2'];
                } else return ['value 1', 'value 2', 'value 3'];
            }, 
                ** use for type = singleSelect,
            getOptionValue: (value) => value.code, 
                ** use it if options return array of objects like [{code: 'abc', name: 'ABC'}]
            getOptionLabel: (value) => value.name, 
                ** use it if options return array of objects like [{code: 'abc', name: 'ABC'}]
        */
        type: 'string',
        hideable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        editable: false,
        filterable: false,
        hideSortIcons: true,
        ...config,
    }));
    let rows = rowsDef.map((row) => ({
        id:
            Object.values(row)
                .filter((i) => typeof i === 'string')
                .join('_') || Math.random() * 1000,
        ...row,
    }));
    return (
        <DataGrid
            columns={columns}
            rows={rows}
            disableColumnFilter
            disableColumnSorting
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            disableRowSelectionOnClick
            disableMultipleRowSelection
            disableVirtualization
            loading={isLoading}
            sortingMode="server"
            filterMode="server"
            hideFooter
            rowSelection={false}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            density="standard"
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}
            slots={{
                loadingOverlay: () => <CustomLinearProgress />,
                noRowsOverlay: CustomNoRowsOverlay,
            }}
            sx={{
                '--DataGrid-overlayHeight': '300px',
                [`& .${gridClasses.row}.even`]: {
                    bgcolor: '#EDEDED',
                    ':hover': {
                        bgcolor: 'aliceblue',
                    },
                },
                [`& .${gridClasses.row}.odd`]: {
                    bgcolor: 'white',
                    ':hover': {
                        bgcolor: 'aliceblue',
                    },
                },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '15px',
                },
                '& .MuiDataGrid-iconSeparator': {
                    display: 'block',
                },
                '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                    border: `1px solid  #f0f0f0`,
                },
            }}
            autosizeOptions={{
                includeOutliers: true,
                includeHeaders: true,
                expand: true,
            }}
            localeText={localeText}
            {...otherProps}
        />
    );
}
const localeText = {
    // Root
    noRowsLabel: 'Không có dữ liệu',
    noResultsOverlayLabel: 'Không tìm thấy kết quả phù hợp.',

    // ** TOOLBAR **
    // Density
    toolbarDensity: 'Giãn dòng',
    toolbarDensityLabel: 'Size',
    toolbarDensityCompact: 'Nhỏ',
    toolbarDensityStandard: 'Vừa',
    toolbarDensityComfortable: 'Lớn',
    // Columns
    toolbarColumnsLabel: 'Xem nhiều cột',
    toolbarColumns: 'Cột',
    // Filters
    toolbarFilters: 'Lọc',
    toolbarFiltersLabel: 'Mở bộ lọc',
    toolbarFiltersTooltipHide: 'Ẩn bộ lọc',
    toolbarFiltersTooltipShow: 'Mở bộ lọc',
    toolbarFiltersTooltipActive: (count) => `${count} cột đang lọc`,
    // Quick filter
    toolbarQuickFilterLabel: 'Tìm nhanh',
    toolbarQuickFilterDeleteIconLabel: 'Xóa',
    // **PANEL**
    // Columns
    columnsPanelTextFieldLabel: 'Tìm cột',
    columnsPanelTextFieldPlaceholder: 'Tên cột',
    columnsPanelShowAllButton: 'Xem tất cả',
    columnsPanelHideAllButton: 'Ẩn tất cả',

    // Filter panel text
    filterPanelDeleteIconLabel: 'Xóa',
    // filterPanelLinkOperator: 'Lọc theo',
    // filterPanelOperators: 'Lọc theo',
    filterPanelOperator: 'Điều kiện lọc',
    filterPanelColumns: 'Tên cột lọc',
    filterPanelInputLabel: 'Giá trị lọc',
    filterPanelInputPlaceholder: 'Giá trị',
    filterOperatorContains: 'Bao gồm',
    filterOperatorStartsWith: 'Bắt đầu với',
    filterOperatorEndsWith: 'Kết thúc với',
    filterOperatorIs: 'Trong nhóm',
    //  filterOperatorNot: 'Giá trị không là',
    // filterOperatorAfter: 'Giá trị sau',
    // filterOperatorOnOrAfter: 'Giá trị kể từ sau',
    // filterOperatorBefore: 'Giá trị trước',
    // filterOperatorOnOrBefore: 'Giá trị kể từ trước',
    // filterOperatorIsEmpty: 'Giá trị rỗng',
    // filterOperatorIsNotEmpty: 'Giá trị không rỗng',
    // filterOperatorIsAnyOf: 'Giá trị bất kì trong',
    'filterOperator=': '=',
    'filterOperator>': '>',
    'filterOperator>=': '≥',
    'filterOperator<': '<',
    'filterOperator<=': '≤',

    // Column menu text
    columnMenuLabel: 'Tùy chỉnh',
    columnMenuShowColumns: 'Xem nhiều cột',
    columnMenuManageColumns: 'Tùy chỉnh cột',
    columnMenuFilter: 'Mở bộ lọc',
    columnMenuHideColumn: 'Ẩn cột',
    columnMenuUnsort: 'Hủy sắp xếp',
    columnMenuSortAsc: 'Sắp xếp tăng dần',
    columnMenuSortDesc: 'Sắp xếp giảm dần',

    // Export selector toolbar button text
    toolbarExport: 'Tải',
    toolbarExportLabel: 'Tải',
    toolbarExportCSV: 'Tải dạng CSV',
    toolbarExportPrint: 'In',
    toolbarExportExcel: 'Tải dạng excel',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        count > 0 ? `cột đang được lọc` : '',
    columnHeaderFiltersLabel: 'Mở bộ lọc',
    columnHeaderSortIconLabel: 'Sắp xếp',

    // Rows selected footer text
    footerRowSelected: (count) => `${count.toLocaleString()} hàng được chọn`,

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} trong ${totalCount.toLocaleString()}`,
    MuiTablePagination: { labelRowsPerPage: 'Số dòng:' },
};
