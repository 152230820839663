import { useEffect, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import {
    MoveUp as MoveUpIcon,
    MoveDown as MoveDownIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';

import { CustomDialog, CustomButton, CustomList, CustomSelect } from 'UI';

function CustomSelectWithProps({
    name = 'name',
    options = [],
    data = {},
    value = data[name],
    onChange = () => {},
    convertArrOptions = (value) => ({
        value,
        label: value,
    }),
    label = '',
    fullWidth = true,
    ...otherProps
}) {
    return (
        <Stack
            width="100%"
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-start"
            spacing={2}>
            <Typography sx={{ whiteSpace: 'nowrap', pb: 0.5, fontSize: 14 }}>
                {label}
            </Typography>
            <CustomSelect
                name={name}
                InputLabelProps={{ shrink: true }}
                margin="none"
                size="small"
                fullWidth={fullWidth}
                options={options}
                convertArrOptions={convertArrOptions}
                multiple={false}
                value={value}
                onChange={onChange}
                FormHelperTextProps={{
                    sx: { fontStyle: 'italic', color: 'blue' },
                }}
                variant="standard"
                {...otherProps}
            />
        </Stack>
    );
}

export default function CustomSortDialog({
    open = false,
    onClose = () => {},
    initialValue = [],
    onSubmit = () => {},
    sortData = {},
    ...otherProps
}) {
    const [sortModel, setSortModel] = useState([]);
    // apply current sort if present, only run once!
    useEffect(() => {
        let getData;
        if (open) {
            getData = setTimeout(() => {
                setSortModel(
                    initialValue.length > 0
                        ? initialValue.map((i) => ({
                              id: Math.random() * 1000,
                              sortName: Object.keys(i)[0],
                              sortValue: Object.values(i)[0],
                          }))
                        : [
                              {
                                  id: Math.random() * 1000,
                                  sortName: Object.keys(sortData)[0],
                                  sortValue: 1,
                              },
                          ]
                );
            }, 200);
        }
        return () => {
            clearTimeout(getData);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (open) {
        return (
            <CustomDialog
                id="sort-data-custom-dialog"
                title={'Sắp xếp dữ liệu'}
                open={open}
                onClose={onClose}
                maxWidth="md"
                labelNoButon="Thêm"
                hideNoButton={sortModel.length === Object.keys(sortData).length}
                onNo={() => {
                    setSortModel((data) => {
                        let updatedData = [...data];
                        return [
                            ...updatedData,
                            {
                                id: Math.random() * 1000,
                                sortName: Object.keys(sortData).filter(
                                    (name) =>
                                        !updatedData
                                            .map((i) => i.sortName)
                                            .includes(name)
                                )[0],
                                sortValue: 1,
                            },
                        ];
                    });
                }}
                labelYesButon={sortModel.length === 0 ? 'Mặc định' : 'Sắp xếp'}
                onYes={() => {
                    onSubmit(sortModel);
                    onClose();
                }}>
                <CustomList
                    id="sort-list-custom-list"
                    disablePaddingListItem={true}
                    subheader={
                        'Sắp xếp dữ liệu theo thứ tự ưu tiên từ trên xuống dưới:'
                    }
                    useListItemButton={false}
                    options={sortModel.map((sort, index) => {
                        return {
                            id: sort.id,
                            ListItemProps: {
                                key: sort.id,
                            },
                            children: (
                                <Stack
                                    pl={{ xs: 0, sm: 2 }}
                                    spacing={{ xs: 0, sm: 2 }}
                                    direction={{ xs: 'column', sm: 'row' }}
                                    width={{ xs: '100%', sm: '80%' }}>
                                    <Box minWidth={250}>
                                        <CustomSelectWithProps
                                            name={'sortName'}
                                            label={`${index + 1}. Tên mục:`}
                                            options={[sort.sortName].concat(
                                                Object.keys(sortData).filter(
                                                    (name) =>
                                                        !sortModel
                                                            .map(
                                                                (i) =>
                                                                    i.sortName
                                                            )
                                                            .includes(name) &&
                                                        name !== sort.sortName
                                                )
                                            )}
                                            convertArrOptions={(option) => ({
                                                value: option,
                                                label: sortData[option],
                                            })}
                                            data={sort}
                                            onChange={(newValue) => {
                                                setSortModel((data) => {
                                                    let updatedData = [...data];
                                                    updatedData =
                                                        updatedData.map(
                                                            (item) =>
                                                                item.id ===
                                                                sort.id
                                                                    ? {
                                                                          ...item,
                                                                          sortName:
                                                                              newValue,
                                                                      }
                                                                    : item
                                                        );
                                                    return updatedData;
                                                });
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            pl: { xs: 3, sm: 0 },
                                            borderLeft: {
                                                xs: '2px solid #EDEDED',
                                                sm: 'none',
                                            },
                                        }}>
                                        <Stack
                                            width="100%"
                                            direction="row"
                                            alignItems="flex-end"
                                            spacing={0.5}>
                                            <Box sx={{ width: 240 }}>
                                                <CustomSelectWithProps
                                                    name="sortValue"
                                                    label="Thứ tự:"
                                                    options={[1, -1]}
                                                    convertArrOptions={(
                                                        option
                                                    ) => ({
                                                        value: option,
                                                        label:
                                                            option === 1
                                                                ? 'Tăng dần'
                                                                : 'Giảm dần',
                                                    })}
                                                    data={sort}
                                                    onChange={(newValue) => {
                                                        setSortModel((data) => {
                                                            let updatedData = [
                                                                ...data,
                                                            ];
                                                            updatedData =
                                                                updatedData.map(
                                                                    (item) =>
                                                                        item.id ===
                                                                        sort.id
                                                                            ? {
                                                                                  ...item,
                                                                                  sortValue:
                                                                                      newValue,
                                                                              }
                                                                            : item
                                                                );
                                                            return updatedData;
                                                        });
                                                    }}
                                                />
                                            </Box>
                                            <CustomButton
                                                id={
                                                    'delete-custom-button-' +
                                                    sort.id
                                                }
                                                variant="base"
                                                useIconButton={true}
                                                onClick={() => {
                                                    setSortModel((data) => {
                                                        let updatedData = [
                                                            ...data,
                                                        ];
                                                        return updatedData.filter(
                                                            (i) =>
                                                                i.id !== sort.id
                                                        );
                                                    });
                                                }}
                                                size="small">
                                                <DeleteIcon fontSize="small" />
                                            </CustomButton>
                                            <CustomButton
                                                id={
                                                    'move up-custom-button-' +
                                                    sort.id
                                                }
                                                variant="base"
                                                disabled={index === 0}
                                                onClick={() => {
                                                    setSortModel((data) => {
                                                        let updatedData = [
                                                            ...data,
                                                        ];

                                                        let currentItem =
                                                            updatedData[index];
                                                        if (index > 0) {
                                                            let beforeItem =
                                                                updatedData[
                                                                    Number(
                                                                        index -
                                                                            1
                                                                    )
                                                                ];
                                                            updatedData =
                                                                updatedData.map(
                                                                    (item, k) =>
                                                                        k ===
                                                                        index -
                                                                            1
                                                                            ? currentItem
                                                                            : k ===
                                                                              index
                                                                            ? beforeItem
                                                                            : item
                                                                );
                                                        }
                                                        return updatedData;
                                                    });
                                                }}
                                                size="small"
                                                useIconButton={true}
                                                children={
                                                    <MoveUpIcon fontSize="small" />
                                                }
                                            />
                                            <CustomButton
                                                id={
                                                    'move down-custom-button-' +
                                                    sort.id
                                                }
                                                variant="base"
                                                disabled={
                                                    index ===
                                                    sortModel.length - 1
                                                }
                                                onClick={() => {
                                                    setSortModel((data) => {
                                                        let updatedData = [
                                                            ...data,
                                                        ];

                                                        let currentItem =
                                                            updatedData[index];
                                                        if (
                                                            index <
                                                            updatedData.length -
                                                                1
                                                        ) {
                                                            let afterItem =
                                                                updatedData[
                                                                    Number(
                                                                        index +
                                                                            1
                                                                    )
                                                                ];
                                                            updatedData =
                                                                updatedData.map(
                                                                    (item, k) =>
                                                                        k ===
                                                                        index +
                                                                            1
                                                                            ? currentItem
                                                                            : k ===
                                                                              index
                                                                            ? afterItem
                                                                            : item
                                                                );
                                                        }
                                                        return updatedData;
                                                    });
                                                }}
                                                size="small"
                                                useIconButton={true}
                                                children={
                                                    <MoveDownIcon fontSize="small" />
                                                }
                                            />
                                        </Stack>
                                    </Box>
                                </Stack>
                            ),
                        };
                    })}
                />
            </CustomDialog>
        );
    } else return null;
}
