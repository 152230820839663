import { MobileStepper } from '@mui/material';

import {
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material/';

import { CustomButton } from 'UI';

import { getColorRGBA } from 'utils/getColorRGBA.js';

export default function CustomMobileStepper({
    variant = null,
    activeStep = 0,
    onChangeStep = () => {},
    steps = 6,
    color = 'default',
    size = 'medium',
    hideBackButton = false,
    hideNextButton = false,
    ...otherProps
}) {
    const handleNext = () => {
        if (activeStep === steps - 1) return;
        onChangeStep(activeStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) return;
        onChangeStep(activeStep - 1);
    };

    if (steps === 1) return null;

    return (
        <MobileStepper
            variant={variant || steps <= 6 ? 'dots' : 'text'}
            steps={steps}
            position="static"
            activeStep={activeStep}
            sx={{
                maxWidth: 250,
                width: '100%',
                backgroundColor: 'transparent',
                color: getColorRGBA(color),
                fontSize: size === 'small' ? 14 : size === 'medium' ? 15 : 16,
                '.MuiMobileStepper-dot': {
                    backgroundColor: getColorRGBA(color, 0.4),
                },
                '.MuiMobileStepper-dotActive': {
                    backgroundColor: getColorRGBA(color),
                },
            }}
            backButton={
                <CustomButton
                    id="next-step-custom-button"
                    variant="base"
                    size={size}
                    color={color}
                    styleProps={{ display: hideBackButton ? 'none' : 'flex' }}
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    startIcon={<KeyboardArrowLeftIcon fontSize="small" />}>
                    Trước
                </CustomButton>
            }
            nextButton={
                <CustomButton
                    id="back-step-custom-button"
                    variant="base"
                    size={size}
                    color={color}
                    styleProps={{ display: hideNextButton ? 'none' : 'flex' }}
                    onClick={handleNext}
                    disabled={activeStep === steps - 1}
                    endIcon={<KeyboardArrowRightIcon fontSize="small" />}>
                    Sau
                </CustomButton>
            }
        />
    );
}
