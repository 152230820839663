import { styled } from '@mui/material/styles';
import { Button, IconButton } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';

import { getColorRGBA } from 'utils/getColorRGBA.js';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function getStyles({
    variant = 'contained',
    color = 'disabled',
    useIconButton = false,
}) {
    let chosenColor = getColorRGBA(color);
    let lighterChosenColor = getColorRGBA(color, true);
    let css = {
        contained: {
            backgroundColor: chosenColor,
            color: 'white',
            textTransform: 'initial',
            ':hover': {
                color: 'white',
                backgroundColor: chosenColor,
                opacity: 0.8,
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: chosenColor,
                color: 'white',
                borderColor: lighterChosenColor,
            },
            '&:focus': {
                boxShadow: 1,
            },
            '&.Mui-disabled': {
                color: 'lightgrey',
                backgroundColor: '#EDEDED',
            },
        },
        text: {
            backgroundColor: 'transparent',
            color: chosenColor,
            textTransform: 'initial',
            ':hover': {
                color: chosenColor,
                backgroundColor: lighterChosenColor,
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: lighterChosenColor,
                color: chosenColor,
            },
            '&:focus': {
                boxShadow: 'none',
            },
            '&.Mui-disabled': {
                color: 'lightgrey',
                backgroundColor: '#EDEDED',
            },
        },
        outlined: {
            backgroundColor: 'transparent',
            borderColor: chosenColor,
            color: chosenColor,
            textTransform: 'initial',
            ':hover': {
                color: chosenColor,
                backgroundColor: lighterChosenColor,
                borderColor: chosenColor,
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: lighterChosenColor,
                color: chosenColor,
            },
            '&:focus': {
                boxShadow: 'none',
            },
            '&.Mui-disabled': {
                color: 'lightgrey',
                backgroundColor: '#EDEDED',
            },
        },
    };
    if (useIconButton) {
        return {
            backgroundColor: 'transparent',
            borderColor: chosenColor,
            color: chosenColor,
            textTransform: 'initial',
            ':hover': {
                color: chosenColor,
                backgroundColor: lighterChosenColor,
                borderColor: chosenColor,
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: lighterChosenColor,
                color: chosenColor,
            },
            '&:focus': {
                boxShadow: 'none',
            },
            '&.Mui-disabled': {
                color: 'lightgrey',
                backgroundColor: '#EDEDED',
            },
        };
    }
    return css[variant];
}
export default function CustomUploadImageButton({
    id, // required
    variant = 'contained', // contained, text, outlined
    children = 'Tải lên', // required, accept a string or a node element
    onUpload = () => {}, // required
    color = 'default', // accepted: default, success, error, info, warning
    size = 'medium', // small, medium , large
    disabled = false,
    // startIcon, // not supported
    endIcon,
    useIconButton = false,
    ...otherProps
}) {
    const styles = getStyles({ variant, color });
    if (useIconButton) {
        return (
            <IconButton
                id={id}
                component="label"
                aria-label="upload-file-icon-button"
                role={undefined}
                size={size}
                disabled={disabled}
                tabIndex={-1}
                sx={styles}
                {...otherProps}>
                <CloudUploadIcon />
                <VisuallyHiddenInput
                    type="file"
                    name="file"
                    accept="image/*"
                    onChange={onUpload}
                    data-testid="hidden-input"
                />
            </IconButton>
        );
    }
    return (
        <Button
            id={id}
            component="label"
            aria-label="upload-file-button"
            role={undefined}
            variant={variant}
            size={size}
            disabled={disabled}
            endIcon={endIcon}
            tabIndex={-1}
            sx={styles}
            startIcon={<CloudUploadIcon />}
            {...otherProps}>
            {children}
            <VisuallyHiddenInput
                type="file"
                name="file"
                accept="image/*"
                onChange={onUpload}
                data-testid="hidden-input"
            />
        </Button>
    );
}
