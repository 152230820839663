import { useState, useEffect } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
    OutlinedInput,
    FilledInput,
    Checkbox,
    ListItemText,
    FormHelperText,
    Box,
    InputAdornment,
    Typography,
} from '@mui/material';
import { DoneRounded as DoneRoundedIcon } from '@mui/icons-material';

import { getColorRGBA } from 'utils/getColorRGBA.js';

const inputName = {
    standard: Input,
    outlined: OutlinedInput,
    filled: FilledInput,
};

function getStyles({
    size = 'medium',
    color = 'disabled',
    variant = 'outlined',
}) {
    let chosenColor = getColorRGBA(color);
    let css = {
        standard: {
            '& label': {
                borderRadius: '5px',
                mt: size === 'small' ? 0.75 : 0.5,
                ml: -1.5,
                '&.MuiInputLabel-shrink': {
                    mt: 0.75,
                },
                '&.Mui-focused': {
                    color: chosenColor,
                },
                ':hover': {
                    color: chosenColor,
                },
                '&.Mui-error': {
                    color: 'rgb(211, 47, 47)',
                },
                '&.Mui-disabled': {
                    color: 'rgb(0,0,0,0.26)',
                },
            },
            '& p': {
                '&.MuiFormHelperText-root': {
                    ml: 0,
                },
            },
            '& .MuiInputBase-root': {
                height: size === 'small' ? 29 : 32,
                borderRadius: '5px',
                color: chosenColor,
                ':hover:not(.Mui-disabled, .Mui-error):before': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                },
                '::after': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                    '&:hover': {
                        borderBottom: `1px solid ${chosenColor}`,
                    },
                },
                '&.Mui-disabled:before': {
                    borderBottom: '1px solid rgb(0,0,0,0.26)',
                },
                '& .MuiInput-input:focus': {
                    backgroundColor: 'transparent',
                },
                '& div.MuiSelect-select': {
                    pr: '0',
                },
            },
        },
        filled: {
            '& label': {
                borderRadius: '5px',
                px: 1,
                ml: -0.75,
                mt: size === 'small' ? 0.5 : 0,
                '&.MuiInputLabel-shrink': {
                    mt: 1.5,
                },
                '&.Mui-focused': {
                    color: chosenColor,
                },
                ':hover': {
                    color: chosenColor,
                },
                '&.Mui-error': {
                    color: 'rgb(211, 47, 47)',
                },
                '&.Mui-disabled': {
                    color: 'rgb(0, 0, 0, 0.26)',
                },
            },
            '& .MuiFilledInput-root': {
                height: size === 'small' ? 48 : 56,
                backgroundColor: 'aliceblue',
                borderRadius: '5px 5px 0 0',
                color: chosenColor,
                ':hover:not(.Mui-disabled, .Mui-focused)': {
                    backgroundColor: 'rgba(0,40,139,0.1)',
                },
                '&.Mui-focused': {
                    backgroundColor: 'aliceblue',
                },
                ':hover:not(.Mui-disabled, .Mui-error):before': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                },
                '::after': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                    '&:hover': {
                        borderBottom: `1px solid ${chosenColor}`,
                    },
                },
                '&.Mui-error:focus': {
                    borderBottom: '1px solid rgb(176, 0, 32)',
                },
                '&.Mui-disabled': {
                    backgroundColor: 'lightgrey',
                },
                '&.Mui-disabled:before': {
                    borderBottom: '1px solid rgb(0,0,0,0.26)',
                },
                '&.Mui-disabled:after': {
                    borderBottom: '1px solid rgb(0,0,0,0.26)',
                },
                '& .MuiFilledInput-input:focus': {
                    backgroundColor: 'transparent',
                },
                '& div.MuiSelect-select': {
                    pr: '0',
                },
            },
        },
        outlined: {
            '& label': {
                borderRadius: '5px',
                backgroundColor: 'white',
                px: 1,
                ml: -0.75,
                '&.Mui-focused': {
                    color: chosenColor,
                },
                ':hover': {
                    color: chosenColor,
                },
                '&.Mui-error': {
                    color: 'rgb(211, 47, 47)',
                },
                '&.Mui-disabled': {
                    color: 'rgb(0,0,0,0.26)',
                },
            },
            '& .MuiOutlinedInput-root': {
                height: size === 'small' ? 41 : 56,
                borderRadius: '5px',
                color: chosenColor,
                '& fieldset': {
                    borderRadius: '5px',
                    borderWidth: '1px',
                },
                '&:hover:not(.Mui-disabled, .Mui-error) fieldset': {
                    border: `1px solid ${chosenColor}`,
                },
                '&.Mui-focused fieldset': {
                    border: `1px solid ${chosenColor}`,
                },
                '&.Mui-error fieldset': {
                    border: '1px solid rgb(211, 47, 47)',
                },
                '&.Mui-disabled fieldset': {
                    border: '1px solid rgb(0,0,0,0.26)',
                },
                '& div.MuiSelect-select': {
                    pr: '0',
                },
            },
        },
    };

    return css[variant];
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function CustomSelect({
    name, // required
    id = `${name}-${Math.floor(Math.random() * 1000)}-custom-select`,
    options = [], // required, input structure: ['value-1','value-2',...]
    convertArrOptions = (value) => ({ value, label: value }),
    /*
            required, 
            input: option element from options, 
            output structure: { value: 'test', label: 'Content' }
        */
    multiple = true, // required
    value, // required if controlled (initialState = '' if !multiple and = [] if multiple)
    onChange = () => {}, // required if controlled
    autoWidth = true,
    FormControlProps,
    FormHelperTextProps,
    label = '',
    error = '',
    placeholder = '',
    helperText = '',
    inputProps,
    InputLabelProps,
    inputRef,
    // multiline  // not supoorted
    onBlur,
    fullWidth = true,
    required = false,
    disabled = false,
    readOnly = false,
    variant = 'outlined', // accepted: filled, outlined, standard
    color = 'default', // accepted: default, success, error, info, warning
    size = 'medium', // accepted: medium, small
    margin = 'normal', // accepted: normal, dense, none
    startAdornment = null,
    endAdornment = null,
    ...otherProps
}) {
    const handleMultipleChange = (event) => {
        const v = event.target.value; // array of values in selected MenuItems

        const selectedValues = typeof v === 'string' ? v.split(',') : v;
        onChange(selectedValues);
    };
    const handleSingleChange = (event) => {
        let v = event.target.value; // equal to value in selected MenuItem
        onChange(v);
    };

    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
    const StyledInput = inputName[variant];
    const styles = getStyles({ size, color, variant });

    const convertedOptions = options.map((i) => {
        let { label: optLabel, value: optValue } = convertArrOptions(i);

        return (
            <MenuItem
                disableGutters
                value={optValue}
                key={optLabel + '-' + optValue}
                sx={{ alignItems: 'flex-start' }}
                style={{
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    backgroundColor: multiple
                        ? value.indexOf(optValue) === -1
                            ? 'white'
                            : 'aliceblue'
                        : value === optValue
                        ? 'aliceblue'
                        : 'white',
                }}>
                {multiple ? (
                    <Checkbox
                        size="small"
                        checked={value.indexOf(optValue) > -1}
                    />
                ) : value === optValue ? (
                    <Box sx={{ mr: 0.5 }}>
                        <DoneRoundedIcon
                            sx={{ color: 'darkblue', width: 12, height: 12 }}
                        />
                    </Box>
                ) : (
                    <Box sx={{ width: 16 }}></Box>
                )}
                <ListItemText
                    primary={optLabel}
                    primaryTypographyProps={{
                        pt: multiple ? 1 : 0,
                        pr: 1,
                        whiteSpace: 'normal',
                        color: multiple
                            ? value.indexOf(optValue) === -1
                                ? 'black'
                                : 'darkblue'
                            : value === optValue
                            ? 'darkblue'
                            : 'black',
                    }}
                />
            </MenuItem>
        );
    });
    const [menuWidth, setMenuWidth] = useState(400);
    useEffect(() => {
        setMenuWidth(
            document.getElementById(id + '-form-control').offsetWidth || 400
        );
    }, [id]);

    return (
        <FormControl
            id={id + '-form-control'}
            fullWidth={fullWidth}
            disabled={disabled}
            required={required}
            size={size}
            error={Boolean(error)}
            margin={margin}
            sx={styles}
            {...FormControlProps}
            {...otherProps}>
            <InputLabel id={id + '-label'} {...InputLabelProps}>
                {label}
            </InputLabel>
            <Select
                labelId={id + '-label'}
                multiple={multiple}
                name={name}
                autoWidth={autoWidth}
                id={id}
                label={label}
                value={value}
                onBlur={onBlur}
                inputRef={inputRef}
                displayEmpty
                onChange={multiple ? handleMultipleChange : handleSingleChange}
                renderValue={(selected) => {
                    if (
                        (typeof selected === 'string' && !selected) ||
                        (Array.isArray(selected) && selected?.length === 0)
                    ) {
                        return (
                            <Typography
                                sx={{
                                    opacity: 0.4,
                                }}>
                                {placeholder}
                            </Typography>
                        );
                    }

                    return (
                        <Typography
                            sx={{
                                width: 'auto',
                                whiteSpace: 'nowrap',
                                overflow: 'clip',
                                textOverflow: 'ellipsis',
                            }}>
                            {multiple
                                ? options
                                      .filter((i) => selected.includes(i))
                                      .map((i) => convertArrOptions(i).label)
                                      .join(', ')
                                : convertArrOptions(selected).label}
                        </Typography>
                    );
                }}
                inputProps={{
                    ...inputProps,
                    ...(readOnly && {
                        readOnly: true,
                    }),
                }}
                input={
                    <StyledInput
                        label={label}
                        {...(startAdornment && {
                            startAdornment: (
                                <InputAdornment position="start">
                                    {startAdornment}
                                </InputAdornment>
                            ),
                        })}
                        {...(endAdornment && {
                            endAdornment: (
                                <InputAdornment position="end">
                                    {endAdornment}
                                </InputAdornment>
                            ),
                        })}
                    />
                }
                MenuProps={{
                    transformOrigin: { horizontal: 'right', vertical: 'top' },
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                    PaperProps: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: menuWidth,
                            borderRadius: '16px',
                        },
                        width: 'auto',
                    },
                }}>
                {convertedOptions}
            </Select>
            <FormHelperText id={helperTextId} {...FormHelperTextProps}>
                {error ? error : helperText}
            </FormHelperText>
        </FormControl>
    );
}
