import { Box } from '@mui/material';

import { CustomModal, CustomCircularProgress } from 'UI';

import LogoBrand from '../layout/LogoBrand';

export default function CustomLoadingModal({
    isLoading = false,
    ...otherProps
}) {
    return (
        <CustomModal
            open={isLoading}
            styleProps={{
                width: 'fit-content',
                backgroundColor: 'transparent',
                border: 'none',
                boxShadow: 0,
                outline: 'none',
            }}
            id="loading-data-custom-modal">
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CustomCircularProgress
                    size={100}
                    animationDuration="1000ms"
                    thickness={2}
                    color="rgba(0,0,139,0.7)"
                    backgroundColor="whitesmoke"
                />
                <Box
                    sx={{
                        top: 4,
                        left: 4,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '93px',
                        height: '93px',
                        overflow: 'hidden',
                        borderRadius: '50%',
                    }}>
                    <LogoBrand
                        width="120px"
                        height="120px"
                        styleProps={{
                            backgroundColor: 'whitesmoke',
                            borderRadius: '50%',
                        }}
                    />
                </Box>
            </Box>
        </CustomModal>
    );
}
