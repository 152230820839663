import { Chip } from '@mui/material';

import { getColorRGBA } from 'utils/getColorRGBA.js';

function getStyles({ size, color, variant }) {
    let chosenColor = getColorRGBA(color);

    let lighterChosenColor = getColorRGBA(color, 0.4);

    const css = {
        filled: {
            '&.MuiChip-root': {
                border: `1px solid ${chosenColor}`,
                backgroundColor: chosenColor,
                color: 'white',
                ':hover': {
                    backgroundColor: chosenColor,
                },
                '& .MuiChipIcon': chosenColor,
            },
        },
        outlined: {
            '&.MuiChip-root': {
                border: `1px solid ${chosenColor}`,
                backgroundColor: 'transparent',
                color: chosenColor,
                ':hover': {
                    backgroundColor: lighterChosenColor,
                },
                '& .MuiChipIcon': chosenColor,
            },
        },
    };
    return css[variant];
}
export default function CustomChip({
    label, // required
    variant = 'outlined', // accepted: filled, outlined
    disabled = false,
    avatar = undefined,
    icon = undefined,
    deleteIcon = undefined,
    color = 'default', // accepted: default, success, error, info, warning
    size = 'medium', // accepted: small, medium
    onClick = undefined,
    onDelete = undefined,
    styleProps = {},
    ...otherProps
}) {
    const styles = getStyles({ size, color, variant });
    return (
        <Chip
            skipFocusWhenDisabled
            label={label}
            variant={variant}
            disabled={disabled}
            avatar={avatar}
            icon={icon}
            deleteIcon={deleteIcon}
            size={size}
            onClick={onClick}
            onDelete={onDelete}
            sx={{ ...styles, ...styleProps }}
            {...otherProps}
        />
    );
}
