export const themeConfig = {
    typography: {
        fontFamily: "'Inter', 'Roboto', Arial, sans-serif !important",
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 800,
            lg: 1000,
            xl: 1200,
            xxl: 1350,
        },
    },
    components: {
        MuiStack: {
            defaultProps: {
                direction: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                spacing: 0,
            },
        },
    },
};
