import { Box } from '@mui/material';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';

import { getColorRGBA } from 'utils/getColorRGBA.js';

export default function CustomCircularProgress({
    size = 30,
    thickness = 3,
    animationDuration = '550ms',
    color = getColorRGBA('default'), // accept any color names
    backgroundColor = 'aliceblue',
    ...otherProps
}) {
    return (
        <Box
            sx={{
                position: 'relative',
            }}
            {...otherProps}>
            <CircularProgress
                variant="determinate"
                sx={{
                    color: backgroundColor,
                }}
                size={size}
                thickness={thickness}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                    color,
                    animationDuration,
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                }}
                size={size}
                thickness={thickness}
            />
        </Box>
    );
}
