import { useState } from 'react';

import { Typography } from '@mui/material';

import { CustomDialog, CustomInput } from 'UI';

export default function CustomDeleteDialog({
    open = false,
    onClose = () => {},
    onSubmit = () => {},
    compareString = 'permanently delete',
    ...otherProps
}) {
    const [userInput, setUserInput] = useState('');
    return (
        <CustomDialog
            id="delete-data-custom-dialog"
            severity="warning"
            title="Xóa dữ liệu?"
            open={open}
            maxWidth="sm"
            onClose={onClose}
            hideCloseIcon={true}
            labelNoButon="Quay lại"
            onNo={onClose}
            hideNoButton={false}
            labelYesButon="Xóa"
            hideYesButton={compareString !== userInput}
            onYes={() => {
                if (compareString === userInput) {
                    onSubmit();
                }
            }}>
            <Typography>
                Bạn đang xóa dữ liệu. Bạn không thể khôi phục sau khi xóa.
            </Typography>
            <Typography fontWeight={600}>
                Bạn có chắc chắn muốn tiếp tục?
            </Typography>
            <CustomInput
                name={'delete-data-custom-input'}
                margin="none"
                size="small"
                variant="standard"
                color="info"
                maxRows={null}
                InputLabelProps={{ shrink: true }}
                placeholder={compareString}
                value={userInput}
                onChange={setUserInput}
                helperText={`Nhập chính xác cụm từ '${compareString}' để xác nhận xóa`}
                FormHelperTextProps={{
                    sx: { fontStyle: 'italic' },
                }}
            />
        </CustomDialog>
    );
}
