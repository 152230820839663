import { Button, IconButton } from '@mui/material';

import { getColorRGBA } from 'utils/getColorRGBA.js';

function getStyles({
    variant = 'contained',
    color = 'disabled',
    useIconButton = false,
}) {
    let chosenColor = getColorRGBA(color);
    let lighterChosenColor = getColorRGBA(color, 0.4);

    let css = {
        base: {
            p: 0,
            backgroundColor: 'transparent',
            color: chosenColor,
            textTransform: 'initial',
            border: 'none',
            ':hover': {
                boxShadow: 'none',
                border: 'none',
                color: chosenColor,
                backgroundColor: 'transparent',
            },
            '&:active': {
                boxShadow: 'none',
                border: 'none',
                backgroundColor: 'transparent',
                color: chosenColor,
            },
            '&:focus': {
                boxShadow: 'none',
                border: 'none',
                backgroundColor: 'transparent',
                color: chosenColor,
            },
            '&.Mui-disabled': {
                boxShadow: 'none',
                border: 'none',
                color: 'EDEDED',
                backgroundColor: 'transparent',
            },
        },
        contained: {
            backgroundColor: chosenColor,
            color: 'white',
            textTransform: 'initial',
            ':hover': {
                color: 'white',
                backgroundColor: chosenColor,
                opacity: 0.8,
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: chosenColor,
                color: 'white',
                borderColor: lighterChosenColor,
            },
            '&:focus': {
                boxShadow: 1,
            },
            '&.Mui-disabled': {
                color: 'lightgrey',
                backgroundColor: '#EDEDED',
            },
        },
        text: {
            backgroundColor: 'transparent',
            color: chosenColor,
            textTransform: 'initial',
            ':hover': {
                color: chosenColor,
                backgroundColor: lighterChosenColor,
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: lighterChosenColor,
                color: chosenColor,
            },
            '&:focus': {
                boxShadow: 'none',
            },
            '&.Mui-disabled': {
                color: 'lightgrey',
                // backgroundColor: '#EDEDED',
            },
        },
        outlined: {
            backgroundColor: 'transparent',
            borderColor: chosenColor,
            color: chosenColor,
            textTransform: 'initial',
            ':hover': {
                color: chosenColor,
                backgroundColor: lighterChosenColor,
                borderColor: chosenColor,
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: lighterChosenColor,
                color: chosenColor,
            },
            '&:focus': {
                boxShadow: 'none',
            },
            '&.Mui-disabled': {
                color: 'lightgrey',
                backgroundColor: '#EDEDED',
            },
        },
    };
    if (useIconButton) {
        return {
            backgroundColor: 'transparent',
            borderColor: variant === 'base' ? 'transparent' : chosenColor,
            color: 'rgba(0, 0, 0, 0.6)',
            ':hover': {
                color: chosenColor,
                backgroundColor:
                    variant === 'base' ? 'transparent' : lighterChosenColor,
                borderColor: variant === 'base' ? 'transparent' : chosenColor,
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor:
                    variant === 'base' ? 'transparent' : lighterChosenColor,
                color: chosenColor,
            },
            '&:focus': {
                boxShadow: 'none',
            },
            '&.Mui-disabled': {
                color: 'lightgrey',
                backgroundColor: variant === 'base' ? 'transparent' : '#EDEDED',
            },
        };
    }
    return css[variant];
}
export default function CustomButton({
    id = `${Math.floor(Math.random() * 1000)}-custom-button`,
    variant = 'contained', // contained, text, outlined, base
    children, // requirred
    onClick = () => {}, // required
    color = 'default', // accepted: default, success, error, info, warning
    size = 'medium', // small, medium , large
    disabled = false,
    startIcon,
    endIcon,
    useIconButton = false,
    styleProps = {},
    sx = {},
    ...otherProps
}) {
    const styles = getStyles({ variant, color, useIconButton });

    if (useIconButton) {
        return (
            <IconButton
                id={id}
                aria-label="custom-icon-button"
                onClick={onClick}
                size={size}
                disabled={disabled}
                sx={{ ...styles, ...styleProps }}
                {...otherProps}>
                {children}
            </IconButton>
        );
    }
    return (
        <Button
            id={id}
            aria-label="custom-button"
            disableElevation
            variant={variant}
            disableRipple={variant === 'base'}
            disableFocusRipple={variant === 'base'}
            disableTouchRipple={variant === 'base'}
            onClick={onClick}
            size={size}
            disabled={disabled}
            startIcon={startIcon}
            endIcon={endIcon}
            sx={{ ...styles, ...styleProps }}
            {...otherProps}>
            {children}
        </Button>
    );
}
