import { Box, Stack, Typography } from '@mui/material';

import { CustomButton, CustomAvatar } from 'UI';

export default function NoFoundData({
    activeFilterCount = 0,
    resetFilter = () => {},
    ...otherProps
}) {
    return (
        <Stack
            spacing={3}
            justifyContent="center"
            alignItems="center"
            px={2}
            py={{ xs: 2, sm: 4 }}>
            <CustomAvatar
                src="../assets/images/pharmacy-illustration.png"
                variant="rounded"
                styleProps={{
                    width: '100%',
                    maxWidth: 500,
                    opacity: 0.5,
                    height: 200,
                }}
            />
            <Stack pt={2} spacing={0.5}>
                <Typography fontSize={26} textAlign="center" fontWeight={600}>
                    Không tìm thấy kết quả nào phù hợp
                </Typography>
                {activeFilterCount > 0 ? (
                    <Typography textAlign="center" fontSize="16px">
                        Hãy thử lại bằng cách thay đổi điều kiện lọc hoặc
                    </Typography>
                ) : (
                    <Typography textAlign="center" fontSize="16px">
                        Hãy thử tìm kiếm với từ khóa khác
                    </Typography>
                )}
            </Stack>
            <Box sx={{ display: activeFilterCount > 0 ? 'flex' : 'none' }}>
                <CustomButton
                    id="clear-all-filter-custom-button"
                    onClick={resetFilter}
                    color="rgb(0,0,139,0.8)"
                    styleProps={{
                        width: 170,
                        fontSize: 16,
                        fontWeight: 500,
                        borderRadius: '20px',
                    }}>
                    Xóa tất cả bộ lọc
                </CustomButton>
            </Box>
        </Stack>
    );
}
