import { Stack, Typography, Box } from '@mui/material';
import { AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { borderWithTransition } from 'config/customCSS.js';

import { CustomAvatar, CustomChip } from 'UI';

const customTheme = (outerTheme) =>
    createTheme({
        components: {
            MuiTypography: {
                styleOverrides: {
                    noWrap(styles) {
                        return {
                            whiteSpace: 'initial',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: String(
                                styles.ownerState['data-lines'] || '2'
                            ),
                            WebkitBoxOrient: 'vertical',
                        };
                    },
                },
            },
        },
    });

export default function ArticleCard({
    articleData = {},
    viewType = 'vertical',
    ...otherProps
}) {
    let {
        _id = Math.random() * 1000,
        category = [],
        sku = '',
        slug = '',
        webName = '',
        description = '',
        primaryImage = {},
        contentInfo = '[{"type":"paragraph","content":"Nội dung chi tiết đang được cập nhật...","isUpdating":"true"}]',
        modifiedAt = null,
    } = articleData;
    const navigate = useNavigate();
    const theme = useTheme();

    const handleClick = () => {
        navigate(`/bai-viet${slug}`);
    };
    if (viewType === 'vertical') {
        return (
            <Stack
                key={_id + '-card-article-item'}
                sx={{
                    backgroundColor: 'white',
                    ...borderWithTransition,
                    width: '100%',
                    borderRadius: '20px',
                    cursor: 'pointer',
                }}
                onClick={handleClick}
                spacing={1}>
                <Box
                    sx={{
                        overflow: 'hidden',
                        p: 2,
                        pb: 1,
                    }}>
                    <CustomAvatar
                        styleProps={{
                            width: '100%',
                            borderRadius: '10px',
                        }}
                        variant="rounded"
                        src={primaryImage?.mainSrc || ''}
                        size={160}
                        alt={primaryImage?.altText || ''}
                    />
                </Box>
                <Box sx={{ overflow: 'hidden', height: 84, px: 2 }}>
                    <ThemeProvider theme={customTheme(theme)}>
                        <Typography
                            component="h3"
                            noWrap
                            data-lines={'3'}
                            sx={{
                                textTransform: 'initial',
                                textAlign: 'justify',
                                height: 73,
                            }}
                            lineHeight="1.5rem"
                            letterSpacing="0.0025em"
                            fontSize={18}
                            fontWeight={600}>
                            {webName}
                        </Typography>
                    </ThemeProvider>
                </Box>
            </Stack>
        );
    } else
        return (
            <Stack
                key={_id + '-card-article-item'}
                sx={{
                    backgroundColor: 'white',
                    ...borderWithTransition,
                    width: '100%',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    p: 2,
                }}
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                onClick={handleClick}
                spacing={1}>
                <Box
                    sx={{
                        width: 160,
                    }}>
                    <CustomAvatar
                        size={160}
                        variant="rounded"
                        src={primaryImage?.mainSrc || ''}
                        alt={primaryImage?.altText || ''}
                    />
                </Box>
                <Stack
                    sx={{ width: '100%', px: 2 }}
                    spacing={1}
                    alignItems="flex-start">
                    <CustomChip
                        size="small"
                        variant="filled"
                        color="lightgrey"
                        styleProps={{
                            '&.MuiChip-root': {
                                color: 'rgba(0,0,0,0.7)',
                                fontWeight: 500,
                                padding: '13px 5px 13px 5px',
                            },
                        }}
                        label={category.find((i) => i.level === 2).name}
                    />
                    <Typography
                        component="h3"
                        sx={{
                            textTransform: 'initial',
                            textAlign: 'left',
                            maxHeight: 48,
                            overflow: 'hidden',
                        }}
                        lineHeight="1.5rem"
                        letterSpacing="0.0025em"
                        fontSize={18}
                        fontWeight={600}>
                        {webName}
                    </Typography>
                    <ThemeProvider theme={customTheme(theme)}>
                        <Typography
                            fontSize="16px"
                            noWrap
                            data-lines={'3'}
                            sx={{
                                textTransform: 'initial',
                                textAlign: 'left',
                                maxHeight: 73,
                            }}>
                            {modifiedAt && (
                                <AccessTimeIcon
                                    sx={{
                                        width: 16,
                                        height: 16,
                                        color: 'rgba(0,0,0,0.7)',
                                        mt: -0.25,
                                        mr: 0.5,
                                    }}
                                />
                            )}
                            <span
                                style={{
                                    color: 'rgba(0,0,0,0.7)',
                                    fontSize: '14px',
                                }}>
                                {modifiedAt &&
                                    moment(modifiedAt).format('DD/MM/YYYY')}
                            </span>
                            {description && <span>&nbsp;{'-'}&nbsp;</span>}
                            {description}
                        </Typography>
                    </ThemeProvider>
                </Stack>
            </Stack>
        );
}
