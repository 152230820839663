import { useEffect, useState } from 'react';

import { Avatar } from '@mui/material';
import { BrokenImage as BrokenImageIcon } from '@mui/icons-material';

export default function CustomAvatar({
    src = '../assets/images/khanhtranglogo-02.png',
    alt = 'avatar',
    variant = 'circular', // accepted: rounded, square, circular
    size = 24,
    imgProps,
    children = undefined, // accepted a text or an icon like <Material-icon/> or a node element
    styleProps = {},
    ...otherProps
}) {
    const [newSrc, setNewSrc] = useState(
        src || '../assets/images/khanhtranglogo-02.png'
    );
    useEffect(() => {
        let timeout;
        if (src && !children) {
            timeout = setTimeout(() => {
                setNewSrc(src);
            }, 200);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [children, src]);

    if (children) {
        return (
            <Avatar
                variant={variant}
                sx={{
                    width: size,
                    height: size,
                    backgroundColor: 'transparent',
                    ...styleProps,
                }}
                {...otherProps}>
                {children ? children : <BrokenImageIcon />}
            </Avatar>
        );
    }

    return (
        <Avatar
            variant={variant}
            src={newSrc}
            imgProps={{
                ...imgProps,
                onError: () => {
                    setNewSrc('../assets/images/khanhtranglogo-02.png');
                },
            }}
            sx={{
                width: size,
                height: size,
                backgroundColor: 'transparent',
                ...styleProps,
            }}
            {...otherProps}
        />
    );
}
