import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export default function CategoryListItem({
    id = Math.random() * 1000,
    cateLv1Path = '',
    name = '',
    slug = '/',
    ...otherProps
}) {
    return (
        <Link
            component={RouterLink}
            underline="none"
            sx={{
                width: '100%',
                fontSize: '14px',
                ':hover': {
                    textDecoration: 'underline',
                },
            }}
            to={`/${cateLv1Path}${slug}`}>
            <Typography
                component="h2"
                fontSize="16px"
                sx={{
                    color: 'blue',
                    maxWidth: '95%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}>
                {name}
            </Typography>
        </Link>
    );
}
