import { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Stack, Typography, Tab } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';

import {
    ArrowCircleRight as ArrowCircleRightIcon,
    Edit as EditIcon,
    Close as CloseIcon,
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    Sms as SmsIcon,
    PhonelinkRing as PhonelinkRingIcon,
    Announcement as AnnouncementIcon,
    PersonSearch as PersonSearchIcon,
} from '@mui/icons-material';

import { useDispatch } from 'react-redux';
import { userInfoActions } from 'store/userInfo-slice.js';

import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';
import { mutateData } from 'utils/http.js';
import { login } from 'utils/auth.js';
import useCountdown from 'hooks/useCountDown.js';

import { customScrollbar } from 'config/customCSS.js';
import { getColorRGBA } from 'utils/getColorRGBA.js';

import polices from 'data/policies.json';

import {
    CustomDialog,
    CustomInput,
    CustomButton,
    CustomAlertBlock,
    CustomCircularProgress,
    CustomAvatar,
} from 'UI';

import PageItemDetails from '../shop/items/PageItemDetails.js';

function CustomInputWithProps({
    name = 'name',
    data = {},
    setData = () => {},
    error = {},
    setError = () => {},
    required = true,
    value = data[name],
    onChange = (newValue) => {
        if (required) {
            setError((error) => {
                let updatedError = { ...error };
                updatedError[name] = validateInputValue(name, newValue);
                return updatedError;
            });
        }
        setData((data) => {
            let updatedData = { ...data };
            updatedData[name] = newValue;
            return updatedData;
        });
    },
    label = '',
    helperText = '',
    ...otherProps
}) {
    return (
        <Stack
            width="100%"
            maxWidth={350}
            sx={{ mx: 'auto' }}
            direction="row"
            alignItems={helperText || error[name] ? 'center' : 'flex-end'}
            justifyContent="flex-start"
            spacing={2}>
            <Typography sx={{ whiteSpace: 'nowrap', pb: 0.5, fontSize: 14 }}>
                {label}
                {':'}
            </Typography>
            <CustomInput
                id={`${name}-custom-input-with-props`}
                name={name}
                margin="none"
                size="small"
                variant="standard"
                maxRows={null}
                InputLabelProps={{ shrink: true }}
                value={value}
                helperText={helperText}
                onChange={onChange}
                error={error[name]}
                FormHelperTextProps={{
                    sx: {
                        fontStyle: 'italic',
                        color: 'blue',
                    },
                }}
                {...otherProps}
            />
        </Stack>
    );
}
function CustomNumberInputWithProps({
    name = 'name',
    error = '',
    value = '',
    onChange = () => {},
    prevRef,
    nextRef,
    ...otherProps
}) {
    const inputRef = useRef(null);

    // control key
    const handleKeyDown = (event) => {
        const key = event.key;
        // Control special keys, backspace, delete, etc.
        if (key === 'ArrowRight' && nextRef) {
            nextRef.focus();
        } else if (key === 'ArrowLeft' && prevRef) {
            prevRef.focus();
        } else if (key === 'Backspace' || key === 'Delete') {
            onChange('');
            if (key === 'Backspace' && prevRef) {
                prevRef.focus();
            }
        } else if (key === 'Tab' || key === 'Enter') {
            return;
        }

        // Prevent non-numeric keys, value >= 10 or press the same value key multitimes
        if (!/[0-9]/.test(key) || value.length > 1 || key === value) {
            event.preventDefault();
        }
    };
    const handleChange = (newValue) => {
        if (/^\d*$/.test(newValue)) {
            // Check if the new value is a number or empty
            onChange(newValue);
            if (newValue === '' && prevRef && prevRef.current) {
                setTimeout(() => {
                    prevRef.current.focus();
                }, 0); // Ensure focus change after state update
            } else if (newValue && nextRef && nextRef.current) {
                setTimeout(() => {
                    nextRef.current.focus();
                }, 0); // Ensure focus change after state update
            }
        }
    };
    useEffect(() => {
        if (value && nextRef && nextRef.current) {
            nextRef.current.focus(); // auto jumpt to next input and focus after user press valid key
        }
    }, [value, nextRef]);

    return (
        <CustomInput
            id={`${name}-custom-number-input-with-props`}
            name={name}
            margin="none"
            size="small"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            inputRef={inputRef}
            value={value}
            onChange={handleChange}
            error={error}
            onKeyDown={handleKeyDown}
            onFocus={(event) => event.target.select()}
            FormHelperTextProps={{ sx: { display: 'none' } }}
            {...otherProps}
            inputProps={{
                style: { textAlign: 'center', fontWeight: 600, fontSize: 20 },
                maxLength: 1, // Ensure only one character is typed
            }}
            debounceTime={0}
        />
    );
}
function validateInputValue(identifier, value) {
    if (identifier === 'tel') {
        return value?.trim()?.length < 3 ? 'Số điện thoại chưa hợp lệ!' : '';
    } else if (identifier === 'otp') {
        return Object.values(value).filter((i) => i === '').length > 0
            ? 'Vui lòng nhập đủ 6 chữ số.'
            : '';
    } else if (identifier === 'password') {
        return value?.trim()?.length < 6 ? 'Mật khẩu tối thiểu 6 kí tự.' : '';
    } else return '';
}
const Header = ({ children = 'Đăng nhập thành viên', ...otherProps }) => {
    return (
        <Typography
            fontSize={20}
            fontWeight={700}
            color="black"
            textAlign="center">
            {children}
        </Typography>
    );
};
const ButtonChangeTel = ({ onClick = () => {}, ...otherProps }) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}>
            <CustomButton
                id="change-tel-custom-button"
                variant="base"
                styleProps={{
                    ':hover': {
                        backgroundColor: 'transparent',
                        textDecoration: 'underline',
                    },
                }}
                onClick={onClick}
                startIcon={<EditIcon sx={{ width: 15, height: 15 }} />}>
                Đổi số điện thoại
            </CustomButton>
        </Stack>
    );
};
const PhoneHref = () => {
    return (
        <Typography
            component="a"
            href="tel:0704430919"
            fontSize={14}
            fontWeight={600}
            sx={{
                '&:hover': {
                    color: 'blue',
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                },
                textDecoration: 'none',
                color: 'blue',
            }}>
            070.443.0919
        </Typography>
    );
};
const defaultUserData = {
    gender: 'male',
    username: '',
    password: '',
    confirmPassword: '',
    tel: '',
};
const defaultOtpDigits = {
    0: '',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
};
const defaultError = {
    password: '',
    confirmPassword: '',
    tel: '',
};
function RenderTelInput({
    adjustedProps = {},
    onChangeStep = () => {},
    ...otherProps
}) {
    let { data, error, setError } = adjustedProps;

    const { mutate, isPending: isCheckingTel } = useMutation({
        mutationFn: mutateData,
        onSuccess: (data, variables, context) => {
            if (data.data.isExisted) {
                onChangeStep('type-select');
            } else {
                onChangeStep('not-signup');
            }
        },
        onError: (err, variables, context) => {
            setError((error) => {
                let updatedError = { ...error };
                updatedError.tel = err.message + '. \n' + err.errorInfo;
                return updatedError;
            });
        },
    });

    const handleCheckTel = async () => {
        let validateTel = validateInputValue('tel', data.tel);
        if (validateTel) {
            setError((error) => {
                let updatedError = { ...error };
                updatedError.tel = validateTel;
                return updatedError;
            });
            return;
        }
        mutate({
            apiUrl: '/user/check-tel',
            formData: {
                data: {
                    tel: data.tel,
                },
            },
        });
    };

    return (
        <Box px={2} mt={4}>
            <Header children="Đăng nhập hoặc Đăng ký" />
            <Typography
                textAlign="center"
                maxWidth={350}
                sx={{ mx: 'auto', mt: 2 }}>
                Vui lòng đăng nhập để xem điểm tích lũy của bạn và các chương
                trình ưu đãi dành riêng cho thành viên.
            </Typography>
            <Stack spacing={3} my={2}>
                <CustomInputWithProps
                    name="tel"
                    label="Số điện thoại"
                    placeholder={`0xxxxxxxxx`}
                    onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                            await handleCheckTel();
                        }
                    }}
                    endAdornment={
                        isCheckingTel ? (
                            <CustomCircularProgress size={20} />
                        ) : (
                            <CustomButton
                                id={`tel-login-next-custom-button`}
                                variant="base"
                                color="default"
                                useIconButton={true}
                                disabled={Boolean(error.tel)}
                                onClick={handleCheckTel}>
                                <ArrowCircleRightIcon fontSize="small" />
                            </CustomButton>
                        )
                    }
                    {...adjustedProps}
                />
                <CustomButton
                    id="check-tel-custom-button"
                    styleProps={{
                        backgroundColor: 'rgba(0, 0, 139, 0.8)',
                        borderRadius: '20px',
                        fontWeight: 600,
                    }}
                    onClick={handleCheckTel}>
                    Tiếp tục
                </CustomButton>
            </Stack>
        </Box>
    );
}
function RenderNotSignup({
    adjustedProps = {},
    onChangeStep = () => {},
    ...otherProps
}) {
    let { data = {}, setData = () => {} } = adjustedProps;

    let customBackgroundPic = (
        <Stack py={1.5} justifyContent="center" direction="row">
            <CustomAvatar
                size={150}
                variant="rounded"
                styleProps={{ position: 'relative' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '38%',
                        left: '28%',
                        transform: 'skewY(-20deg)',
                    }}>
                    <PersonSearchIcon
                        sx={{
                            color: 'orangered',
                            opacity: 0.65,
                            width: 40,
                            height: 40,
                        }}
                    />
                </Box>
                <PhonelinkRingIcon
                    sx={{
                        width: 120,
                        height: 120,
                        color: 'darkblue',
                        opacity: '0.75',
                        transform: 'skewY(-20deg)',
                    }}
                />
            </CustomAvatar>
            <CustomAvatar size={50} variant="rounded" styleProps={{ ml: -1 }}>
                <AnnouncementIcon
                    sx={{
                        width: 60,
                        height: 60,
                        color: 'orangered',
                        opacity: 0.65,
                        borderRadius: '25px 25px 25px 0',
                    }}
                />
            </CustomAvatar>
        </Stack>
    );
    return (
        <Stack mt={4} spacing={1} justifyContent="center">
            {customBackgroundPic}
            <div style={{ textAlign: 'center' }}>
                <Typography component="span">
                    Số điện thoại <b>{data.tel}</b> chưa được đăng kí trên
                    website. Vui lòng liên hệ hotline{' '}
                    <b style={{ color: 'blue' }}>Zalo</b>{' '}
                </Typography>
                <PhoneHref />
                <Typography component="span">{' để được hỗ trợ.'}</Typography>
                <Box my={2}>
                    <ButtonChangeTel
                        onClick={() => {
                            setData((data) => {
                                let updatedData = { ...data };
                                updatedData.tel = '';
                                return updatedData;
                            });
                            onChangeStep('tel-input');
                        }}
                    />
                </Box>
            </div>
        </Stack>
    );
}
const PolicyTabs = ({
    tabValue = 0,
    setTabValue = () => {},
    ...otherProps
}) => {
    let getStyle = (boolean = true) => {
        return {
            borderRadius: '15px 15px 0 0',
            border: boolean ? '1px solid rgba(0,0,139,0.7)' : 'none',
            borderBottom: boolean ? 'none' : '1px solid lightgrey',
            boxShadow: boolean ? 3 : 0,
            textTransform: 'initial',
            color: 'darkblue !important',
            p: '10px 5px',
            minHeight: 0,
            mx: boolean ? 2 : 1,
        };
    };
    return (
        <Box
            sx={{
                flexGrow: 1,
                maxWidth: 400,
                bgcolor: 'whitesmoke',
                pt: 0.5,
            }}>
            <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                    setTabValue(newValue);
                }}
                variant="scrollable"
                scrollButtons
                aria-label="visible arrows tabs example"
                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: 'orange',
                        height: '1px',
                        bottom: 9,
                    },
                    mt: 1,
                }}>
                <Tab
                    label="Quy chế hoạt động"
                    sx={{ ...getStyle(tabValue === 0), ml: 0 }}
                />
                <Tab
                    sx={{ ...getStyle(tabValue === 1) }}
                    label="Chính sách xử lý dữ liệu cá nhân"
                />
                <Tab
                    sx={{ ...getStyle(tabValue === 2) }}
                    label="Chính sách vận chuyển"
                />
                <Tab
                    sx={{ ...getStyle(tabValue === 3) }}
                    label="Chính sách trả hàng"
                />
                <Tab
                    sx={{ ...getStyle(tabValue === 4) }}
                    label="Chính sách thành viên"
                />
                <Tab
                    sx={{ ...getStyle(tabValue === 5) }}
                    label="Chính sách thanh toán"
                />
                <Tab
                    sx={{ ...getStyle(tabValue === 6), mr: 0 }}
                    label="Chính sách bảo mật"
                />
            </Tabs>
        </Box>
    );
};
function RenderPolicyAgreement({ onClose = () => {}, ...otherProps }) {
    const [tabValue, setTabValue] = useState(0);

    let selectedPolicies = useMemo(() => {
        if (tabValue === 0) {
            return 'businessOperationPolicies';
        } else if (tabValue === 1) {
            return 'dataPolicies';
        } else if (tabValue === 2) {
            return 'deliverPolicies';
        } else if (tabValue === 3) {
            return 'goodsReturnPolicies';
        } else if (tabValue === 4) {
            return 'loyaltyPolicies';
        } else if (tabValue === 5) {
            return 'paymentPolicies';
        } else return 'securityPolicies';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabValue]);

    return (
        <Box mt={4}>
            <Typography variant="subtitle1" fontWeight={600} textAlign="center">
                Thông tin quy chế và chính sách
            </Typography>
            <PolicyTabs tabValue={tabValue} setTabValue={setTabValue} />
            <Box
                sx={{
                    my: 1,
                    height: 300,
                    ...customScrollbar,
                    backgroundColor: '#EDEDED',
                }}>
                <PageItemDetails
                    newContent={polices[selectedPolicies]}
                    toggleMoreDetails={false}
                    viewBiggerText={false}
                    keywordArr={[]}
                />
            </Box>
            <CustomButton
                id="close-policy-component-custom-button"
                fullWidth={true}
                styleProps={{
                    mt: 1,
                    backgroundColor: 'rgba(0, 0, 139, 0.8)',
                    borderRadius: '20px',
                    fontWeight: 600,
                }}
                onClick={onClose}>
                Quay lại
            </CustomButton>
        </Box>
    );
}
function RenderTypeSelect({
    adjustedProps = {},
    onChangeStep = () => {},
    ...otherProps
}) {
    let { data = {}, setData = () => {} } = adjustedProps;

    const [isReadPolicies, setIsReadPolicies] = useState(true);
    const [isOpenPolicies, setIsOpenPolicies] = useState(false);

    let customBackgroundPic = (
        <Stack py={1.5} justifyContent="center" direction="row">
            <CustomAvatar
                size={150}
                variant="rounded"
                styleProps={{ position: 'relative' }}>
                <Typography
                    variant="caption"
                    color="blue"
                    fontSize="15px"
                    fontWeight={600}
                    sx={{
                        position: 'absolute',
                        top: '45%',
                        left: '30%',
                        transform: 'skewY(-20deg)',
                    }}>
                    Zalo
                </Typography>
                <PhonelinkRingIcon
                    sx={{
                        width: 120,
                        height: 120,
                        color: 'darkblue',
                        opacity: '0.75',
                        transform: 'skewY(-20deg)',
                    }}
                />
            </CustomAvatar>
            <CustomAvatar size={50} variant="rounded" styleProps={{ ml: -1 }}>
                <SmsIcon
                    sx={{
                        width: 60,
                        height: 60,
                        color: 'darkblue',
                        opacity: '0.75',
                        borderRadius: '25px 25px 25px 0',
                    }}
                />
            </CustomAvatar>
        </Stack>
    );

    return (
        <Box>
            {!isOpenPolicies && (
                <Stack mt={4} spacing={1} justifyContent="center">
                    {customBackgroundPic}
                    <Typography textAlign="center">
                        Mã xác thực sẽ được gửi qua{' '}
                        <b style={{ color: 'blue' }}>Zalo</b> cho số điện thoại{' '}
                        <b>{data.tel}</b>
                    </Typography>
                    <ButtonChangeTel
                        onClick={() => {
                            setData((data) => {
                                let updatedData = { ...data };
                                updatedData.tel = '';
                                return updatedData;
                            });
                            onChangeStep('tel-input');
                        }}
                    />
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        spacing={0.5}>
                        <CustomButton
                            id="checkbox-policy-agreement"
                            variant="base"
                            size="small"
                            useIconButton={true}
                            onClick={() => {
                                setIsReadPolicies((v) => !v);
                            }}>
                            {isReadPolicies ? (
                                <CheckBoxIcon
                                    fontSize="small"
                                    sx={{ color: 'darkblue' }}
                                />
                            ) : (
                                <CheckBoxOutlineBlankIcon
                                    fontSize="small"
                                    sx={{ color: 'darkblue' }}
                                />
                            )}
                        </CustomButton>
                        <Typography fontSize={13} fontStyle="italic" pb={2}>
                            Tôi đồng ý với điều khoản dịch vụ, chính sách thu
                            thập và xử lý dữ liệu cá nhân của NT Khánh Trang{' '}
                            <span
                                style={{
                                    color: 'blue',
                                    ':hover': { textDecoration: 'underline' },
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setIsOpenPolicies(true);
                                }}>
                                {'(Xem chi tiết)'}
                            </span>
                        </Typography>
                    </Stack>
                    <CustomButton
                        id="zalo-otp-custom-button"
                        styleProps={{
                            backgroundColor: 'rgba(0, 0, 139, 0.8)',
                            borderRadius: '20px',
                            fontWeight: 600,
                            py: 1,
                        }}
                        disabled={!isReadPolicies}
                        onClick={() => onChangeStep('zalo-otp-input')}>
                        Nhận mã xác thực qua Zalo
                    </CustomButton>
                    <div
                        style={{
                            textAlign: 'center',
                            paddingTop: '8px',
                        }}>
                        <Typography component="span">
                            Nếu chưa có <b style={{ color: 'blue' }}>Zalo</b>,
                            vui lòng liên hệ hotline{' '}
                        </Typography>
                        <PhoneHref />
                        <Typography component="span">
                            {' để được hỗ trợ.'}
                        </Typography>
                    </div>
                </Stack>
            )}
            {isOpenPolicies && (
                <RenderPolicyAgreement
                    onClose={() => setIsOpenPolicies(false)}
                />
            )}
        </Box>
    );
}
// function RenderPasswordInput({
//     adjustedProps = {},
//     onChangeStep = () => {},
//     onClose = () => {},
//     handleLogin = () => {},
//     ...otherProps
// }) {
//     let { data: userData, setData, setError } = adjustedProps;

//     const [showPassword, setShowPassword] = useState(false);
//     const { mutate, isPending: isCheckingPassword } = useMutation({
//         mutationFn: mutateData,
//         onSuccess: (data, variables, context) => {
//             handleLogin(data.data);
//             setData(data.data.userInfo);
//             onClose();
//             enqueueSnackbar(
//                 <CustomAlertBlock
//                     id={'login-password-success-snackbar'}
//                     severity="success"
//                     title="Đăng nhập thành công"
//                     onClose={() => {
//                         closeSnackbar('login-password-success-snackbar');
//                     }}
//                 />,
//                 {
//                     variant: 'success',
//                     key: 'login-password-success-snackbar',
//                 }
//             );
//         },
//         onError: (err, variables, context) => {
//             enqueueSnackbar(
//                 <CustomAlertBlock
//                     id={'check-password-error-snackbar'}
//                     title={err.message}
//                     children={err.errorInfo}
//                     onClose={() => {
//                         closeSnackbar('check-password-error-snackbar');
//                     }}
//                 />,
//                 {
//                     key: 'check-password-error-snackbar',
//                 }
//             );
//         },
//     });
//     const handleCheckPassword = () => {
//         let validateTel = validateInputValue('password', userData.password);
//         if (validateTel) {
//             setError((error) => {
//                 let updatedError = { ...error };
//                 updatedError.password = validateTel;
//                 return updatedError;
//             });
//             return;
//         }
//         mutate({
//             apiUrl: '/user/check-password',
//             formData: {
//                 data: {
//                     tel: userData.tel,
//                     password: userData.password,
//                 },
//             },
//         });
//     };
//     return (
//         <Box px={2} mt={4}>
//             <Header children="Nhập mật khẩu" />
//             <CustomButton
//                 id="change-tel-custom-button"
//                 variant="base"
//                 styleProps={{
//                     ':hover': {
//                         backgroundColor: 'transparent',
//                         textDecoration: 'underline',
//                     },
//                     width: '100%',
//                     mt: 1,
//                     mx: 'auto',
//                 }}
//                 onClick={() => {
//                     setData((data) => {
//                         let updatedData = { ...data };
//                         updatedData.tel = '';
//                         updatedData.password = '';
//                         return updatedData;
//                     });
//                     onChangeStep('tel-input');
//                 }}
//                 startIcon={<EditIcon sx={{ width: 15, height: 15 }} />}>
//                 Đổi số điện thoại
//             </CustomButton>
//             <Stack spacing={3} my={2}>
//                 <CustomInputWithProps
//                     name="password"
//                     type={showPassword ? 'text' : 'password'}
//                     label="Mật khẩu"
//                     onKeyDown={(e) => {
//                         if (e.key === 'Enter') {
//                             handleCheckPassword();
//                         }
//                     }}
//                     endAdornment={
//                         isCheckingPassword ? (
//                             <CustomCircularProgress size={20} />
//                         ) : (
//                             <CustomButton
//                                 id={`tel-login-next-custom-button`}
//                                 variant="base"
//                                 color="default"
//                                 useIconButton={true}
//                                 onClick={() => {
//                                     setShowPassword((show) => !show);
//                                 }}>
//                                 {showPassword ? (
//                                     <VisibilityOffIcon fontSize="small" />
//                                 ) : (
//                                     <VisibilityIcon fontSize="small" />
//                                 )}
//                             </CustomButton>
//                         )
//                     }
//                     debounceTime={0}
//                     {...adjustedProps}
//                 />
//                 <CustomButton
//                     id="check-tel-custom-button"
//                     styleProps={{
//                         backgroundColor: 'rgba(0, 0, 139, 0.8)',
//                         borderRadius: '20px',
//                         fontWeight: 600,
//                     }}
//                     onClick={handleCheckPassword}>
//                     Tiếp tục
//                 </CustomButton>
//                 <Stack
//                     direction="row"
//                     spacing={1}
//                     alignItems="center"
//                     justifyContent="center">
//                     <Typography>hoặc có thể</Typography>
//                     <CustomButton
//                         id="zalo-otp-type-custom-button"
//                         variant="base"
//                         styleProps={{
//                             ':hover': {
//                                 backgroundColor: 'transparent',
//                                 textDecoration: 'underline',
//                             },
//                         }}
//                         onClick={() => {
//                             setData((data) => {
//                                 let updatedData = { ...data };
//                                 updatedData.password = '';
//                                 return updatedData;
//                             });
//                             setError((error) => {
//                                 let updatedError = { ...error };
//                                 updatedError.password = '';
//                                 return updatedError;
//                             });
//                             onChangeStep('zalo-otp-input');
//                         }}>
//                         nhận mã OTP qua Zalo
//                     </CustomButton>
//                 </Stack>
//             </Stack>
//         </Box>
//     );
// }
function RenderOTPInput({
    adjustedProps = {},
    onChangeStep = () => {},
    onClose = () => {},
    handleLogin = () => {},
    ...otherProps
}) {
    let { data, setData } = adjustedProps;

    const [otpDigits, setOtpDigits] = useState(defaultOtpDigits);
    const [otpInputError, setOtpInputError] = useState('');

    const inputRefs = useRef([null, null, null, null, null, null]);

    const [isCheckingOTP, setIsCheckingOTP] = useState(false); // use to keep run otp valid count down
    const [
        otpValidMinuteRemaining,
        otpValidSecondRemaining,
        resetOtpCountdown,
    ] = useCountdown(5, 0, isCheckingOTP);
    const [canSendNewOtp, setCanSendNewOtp] = useState(true); // use to keep run count down of send new otp
    const [minuteRemaining, secondRemaining, resetSendOtpCountdown] =
        useCountdown(0, 30, !canSendNewOtp);

    //function get OTP
    const { mutate: getOTP, isPending: isGettingOTP } = useMutation({
        mutationFn: mutateData,
        onSuccess: (data, variables, context) => {
            setIsCheckingOTP(true);
            resetOtpCountdown();
            setCanSendNewOtp(false);
            resetSendOtpCountdown();
            setTimeout(() => {
                setCanSendNewOtp(true);
            }, 31000); // accept to request new otp after 31s
        },
        onError: (err, variables, context) => {
            setOtpInputError(
                `${err.message} ${err.errorInfo ? `(${err.errorInfo})` : ''}`
            );
        },
    });
    const sendOTP = () => {
        if (!canSendNewOtp) {
            return;
        }
        getOTP({
            apiUrl: `/user/send-otp`,
            formData: {
                data: {
                    tel: data.tel,
                },
            },
        });
    };

    useEffect(() => {
        let timeout = setTimeout(() => sendOTP(), 200);
        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let remainingValidOTP = useMemo(() => {
        let min = otpValidMinuteRemaining;
        let sec = otpValidSecondRemaining;
        if (min > 0 && sec > 0) {
            return ` có hiệu lực trong ${min} phút ${sec} giây`;
        }
        if (min === 0 && sec > 0) {
            return ` có hiệu lực trong ${sec} giây`;
        } else {
            return ' đã hết hiệu lực';
        }
    }, [otpValidMinuteRemaining, otpValidSecondRemaining]);

    let countdownText = useMemo(() => {
        let sec = secondRemaining;
        if (sec > 0) {
            return `Gửi lại mã xác thực cho tôi sau ${sec} giây)`;
        } else return '';
    }, [secondRemaining]);

    // function submit OTP
    const { mutate: checkOtp, isPending: isSubmittingOTP } = useMutation({
        mutationFn: mutateData,
        onSuccess: (data, variables, context) => {
            if (data.data.isValid) {
                handleLogin(data.data);
                setData(data.data.userInfo);
                onClose();
                enqueueSnackbar(
                    <CustomAlertBlock
                        id={'login-otp-success-snackbar'}
                        severity="success"
                        title="Đăng nhập thành công"
                        onClose={() => {
                            closeSnackbar('login-otp-success-snackbar');
                        }}
                    />,
                    {
                        variant: 'success',
                        key: 'login-otp-success-snackbar',
                    }
                );
            } else {
                setOtpInputError(data.data.message);
            }
        },
        onError: (err, variables, context) => {
            setOtpInputError(
                `${err.message} ${err.errorInfo ? `(${err.errorInfo})` : ''}`
            );
        },
    });

    const handleCheckOtp = () => {
        let validateOtp = validateInputValue('otp', otpDigits);
        if (validateOtp) {
            setOtpInputError(validateOtp);
            return;
        }
        checkOtp({
            apiUrl: '/user/check-otp',
            formData: {
                data: {
                    tel: data.tel,
                    otp: Object.values(otpDigits).join(''),
                },
            },
        });
    };
    const otpInputs = useMemo(
        () =>
            Object.keys(otpDigits).map((key, index) => (
                <CustomNumberInputWithProps
                    key={index + '-CustomNumberInputWithProps'}
                    name={key + '-CustomNumberInputWithProps'}
                    value={otpDigits[key]}
                    onChange={(value) => {
                        setOtpInputError('');
                        const newOtpDigits = { ...otpDigits, [key]: value };
                        setOtpDigits(newOtpDigits);
                        if (value && inputRefs.current[index + 1]) {
                            inputRefs.current[index + 1].focus();
                        }
                    }}
                    error={otpInputError}
                    prevRef={inputRefs.current[index - 1] || null}
                    nextRef={inputRefs.current[index + 1] || null}
                    inputRef={(el) => (inputRefs.current[index] = el)}
                />
            )),
        [inputRefs, otpDigits, otpInputError]
    );

    return (
        <Box px={2} mt={4}>
            <Header children="Nhập mã xác thực" />
            <Typography
                textAlign="center"
                maxWidth={350}
                sx={{ mx: 'auto', mt: 2 }}>
                {`Mã xác thực được gửi đến tài khoản Zalo `}
                <b>{data.tel}</b>
                {remainingValidOTP}
            </Typography>
            <ButtonChangeTel
                onClick={() => {
                    setData((data) => {
                        let updatedData = { ...data };
                        updatedData.tel = '';
                        return updatedData;
                    });
                    setOtpDigits(defaultOtpDigits);
                    onChangeStep('tel-input');
                }}
            />
            <Stack spacing={2} direction="row" my={2}>
                {otpInputs}
            </Stack>
            {otpInputError && (
                <Typography
                    textAlign="center"
                    color={getColorRGBA('error')}
                    fontSize={14}>
                    {otpInputError}
                </Typography>
            )}
            <Stack spacing={2} justifyContent="center" my={2}>
                <CustomButton
                    id="check-otp-custom-button"
                    styleProps={{
                        backgroundColor: 'rgba(0, 0, 139, 0.8)',
                        borderRadius: '20px',
                        fontWeight: 600,
                    }}
                    fullWidth={true}
                    disabled={isSubmittingOTP || isGettingOTP}
                    onClick={handleCheckOtp}>
                    {isSubmittingOTP ? 'Đang xác thực...' : 'Xác nhận'}
                </CustomButton>
                {isGettingOTP ? (
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        spacing={2}>
                        <CustomCircularProgress size={20} />
                        <Typography color="darkblue">
                            Đang gửi OTP...
                        </Typography>
                    </Stack>
                ) : !canSendNewOtp && countdownText ? (
                    <Typography
                        sx={{
                            textAlign: 'center',
                            color: 'darkblue',
                            fontWeight: 500,
                        }}>
                        {countdownText}
                    </Typography>
                ) : (
                    <Typography
                        sx={{
                            textAlign: 'center',
                            color: 'darkblue',
                            fontWeight: 500,
                            ':hover': {
                                color: 'darkblue',
                                backgroundColor: 'transparent',
                                textDecoration: 'underline',
                            },
                            cursor: 'pointer',
                        }}
                        onClick={() => sendOTP()}>
                        Gửi lại mã xác thực cho tôi
                    </Typography>
                )}
            </Stack>
            <div
                style={{
                    textAlign: 'center',
                    paddingTop: '8px',
                }}>
                <Typography component="span">
                    Nếu chưa có <b style={{ color: 'blue' }}>Zalo</b>, vui lòng
                    liên hệ hotline{' '}
                </Typography>
                <PhoneHref />
                <Typography component="span">{' để được hỗ trợ.'}</Typography>
            </div>
        </Box>
    );
}
export default function LoginDialog({
    open = false,
    onClose = () => {},
    ...props
}) {
    const dispatch = useDispatch();
    const [stepName, setStepName] = useState('tel-input');
    const [userData, setUserData] = useState(defaultUserData);
    const [formError, setFormError] = useState(defaultError);

    const adjustedProps = {
        data: userData,
        setData: setUserData,
        error: formError,
        setError: setFormError,
    };

    return (
        <CustomDialog
            id="login-custom-dialog"
            severity="warning"
            title=""
            open={open}
            maxWidth={'xs'}
            onClose={onClose}
            hideNoButton={true}
            hideYesButton={true}>
            <CustomButton
                id="close-custom-button"
                variant="base"
                color="disabled"
                onClick={onClose}
                styleProps={{ position: 'absolute', top: 10, right: 10 }}
                useIconButton={true}>
                <CloseIcon />
            </CustomButton>
            {stepName === 'tel-input' && (
                <RenderTelInput
                    adjustedProps={adjustedProps}
                    onChangeStep={(name) => setStepName(name)}
                />
            )}
            {stepName === 'not-signup' && (
                <RenderNotSignup
                    adjustedProps={adjustedProps}
                    onChangeStep={(name) => setStepName(name)}
                />
            )}
            {stepName === 'type-select' && (
                <RenderTypeSelect
                    adjustedProps={adjustedProps}
                    onChangeStep={(name) => setStepName(name)}
                />
            )}
            {/* {stepName === 'password-input' && (
                <RenderPasswordInput
                    adjustedProps={adjustedProps}
                    onChangeStep={(name) => setStepName(name)}
                    onClose={onClose}
                    handleLogin={(data) => {
                        login(data);
                        dispatch(userInfoActions.updateUserInfo(data.userInfo));
                    }}
                />
            )} */}
            {stepName === 'zalo-otp-input' && (
                <RenderOTPInput
                    adjustedProps={adjustedProps}
                    onChangeStep={(name) => setStepName(name)}
                    onClose={onClose}
                    handleLogin={(data) => {
                        login(data);
                        dispatch(userInfoActions.updateUserInfo(data.userInfo));
                    }}
                />
            )}
        </CustomDialog>
    );
}
