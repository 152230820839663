import { useState } from 'react';

import { Box, Typography, Grid, Collapse } from '@mui/material';
import {
    KeyboardArrowRight as KeyboardArrowRightIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
} from '@mui/icons-material';

import { underlineWithTransition } from 'config/customCSS.js';
import { CustomButton } from 'UI';

import CategoryCardItem from './CategoryCardItem.jsx';
import CategoryListItem from './CategoryListItem.jsx';

export default function CategorySection({
    cateLv1Path = '',
    subCatgories = [],
    parentCategories = [],
    currentLevel = 1,
    ...otherProps
}) {
    const [showCollapse, setShowCollapse] = useState({
        1: false,
        2: false,
        3: false,
    });
    const [showMaxHeight, setShowMaxHeight] = useState({
        1: false,
        2: false,
        3: false,
    });
    if (
        (cateLv1Path === 'thuc-pham-chuc-nang' && currentLevel === 1) ||
        (cateLv1Path !== 'thuc-pham-chuc-nang' && currentLevel === 2)
    ) {
        return (
            <Box sx={{ width: 'calc(100% - 12px)' }}>
                <Collapse
                    sx={{ minWidth: '100%' }}
                    in={showMaxHeight['3']}
                    collapsedSize={190}>
                    <Grid container spacing={2} my={1}>
                        {subCatgories.map((subCate) => {
                            return (
                                <Grid
                                    item
                                    xs={6}
                                    md={4}
                                    xl={3}
                                    zeroMinWidth
                                    key={Math.random() * 1000}>
                                    <CategoryCardItem
                                        cateLv1Path={cateLv1Path}
                                        id={subCate.id}
                                        name={subCate.name}
                                        slug={subCate.slug}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Collapse>
                <Grid
                    container
                    sx={{
                        pb: 1,
                        display: showMaxHeight['3'] ? 'none' : 'block',
                    }}>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        <CustomButton
                            id={Math.random() * 1000}
                            variant="base"
                            styleProps={{
                                width: '100px',
                                mx: 'auto',
                            }}
                            onClick={() => {
                                setShowMaxHeight((prev) => {
                                    let updatedValue = { ...prev };
                                    updatedValue['3'] = !updatedValue['3'];
                                    return updatedValue;
                                });
                            }}
                            startIcon={
                                <KeyboardDoubleArrowDownIcon fontSize="small" />
                            }>
                            Xem tất cả
                        </CustomButton>
                    </Grid>
                </Grid>
            </Box>
        );
    } else if (cateLv1Path !== 'thuc-pham-chuc-nang' && currentLevel === 1) {
        return parentCategories.map((parentCate, index) => {
            return (
                <Box mt={1} key={Math.random() * 1000}>
                    <CustomButton
                        id={Math.random() * 1000}
                        size="small"
                        variant="base"
                        onClick={() => {
                            setShowCollapse((prev) => {
                                let updatedValue = { ...prev };
                                updatedValue[index] = !updatedValue[index];
                                return updatedValue;
                            });
                        }}
                        startIcon={
                            showCollapse[index] ? (
                                <KeyboardArrowDownIcon />
                            ) : (
                                <KeyboardArrowRightIcon />
                            )
                        }>
                        <Typography
                            fontSize="16px"
                            sx={{
                                color: 'rgba(0, 0, 139, 0.8)',
                                ...underlineWithTransition,
                                cursor: 'pointer',
                            }}
                            fontWeight={500}>
                            {parentCate.name}
                        </Typography>
                    </CustomButton>
                    <Box
                        pr={1.5}
                        sx={{
                            display: showCollapse[index] ? 'block' : 'none',
                        }}>
                        <Collapse
                            sx={{ minWidth: '100%' }}
                            in={showMaxHeight[index]}
                            collapsedSize={90}>
                            <Box sx={{ width: '100%' }}>
                                <Grid
                                    container
                                    rowSpacing={1}
                                    sx={{
                                        backgroundColor: 'white',
                                        py: 1,
                                        px: 2,
                                        my: 1,
                                        borderRadius: showMaxHeight[index]
                                            ? '10px'
                                            : '10px 10px 0 0',
                                    }}>
                                    {subCatgories
                                        .filter(
                                            (i) =>
                                                i.parentName === parentCate.name
                                        )
                                        .map((subCate) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={6}
                                                    md={4}
                                                    xl={3}
                                                    key={Math.random() * 1000}>
                                                    <CategoryListItem
                                                        cateLv1Path={
                                                            cateLv1Path
                                                        }
                                                        id={subCate.id}
                                                        name={subCate.name}
                                                        slug={subCate.slug}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                </Grid>
                            </Box>
                        </Collapse>
                        <Grid
                            container
                            sx={{
                                backgroundColor: 'white',
                                pb: 1,
                                borderRadius: showMaxHeight[index]
                                    ? '10px'
                                    : '0 0 10px 10px',
                                display: showMaxHeight[index]
                                    ? 'none'
                                    : 'block',
                            }}>
                            <Grid item xs={12} sx={{ display: 'flex' }}>
                                <CustomButton
                                    id={Math.random() * 1000}
                                    variant="base"
                                    styleProps={{
                                        width: '100px',
                                        mx: 'auto',
                                    }}
                                    onClick={() => {
                                        setShowMaxHeight((prev) => {
                                            let updatedValue = { ...prev };
                                            updatedValue[index] =
                                                !updatedValue[index];
                                            return updatedValue;
                                        });
                                    }}
                                    startIcon={
                                        <KeyboardDoubleArrowDownIcon fontSize="small" />
                                    }>
                                    Xem tất cả
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            );
        });
    } else return null;
}
