import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    Paper,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import Draggable from 'react-draggable';

import { CustomButton, CustomLinearProgress } from 'UI';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export default function CustomDialog({
    id, // required
    severity = 'default', // accept: default, error, success, info, warning
    title = 'Dialog title', // required, accept a string or a node element
    open = false, // required
    fullScreen = false,
    maxWidth = 'md', // accepted: sm, md, lg, xl, false (to disable)
    fullWidth = true,
    loading = false,
    onClose = () => {}, // required
    labelNoButon = 'Bỏ qua', // required
    hideNoButton = true,
    onNo = () => {}, // required
    labelYesButon = 'Tiếp tục', // required
    hideYesButton = false,
    onYes = () => {}, // required
    children, // required
    hideCloseIcon = false,
    DialogProps,
    DialogTitleProps,
    DialogTitleTypographyProps,
    DialogContentProps,
    DialogActionsProps,
    ...otherProps
}) {
    return (
        <Dialog
            open={open}
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            onClose={onClose}
            scroll="paper"
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            {...DialogProps}
            {...otherProps}>
            {title && (
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        cursor: 'move',
                    }}
                    {...DialogTitleProps}>
                    {typeof title === 'string' ? (
                        <Typography
                            variant="h6"
                            component="div"
                            noWrap
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            {...DialogTitleTypographyProps}>
                            {title}
                        </Typography>
                    ) : (
                        title
                    )}
                    {!hideCloseIcon && (
                        <CustomButton
                            aria-label="close"
                            onClick={onClose}
                            id="dialog-close-icon-button"
                            disabled={hideCloseIcon}
                            variant="standard"
                            color="disabled"
                            size="small"
                            useIconButton={true}>
                            <CloseIcon fontSize="small" />
                        </CustomButton>
                    )}
                </DialogTitle>
            )}
            {loading && (
                <Box sx={{ mt: -2, mx: -3 }}>
                    <CustomLinearProgress />
                </Box>
            )}
            <DialogContent
                dividers={typeof children === 'string' ? false : true}
                {...DialogContentProps}>
                {typeof children === 'string' ? (
                    <DialogContentText
                        id={`${id ? id + '-' : ''}dialog-description`}>
                        {children}
                    </DialogContentText>
                ) : (
                    children
                )}
            </DialogContent>
            {(!hideNoButton || !hideYesButton) && (
                <DialogActions {...DialogActionsProps}>
                    {!hideNoButton && (
                        <CustomButton
                            id="no-custom-button"
                            variant="text"
                            color="black"
                            onClick={onNo}
                            disabled={hideNoButton || loading}>
                            {labelNoButon}
                        </CustomButton>
                    )}
                    {!hideYesButton && (
                        <CustomButton
                            id="yes-custom-button"
                            variant="contained"
                            color={severity}
                            onClick={onYes}
                            disabled={hideYesButton || loading}
                            autoFocus>
                            {labelYesButon}
                        </CustomButton>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
}
