import { Suspense } from 'react';

import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient } from 'utils/http.js';
import { Provider as ReduxProvider } from 'react-redux';
import store from 'store/index.js';

import { themeConfig } from 'config/theme.js';
import { routerConfig } from 'config/router.js';
import { snackbarConfig } from 'config/snackbar.js';
import { CustomLoadingFallback } from 'UI';

const theme = createTheme(themeConfig);
const router = createBrowserRouter(routerConfig);

export default function App() {
    return (
        <HelmetProvider>
            <ReduxProvider store={store}>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Suspense fallback={<CustomLoadingFallback />}>
                            <RouterProvider router={router} />
                        </Suspense>
                        <SnackbarProvider {...snackbarConfig} />
                    </ThemeProvider>
                </QueryClientProvider>
            </ReduxProvider>
        </HelmetProvider>
    );
}
