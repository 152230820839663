export const drawerWidth = 320;
export const customScrollbar = {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
        width: '0.05em',
        height: '0.05em',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(3,37,126, 0.8)',
        borderRadius: '25px',
    },
    '&::-webkit-scrollbar-track-piece:start': {
        backgroundColor: 'transparent',
        marginTop: '10px',
        marginLeft: '10px',
    },
    '&::-webkit-scrollbar-track-piece:end': {
        backgroundColor: 'transparent',
        marginBottom: '10px',
        marginRight: '10px',
    },
};
export const hideScrollbar = {
    overflow: 'auto',
    'msOverflowStyle': 'none' /* Internet Explorer 10+ */,
    'scrollbarWidth': 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
        display: 'none' /* Safari and Chrome */,
    },
};
export const underlineWithTransition = {
    pb: '2px',
    backgroundImage: 'linear-gradient(darkblue 0 0)',
    backgroundPosition: '0 100%',
    backgroundSize: '0% 1px',
    backgroundRepeat: 'no-repeat',
    transition: 'background-size 0.3s , background-position 0s 0.3s',
    ':hover': {
        backgroundColor: 'transparent',
        backgroundPosition: ' 100% 100%',
        backgroundSize: '100% 2px',
    },
    cursor: 'default',
};
export const borderWithTransition = {
    border: '1px solid rgba(0,0,0,0.08)',
    borderRadius: '10px',
    transition: 'all 0.3s ease-in-out',
    '&: hover': {
        borderColor: '#0062cc',
        borderWidth: '1px',
        borderStyle: 'solid',
        boxShadow: 2,
    },
    backgroundColor: 'white',
};
export const typeBoxSx = {
    display: 'flex',
    flexDirection: 'column',
};
export const cellBoxSx = {
    m: 0.1,
    p: 0.5,
    borderTop: '0.5px solid darkblue',
    borderBottom: '0.5px solid darkblue',
    borderLeft: '0.5px solid darkblue',
    borderRight: '0.5px solid darkblue',
};
export const cellTypographySx = {
    variant: 'body2',
    fontSize: 16,
    lineHeight: 1.5,
    textTransform: 'initial',
    textAlign: 'center',
};
export const lineBoxSx = {
    pl: 2,
    display: 'flex',
    alignItems: 'flex-start',
};
export const lineTypographySx = {
    variant: 'body2',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 2,
    textTransform: 'initial',
    color: 'black',
    fontStyle: 'normal',
    textDecoration: 'none',
    textAlign: 'left',
    fontFamily: 'Arial !important',
    width: '100%',
};
export const boxPropsNameMap = {
    header1: {
        padding: 0,
        backgroundColor: 'transparent',

        my: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    header2: {
        padding: 0,
        backgroundColor: 'transparent',

        my: 0.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    paragraph: {
        padding: 0,
        backgroundColor: 'transparent',
        my: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    quote: {
        borderRadius: '8px',
        padding: '20px',
        backgroundColor: 'whitesmoke',
        opacity: 0.95,
        boxShadow: 2,
        my: 2,
        width: '80%',
        mx: 'auto',
        position: 'relative',
        marginBottom: '20px',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        '::before': {
            content: '"\\201C"',
            position: 'absolute',
            left: '5px',
            top: '-5px',
            fontSize: '60px',
            fontFamily: 'Georgia',
            fontWeight: 'bold',
            color: 'darkblue',
        },
        '::after': {
            content: '"\\201D"',
            position: 'absolute',
            right: '10px',
            bottom: '-25px',
            fontSize: '60px',
            fontFamily: 'Georgia',
            fontWeight: 'bold',
            color: 'darkblue',
        },
    },
};
export const typographyPropsNameMap = {
    header1: {
        variant: 'h1',
        fontWeight: 700,
        fontSize: 20,
        lineHeight: 2,
        textTransform: 'uppercase',
        color: 'darkblue',
        fontStyle: 'normal',
        textDecoration: 'none',
        textAlign: 'justify',
    },
    header2: {
        variant: 'h2',
        fontWeight: 600,
        fontSize: 18,
        lineHeight: 2,
        textTransform: 'initial',
        color: 'black',
        fontStyle: 'normal',
        textDecoration: 'none',
        textAlign: 'justify',
    },
    paragraph: {
        variant: 'body2',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 1.8,
        textTransform: 'initial',
        color: 'black',
        fontStyle: 'normal',
        textDecoration: 'none',
        textAlign: 'justify',
    },
    quote: {
        variant: 'body2',
        color: 'black',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 0.9,
        fontStyle: 'italic',
        textTransform: 'initial',
        m: 2,
    },
};
