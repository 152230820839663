import {
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    Checkbox,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    CheckBoxRounded as CheckBoxRoundedIcon,
    CheckBoxOutlineBlankRounded as CheckBoxOutlineBlankRoundedIcon,
    CheckBoxOutlined as CheckBoxOutlinedIcon,
} from '@mui/icons-material';

import { getColorRGBA } from 'utils/getColorRGBA.js';

const StyledCheckbox = styled((props) => (
    <Checkbox
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme, ...props }) => {
    const { size, color, margin, labelPlacement } = props;
    let chosenColor = getColorRGBA(color);

    return {
        '&.MuiCheckbox-root': {
            marginTop:
                labelPlacement === 'start' || labelPlacement === 'end'
                    ? size === 'small'
                        ? margin === 'normal'
                            ? '-7px'
                            : margin === 'dense'
                            ? '-3px'
                            : 0
                        : margin === 'normal'
                        ? '-10px'
                        : margin === 'dense'
                        ? '-6px'
                        : '-2px'
                    : 0,
            padding:
                margin === 'normal'
                    ? '9px'
                    : margin === 'dense'
                    ? '5px'
                    : '1px',
            color: 'grey',
            '&.Mui-checked': {
                color: chosenColor,
            },
            '&.Mui-disabled': {
                color: '#E9E9EA',
            },
        },
    };
});
export default function CustomCheckbox({
    id, // required
    options = [],
    /*  required,
        [{
            name: 'test',
            label: 'Content',
            required: true/false,
            checked: true/false,
            disabled: true/false,
        }]
    */
    onChange = () => {}, // required
    variant = 'outlined', // accepted: filled, outlined, standard
    formLabel = '',
    size = 'medium', // accepted: medium, small
    color = 'default', // accepted: default, success, error, info, warning
    margin = 'normal', // accepted: normal, dense, none
    icon = <CheckBoxOutlineBlankRoundedIcon />,
    checkedIcon = variant === 'filled' || variant === 'standard' ? (
        <CheckBoxRoundedIcon />
    ) : (
        <CheckBoxOutlinedIcon />
    ),
    helperText = '',
    error = '',
    disabled: formDisabled = false,
    required = false,
    labelPlacement = 'end', // accepted: top, bottom, start, end
    row = false,
    FormControlProps,
    FormLabelProps,
    FormGroupProps,
    FormHelperTextProps,
    ...otherProps
}) {
    const handleChange = (event) => {
        let name = event.target.name;
        let checked = event.target.checked;
        onChange({ name, checked });
    };
    return (
        <FormControl
            component="fieldset"
            variant={'standard'}
            disabled={formDisabled}
            required={required}
            margin={margin}
            error={Boolean(error)}
            {...FormControlProps}
            {...otherProps}>
            <FormLabel component="legend" {...FormLabelProps}>
                {formLabel}
            </FormLabel>
            {options.length > 0 && (
                <FormGroup row={row} {...FormGroupProps}>
                    {options.map((opt, idx) => {
                        const {
                            name = `Checkbox ${idx + 1}`,
                            label = `Checkbox ${idx + 1}`,
                            disabled: formControlDisabled = false,
                            checked = false,
                            required = false,
                        } = opt;
                        return (
                            <FormControlLabel
                                key={name || label}
                                disabled={formControlDisabled}
                                labelPlacement={labelPlacement}
                                sx={{
                                    '&.MuiFormControlLabel-root': {
                                        mx: 0,
                                        my:
                                            margin === 'normal'
                                                ? 1
                                                : margin === 'dense'
                                                ? 0.5
                                                : 0,
                                        display: 'flex',
                                        alignItems:
                                            labelPlacement === 'top' ||
                                            labelPlacement === 'bottom'
                                                ? 'center'
                                                : 'flex-start',
                                    },
                                    ...(labelPlacement === 'start' && {
                                        '.MuiFormControlLabel-label': {
                                            direction: 'rtl',
                                            minHeight: '100%',
                                            mr: 0.5,
                                            textAlign: 'right',
                                        },
                                    }),
                                    ...(labelPlacement === 'end' && {
                                        '.MuiFormControlLabel-label': {
                                            direction: 'ltr',
                                            minHeight: '100%',
                                            ml: 0.5,
                                        },
                                    }),
                                    ...((labelPlacement === 'top' ||
                                        labelPlacement === 'bottom') && {
                                        '.MuiFormControlLabel-label': {
                                            textAlign: 'center',
                                        },
                                    }),
                                }}
                                control={
                                    <StyledCheckbox
                                        size={size}
                                        color={color}
                                        margin={margin}
                                        checked={checked}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        disableRipple
                                        onChange={handleChange}
                                        name={name || label}
                                        labelPlacement={labelPlacement}
                                    />
                                }
                                label={label + (required ? ' *' : '')}
                            />
                        );
                    })}
                </FormGroup>
            )}
            <FormHelperText id={`${id}-helper-text`} {...FormHelperTextProps}>
                {error ? error : helperText}
            </FormHelperText>
        </FormControl>
    );
}
