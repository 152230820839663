import { Stack, Typography } from '@mui/material';
import {
    ListAlt as ListAltIcon,
    GridViewOutlined as GridViewOutlinedIcon,
    GridViewSharp as GridViewSharpIcon,
    FilterListOutlined as FilterListOutlinedIcon,
} from '@mui/icons-material';

import { CustomToggleButton, CustomButton } from 'UI';

export default function Header({
    viewType = 'vertical',
    setViewType = () => {},
    setIsDrawerOpen = () => {},
    label = 'Danh sách sản phẩm',
    ...otherProps
}) {
    return (
        <Stack
            px={2}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}>
            <Typography component="h2" fontSize="18px" fontWeight={600}>
                {label}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
                <CustomButton
                    id={Math.random() * 1000}
                    variant="outlined"
                    size="small"
                    onClick={() => {
                        setIsDrawerOpen(true);
                    }}
                    styleProps={{
                        color: 'black',
                        borderRadius: '20px',
                        backgroundColor: '#EDEDED',
                        ':hover': {
                            backgroundColor: '#EDEDED',
                            borderColor: 'lightgrey',
                        },
                        borderColor: 'lightgrey',
                        display: { lg: 'none' },
                    }}
                    startIcon={<FilterListOutlinedIcon fontSize="small" />}
                    children="Lọc"
                />
                <CustomToggleButton
                    id="view-type-custom-toggle-button"
                    sx={{
                        '&.MuiToggleButtonGroup-root': {
                            borderRadius: '20px',
                            alignItems: 'center',
                            border: '1px solid lightgrey',
                        },
                        '& .MuiToggleButtonGroup-grouped': {
                            p: '1px',

                            '&.Mui-disabled': {
                                borderRadius: 0,
                            },
                            '&:first-of-type': {
                                borderRadius: '20px',
                                border: 'none',
                                backgroundColor:
                                    viewType === 'vertical'
                                        ? '#EDEDED'
                                        : 'transparent',
                                p: 0.75,
                            },
                            '&:not(:first-of-type)': {
                                borderRadius: '20px',
                                border: 'none',
                                backgroundColor:
                                    viewType === 'horizontal'
                                        ? '#EDEDED'
                                        : 'transparent',
                                p: 0.75,
                            },
                        },
                    }}
                    options={[
                        {
                            value: 'vertical',
                            icon:
                                viewType === 'vertical' ? (
                                    <GridViewSharpIcon
                                        fontSize="small"
                                        sx={{
                                            color: 'black',
                                            width: 18,
                                            height: 18,
                                        }}
                                    />
                                ) : (
                                    <GridViewOutlinedIcon
                                        fontSize="small"
                                        sx={{
                                            color: 'grey',
                                            width: 18,
                                            height: 18,
                                        }}
                                    />
                                ),
                        },
                        {
                            value: 'horizontal',
                            icon: (
                                <ListAltIcon
                                    fontSize="small"
                                    sx={{
                                        color:
                                            viewType === 'horizontal'
                                                ? 'black'
                                                : 'grey',
                                        width: 18,
                                        height: 18,
                                    }}
                                />
                            ),
                        },
                    ]}
                    value={viewType}
                    onChane={(value) => {
                        setViewType(value);
                    }}
                    exclusive={true}
                    size="small"
                />
            </Stack>
        </Stack>
    );
}
