import { useState, useEffect, useRef } from 'react';
import {
    FormControl,
    InputLabel,
    Input,
    FilledInput,
    OutlinedInput,
    FormHelperText,
    InputAdornment,
} from '@mui/material';

import { getColorRGBA } from 'utils/getColorRGBA.js';

const inputName = {
    standard: Input,
    outlined: OutlinedInput,
    filled: FilledInput,
};
function getStyles({
    size = 'medium',
    color = 'disabled',
    variant = 'outlined',
    multiline = false,
}) {
    let chosenColor = getColorRGBA(color);

    let css = {
        standard: {
            '& label': {
                borderRadius: '5px',
                '&.Mui-focused': {
                    color: chosenColor,
                },
                ':hover': {
                    color: chosenColor,
                },
                '&.Mui-error': {
                    color: 'rgb(211, 47, 47)',
                },
                '&.Mui-disabled': {
                    color: 'rgb(0,0,0,0.26)',
                },
            },
            '& p': {
                '&.MuiFormHelperText-root': {
                    ml: 0,
                },
            },
            '& .MuiInputBase-root': {
                height: multiline ? 'auto' : size === 'small' ? 29 : 32,
                borderRadius: '5px',
                color: chosenColor,
                ':hover:not(.Mui-disabled, .Mui-error):before': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                },
                '::after': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                    '&:hover': {
                        borderBottom: `1px solid ${chosenColor}`,
                    },
                },
                '&.Mui-disabled:before': {
                    borderBottom: '1px solid rgb(0,0,0,0.26)',
                },
            },
        },
        filled: {
            '& label': {
                borderRadius: '5px',
                px: 1,
                ml: -0.75,
                '&.Mui-focused': {
                    color: chosenColor,
                },
                ':hover': {
                    color: chosenColor,
                },
                '&.Mui-error': {
                    color: 'rgb(211, 47, 47)',
                },
                '&.Mui-disabled': {
                    color: 'rgb(0, 0, 0, 0.26)',
                },
            },
            '& .MuiFilledInput-root': {
                height: multiline ? 'auto' : size === 'small' ? 48 : 56,
                backgroundColor: 'aliceblue',
                borderRadius: '5px 5px 0 0',
                color: chosenColor,
                ':hover:not(.Mui-disabled, .Mui-focused)': {
                    backgroundColor: 'rgba(0,40,139,0.1)',
                },
                '&.Mui-focused': {
                    backgroundColor: 'aliceblue',
                },
                ':hover:not(.Mui-disabled, .Mui-error):before': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                },
                '::after': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                    '&:hover': {
                        borderBottom: `1px solid ${chosenColor}`,
                    },
                },
                '&.Mui-error:focus': {
                    borderBottom: '1px solid rgb(176, 0, 32)',
                },
                '&.Mui-disabled': {
                    backgroundColor: 'lightgrey',
                },
                '&.Mui-disabled:before': {
                    borderBottom: '1px solid rgb(0,0,0,0.26)',
                },
                '&.Mui-disabled:after': {
                    borderBottom: '1px solid rgb(0,0,0,0.26)',
                },
                '& div.MuiInputAdornment-root': {
                    alignItems: 'start',
                },
            },
        },
        outlined: {
            '& label': {
                borderRadius: '5px',
                backgroundColor: 'white',
                px: 1,
                ml: -0.75,
                '&.Mui-focused': {
                    color: chosenColor,
                },
                ':hover': {
                    color: chosenColor,
                },
                '&.Mui-error': {
                    color: 'rgb(211, 47, 47)',
                },
                '&.Mui-disabled': {
                    color: 'rgb(0,0,0,0.26)',
                },
            },
            '& .MuiOutlinedInput-root': {
                height: multiline ? 'auto' : size === 'small' ? 41 : 56,
                borderRadius: '5px',
                color: chosenColor,
                '& fieldset': {
                    borderRadius: '5px',
                    borderWidth: '1px',
                },
                '&:hover:not(.Mui-disabled, .Mui-error) fieldset': {
                    border: `1px solid ${chosenColor}`,
                },
                '&.Mui-focused fieldset': {
                    border: `1px solid ${chosenColor}`,
                },
                '&.Mui-error fieldset': {
                    border: '1px solid rgb(211, 47, 47)',
                },
                '&.Mui-disabled fieldset': {
                    border: '1px solid rgb(0,0,0,0.26)',
                },
            },
        },
    };

    return {
        ...css[variant],
        '& input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
            display: 'none',
        },
        '& input[type=number]::-webkit-outer-spin-button ': {
            WebkitAppearance: 'none',
            margin: 0,
            display: 'none',
        },
    };
}

export default function CustomInput(props) {
    const {
        name = '', // required
        id = `${name}-${Math.floor(Math.random() * 1000)}-custom-input`,
        type = 'text',
        value = '', // required if controlled
        onChange = () => {}, // required if controlled
        onKeyDown = () => {},
        autoComplete,
        autoFocus,
        FormControlProps,
        FormHelperTextProps,
        label = '',
        error = '',
        helperText = '',
        placeholder = '',
        inputProps,
        InputLabelProps,
        inputRef,
        fullWidth = true,
        required = false,
        readOnly = false,
        disabled = false,
        multiline = false,
        onBlur,
        onFocus,
        rows, // required if multiline is true
        minRows = 1, // required if multiline is true
        maxRows = 3, // required if multiline is true
        variant = 'outlined', // accepted: filled, outlined, standard
        color = 'default', // accepted: default, success, error, info, warning
        size = 'medium', // accepted: medium, small
        margin = 'normal', // accepted: normal, dense, none
        startAdornment = null,
        endAdornment = null,
        disableOnWheel = true,
        debounceTime = 300,
        styleProps = {},
        ...otherProps
    } = props;
    const debounceTimeout = useRef(null);
    const [typingValue, setTypingValue] = useState(value || '');
    const isTyping = useRef(false);

    useEffect(() => {
        return () => {
            clearTimeout(debounceTimeout.current);
        };
    }, []);

    useEffect(() => {
        // update typingValue when value props change and user is not typing
        if (!isTyping.current) {
            setTypingValue(value || '');
        }
    }, [value]);

    const handleInputChange = (event) => {
        const { value } = event.target;
        setTypingValue(value);

        // Clear previous timeout
        clearTimeout(debounceTimeout.current);

        // Setup new timeout
        debounceTimeout.current = setTimeout(() => {
            isTyping.current = false;
            // Trigger onChange after debounce time
            onChange(value);
        }, debounceTime);
    };

    const StyledInput = inputName[variant];
    const styles = getStyles({ size, color, variant, multiline });

    return (
        <FormControl
            variant={variant}
            fullWidth={fullWidth}
            disabled={disabled}
            required={required}
            size={size}
            error={Boolean(error)}
            margin={margin}
            sx={{ ...styles, ...styleProps }}
            {...FormControlProps}
            {...otherProps}>
            <InputLabel htmlFor={id} {...InputLabelProps}>
                {label}
            </InputLabel>
            <StyledInput
                id={id}
                name={name}
                type={type}
                inputRef={inputRef}
                aria-describedby={`${id}-helper-text`}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                fullWidth={fullWidth}
                multiline={multiline}
                rows={rows}
                minRows={minRows}
                maxRows={maxRows}
                onBlur={onBlur}
                value={typingValue}
                onChange={handleInputChange}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                placeholder={placeholder}
                inputProps={{
                    ...(readOnly && {
                        readOnly: true,
                    }),
                    sx: {
                        '&::placeholder': {
                            opacity: 0.8,
                            fontSize: '14px',
                        },
                    },
                    ...inputProps,
                    ...{
                        style: {
                            ...(type === 'number' && { textAlign: 'right' }),
                            ...(inputProps?.style || {}),
                        },
                    },
                }}
                onWheel={disableOnWheel ? (e) => e.target.blur() : undefined}
                startAdornment={
                    startAdornment ? (
                        <InputAdornment position="start">
                            {startAdornment}
                        </InputAdornment>
                    ) : undefined
                }
                endAdornment={
                    endAdornment ? (
                        <InputAdornment position="end">
                            {endAdornment}
                        </InputAdornment>
                    ) : undefined
                }
            />
            <FormHelperText id={`${id}-helper-text`} {...FormHelperTextProps}>
                {error ? error : helperText}
            </FormHelperText>
        </FormControl>
    );
}
