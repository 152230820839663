import { Stack, Skeleton, Box } from '@mui/material';

import { CustomMasonry, CustomLoadingModal } from 'UI';

import ProductItemSkeleton from './ProductItemSkeleton.jsx';

export default function PageProductSkeleton({ ...otherProps }) {
    return (
        <Stack sx={{ maxWidth: 1260, mx: 'auto', px: { xs: 0, sm: 2 } }}>
            <CustomLoadingModal isLoading={true} />
            <Stack spacing={1} mt={1} pl={{ xs: 3, lg: 0 }}>
                <Skeleton variant="text" width={300} height={24} />
                <Skeleton variant="text" width={200} height={30} />
            </Stack>
            <Stack
                direction="row"
                alignItems="flex-start"
                spacing={0.5}
                sx={{ pt: 2 }}>
                <Skeleton
                    variant="rectangular"
                    width={250}
                    sx={{
                        minHeight: 1000,
                        borderRadius: '10px',
                        border: '1px solid #EDEDED',
                        display: { xs: 'none', lg: 'block' },
                    }}
                />
                <Box width="100%">
                    <Stack
                        mx={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between">
                        <Skeleton variant="text" width={200} height={30} />
                        <Skeleton variant="rounded" width={50} height={20} />
                    </Stack>
                    <CustomMasonry
                        spacing={2}
                        elevation={1}
                        id="product-item-custom-masonry"
                        columns={{ xs: 2, lg: 3, xl: 4 }}
                        items={Array.from(
                            {
                                length: 8,
                            },
                            (x, i) => i
                        ).map((_) => ({
                            id: Math.random() * 1000,
                            content: <ProductItemSkeleton />,
                        }))}
                    />
                </Box>
            </Stack>
        </Stack>
    );
}
