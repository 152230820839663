import { createSlice } from '@reduxjs/toolkit';

const initialState = (() => {
    try {
        const history = localStorage.getItem('recentWatchProd');
        return history ? JSON.parse(history) : [];
    } catch (e) {
        console.error('Failed to parse recent watched from localStorage:', e);
        return [];
    }
})();

const recentWatchProdSlice = createSlice({
    name: 'recentWatchProd',
    initialState,
    reducers: {
        addRecentWatch: (state, action) => {
            const newValue = action.payload;
            // Remove newValue if it exists in the state
            const existingIndex = state.indexOf(newValue);
            if (existingIndex !== -1) {
                state.splice(existingIndex, 1); // Remove the existing value
            }
            // Add newValue to the beginning of the array
            state.unshift(newValue);
            localStorage.setItem('recentWatchProd', JSON.stringify(state));
        },
    },
});

export const recentWatchProdActions = recentWatchProdSlice.actions;
export default recentWatchProdSlice.reducer;
