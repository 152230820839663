import { Fragment, useState } from 'react';

import {
    Box,
    Drawer,
    List,
    ListItemButton,
    ListItemText,
    Typography,
    Stack,
} from '@mui/material';
import {
    Login as LoginIcon,
    Logout as LogoutIcon,
    Close as CloseIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Payments as PaymentsIcon,
    GroupsRounded as GroupsRoundedIcon,
    CollectionsBookmark as CollectionsBookmarkIcon,
    Person as PersonIcon,
    ShoppingBasket as ShoppingBasketIcon,
    PinDrop as PinDropIcon,
    WhatsApp as WhatsAppIcon,
    Facebook as FacebookIcon,
    YouTube as YouTubeIcon,
    Share as ShareIcon,
    Topic as TopicIcon,
    Newspaper as NewspaperIcon,
    Storefront as StorefrontIcon,
    Schedule as ScheduleIcon,
    Factory as FactoryIcon,
    Key as KeyIcon,
    MedicationLiquid as MedicationLiquidIcon,
    PersonPin as PersonPinIcon,
    EmojiEvents as EmojiEventsIcon,
} from '@mui/icons-material';

import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { customScrollbar, drawerWidth } from 'config/customCSS.js';
import breadcrumbNameMapping from 'data/breadcrumbNameMapping.json';

import { CustomButton, CustomAvatar, CustomCollapseList } from 'UI';

import LogoBrand from '../LogoBrand.jsx';
import StatusBadge from '../StatusBadge.jsx';

export default function SectionDrawer({
    handleLogout = () => {},
    window = undefined,
    open = false,
    toggleDrawer = () => {},
    handleLogin = () => {},
    ...otherProps
}) {
    const userInfo = useSelector((state) => state.userInfo.value);
    let { _id = null, username = '', tel = '', rewardPoint = null } = userInfo;

    const navigate = useNavigate();
    const location = useLocation().pathname;

    const container =
        window !== undefined ? () => window().document.body : undefined;
    const [isOpenCollases, setIsOpenCollapses] = useState({
        account: false,
        page: true,
        list: true,
        policy: false,
        contact: true,
    });
    const loginBox = (
        <Stack
            spacing={1.5}
            justifyContent="space-between"
            alignItems="flex-start"
            px={3}
            py={1}
            backgroundColor="rgba(0, 0, 139, 0.85)">
            <Stack spacing={0.5}>
                <Typography variant="subtitle2" fontSize={12} color="white">
                    Bạn chưa đăng nhập.
                </Typography>
                <Typography variant="subtitle2" fontSize={12} color="white">
                    Hãy đăng nhập để xem các khuyến mãi dành riêng cho thành
                    viên.
                </Typography>
            </Stack>
            <CustomButton
                disableRipple
                disableTouchRipple
                disableFocusRipple
                id="drawer-login-button"
                variant="contained"
                color="success"
                size="small"
                onClick={handleLogin}
                startIcon={<LoginIcon />}>
                Đăng nhập
            </CustomButton>
        </Stack>
    );
    const accountBox = (
        <Box sx={{ backgroundColor: 'aliceblue', p: 2 }}>
            <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between">
                <Stack direction="row" spacing={1} alignItems="flex-start">
                    <StatusBadge>
                        <CustomAvatar size={25}>
                            <PersonPinIcon sx={{ color: 'darkblue' }} />
                        </CustomAvatar>
                    </StatusBadge>
                    <Box>
                        <Typography
                            variant="subtitle2"
                            fontSize={16}
                            color="darkblue"
                            sx={{
                                maxWidth: 100,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {username}
                        </Typography>
                        <Typography variant="caption" color="grey">
                            {tel}
                        </Typography>
                    </Box>
                </Stack>
                <CustomButton
                    disableRipple
                    disableTouchRipple
                    disableFocusRipple
                    id="drawer-logout-button"
                    variant="contained"
                    color="warning"
                    size="small"
                    onClick={() => {
                        handleLogout();
                    }}
                    styleProps={{ px: 2, py: 0.5 }}
                    startIcon={<LogoutIcon />}>
                    Thoát
                </CustomButton>
            </Stack>
            {rewardPoint && (
                <Stack direction="row" spacing={1} alignItems="center">
                    <EmojiEventsIcon sx={{ color: 'orange' }} />
                    <Typography color="darkblue">
                        {'Điểm tích lũy: '}
                        {Number(rewardPoint).toLocaleString() + ' điểm'}
                    </Typography>
                </Stack>
            )}
        </Box>
    );
    const avatarProps = {
        size: 18,
        variant: 'rounded',
        styleProps: {
            backgroundColor: 'grey',
            color: 'white',
            fontSize: 12,
        },
    };
    return (
        <Box component="nav">
            <Drawer
                container={container}
                variant="temporary"
                open={open}
                onClose={toggleDrawer}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                        ...customScrollbar,
                    },
                }}>
                <Box
                    sx={{
                        height: '100vh',
                    }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ p: 0.5 }}>
                        <LogoBrand
                            styleProps={{ marginLeft: '-14px' }}
                            width="160px"
                            height="60px"
                        />
                        <CustomButton
                            id="drawer-close-button"
                            variant="text"
                            useIconButton={true}
                            onClick={toggleDrawer}>
                            <CloseIcon />
                        </CustomButton>
                    </Stack>
                    {_id ? accountBox : loginBox}
                    <List>
                        {[
                            '/thuoc',
                            '/thuc-pham-chuc-nang',
                            '/san-pham-khac',
                            '/bai-viet-suc-khoe',
                        ].map((i) => {
                            return (
                                <ListItemButton
                                    key={i}
                                    sx={{ px: 0, mx: 3 }}
                                    variant="text"
                                    onClick={() => {
                                        navigate(i);
                                    }}>
                                    <ListItemText
                                        primary={breadcrumbNameMapping[i]}
                                        primaryTypographyProps={{
                                            variant: 'subtitle2',
                                            color: 'black',
                                            fontSize: 16,
                                            pl: 1,
                                        }}
                                    />
                                </ListItemButton>
                            );
                        })}
                        {[
                            {
                                isOpenCollapse: isOpenCollases.account,
                                handleClickCollapse: () => {
                                    setIsOpenCollapses((prevValue) => {
                                        let updatedValue = { ...prevValue };
                                        updatedValue.account =
                                            !updatedValue.account;
                                        return updatedValue;
                                    });
                                },
                                subHeaderText: 'Tài khoản',
                                pathname: '/tai-khoan',
                                listOptions: [
                                    {
                                        name: breadcrumbNameMapping[
                                            '/thong-tin-tai-khoan'
                                        ],
                                        location: '/thong-tin-tai-khoan',
                                        icon: <PersonIcon fontSize="small" />,
                                    },
                                    {
                                        name: breadcrumbNameMapping[
                                            '/lich-su-mua-hang'
                                        ],
                                        location: '/lich-su-mua-hang',
                                        icon: (
                                            <ShoppingBasketIcon fontSize="small" />
                                        ),
                                    },
                                    {
                                        name: breadcrumbNameMapping[
                                            '/dia-chi-nhan-hang'
                                        ],
                                        location: '/dia-chi-nhan-hang',
                                        icon: <PinDropIcon fontSize="small" />,
                                    },
                                ],
                            },
                            {
                                isOpenCollapse: isOpenCollases.page,
                                handleClickCollapse: () => {
                                    setIsOpenCollapses((prevValue) => {
                                        let updatedValue = { ...prevValue };
                                        updatedValue.page = !updatedValue.page;
                                        return updatedValue;
                                    });
                                },
                                subHeaderText: 'Quản lý Page',
                                pathname: '/admin',
                                listOptions: [
                                    {
                                        name: breadcrumbNameMapping[
                                            '/admin/market'
                                        ],
                                        location: '/market',
                                        icon: (
                                            <StorefrontIcon fontSize="small" />
                                        ),
                                    },
                                    {
                                        name: breadcrumbNameMapping[
                                            '/admin/product-item'
                                        ],
                                        location: '/product-item',
                                        icon: <TopicIcon fontSize="small" />,
                                    },
                                    {
                                        name: breadcrumbNameMapping[
                                            '/admin/manufacture'
                                        ],
                                        location: '/manufacture',
                                        icon: <FactoryIcon fontSize="small" />,
                                    },
                                    {
                                        name: breadcrumbNameMapping[
                                            '/admin/ingredient'
                                        ],
                                        location: '/ingredient',
                                        icon: (
                                            <MedicationLiquidIcon fontSize="small" />
                                        ),
                                    },
                                    {
                                        name: breadcrumbNameMapping[
                                            '/admin/article-item'
                                        ],
                                        location: '/article-item',
                                        icon: (
                                            <NewspaperIcon fontSize="small" />
                                        ),
                                    },
                                    {
                                        name: breadcrumbNameMapping[
                                            '/admin/keyword'
                                        ],
                                        location: '/keyword',
                                        icon: <KeyIcon fontSize="small" />,
                                    },
                                ],
                            },
                            {
                                isOpenCollapse: isOpenCollases.list,
                                handleClickCollapse: () => {
                                    setIsOpenCollapses((prevValue) => {
                                        let updatedValue = { ...prevValue };
                                        updatedValue.list = !updatedValue.list;
                                        return updatedValue;
                                    });
                                },
                                subHeaderText: 'Danh sách',
                                pathname: '/admin',
                                listOptions: [
                                    {
                                        name: breadcrumbNameMapping[
                                            '/admin/staff'
                                        ],
                                        location: '/staff',
                                        icon: (
                                            <GroupsRoundedIcon fontSize="small" />
                                        ),
                                    },
                                    {
                                        name: breadcrumbNameMapping[
                                            '/admin/commission-products'
                                        ],
                                        location: '/commission-products',
                                        icon: (
                                            <CollectionsBookmarkIcon fontSize="small" />
                                        ),
                                    },
                                    {
                                        name: breadcrumbNameMapping[
                                            '/admin/salary'
                                        ],
                                        location: '/salary',
                                        icon: <PaymentsIcon fontSize="small" />,
                                    },
                                ],
                            },
                            {
                                isOpenCollapse: isOpenCollases.policy,
                                handleClickCollapse: () => {
                                    setIsOpenCollapses((prevValue) => {
                                        let updatedValue = { ...prevValue };
                                        updatedValue.policy =
                                            !updatedValue.policy;
                                        return updatedValue;
                                    });
                                },
                                subHeaderText: 'Quy chế hoạt động',
                                pathname: '',
                                listOptions: [
                                    {
                                        name: breadcrumbNameMapping[
                                            '/khach-hang-than-thiet'
                                        ],
                                        location: '/khach-hang-than-thiet',
                                        icon: (
                                            <CustomAvatar
                                                children={1}
                                                {...avatarProps}
                                            />
                                        ),
                                    },
                                    {
                                        name: breadcrumbNameMapping[
                                            '/quy-che-hoat-dong'
                                        ],
                                        location: '/quy-che-hoat-dong',
                                        icon: (
                                            <CustomAvatar
                                                children={2}
                                                {...avatarProps}
                                            />
                                        ),
                                    },
                                    {
                                        name: breadcrumbNameMapping[
                                            '/chinh-sach-giao-hang'
                                        ],
                                        location: '/chinh-sach-giao-hang',
                                        icon: (
                                            <CustomAvatar
                                                children={3}
                                                {...avatarProps}
                                            />
                                        ),
                                    },
                                    {
                                        name: breadcrumbNameMapping[
                                            '/chinh-sach-thanh-toan'
                                        ],
                                        location: '/chinh-sach-thanh-toan',
                                        icon: (
                                            <CustomAvatar
                                                children={4}
                                                {...avatarProps}
                                            />
                                        ),
                                    },
                                    {
                                        name: breadcrumbNameMapping[
                                            '/chinh-sach-doi-tra-hang'
                                        ],
                                        location: '/chinh-sach-doi-tra-hang',
                                        icon: (
                                            <CustomAvatar
                                                children={5}
                                                {...avatarProps}
                                            />
                                        ),
                                    },
                                    {
                                        name: breadcrumbNameMapping[
                                            '/chinh-sach-bao-mat'
                                        ],
                                        location: '/chinh-sach-bao-mat',
                                        icon: (
                                            <CustomAvatar
                                                children={6}
                                                {...avatarProps}
                                            />
                                        ),
                                    },
                                    {
                                        name: breadcrumbNameMapping[
                                            '/chinh-sach-thu-thap-va-xu-ly-du-lieu'
                                        ],
                                        location:
                                            '/chinh-sach-thu-thap-va-xu-ly-du-lieu',
                                        icon: (
                                            <CustomAvatar
                                                children={7}
                                                {...avatarProps}
                                            />
                                        ),
                                    },
                                ],
                            },
                        ]
                            .filter((i) =>
                                location.startsWith('/admin')
                                    ? i
                                    : i.pathname !== '/admin'
                            )
                            .map((i, idx) => {
                                const {
                                    isOpenCollapse = false,
                                    subHeaderText = '',
                                    handleClickCollapse = () => {},
                                    listOptions = [],
                                    pathname = '/',
                                } = i;
                                return (
                                    <Fragment key={i.subHeaderText}>
                                        <ListItemButton
                                            sx={{ px: 0, mx: 3 }}
                                            variant="text"
                                            onClick={handleClickCollapse}>
                                            <ListItemText
                                                primary={subHeaderText}
                                                primaryTypographyProps={{
                                                    variant: 'subtitle2',
                                                    color: isOpenCollapse
                                                        ? 'darkblue'
                                                        : 'black',
                                                    fontSize: 16,
                                                    pl: 1,
                                                }}
                                            />
                                            {isOpenCollapse ? (
                                                <ExpandLessIcon />
                                            ) : (
                                                <ExpandMoreIcon />
                                            )}
                                        </ListItemButton>
                                        <CustomCollapseList
                                            id={
                                                'drawer-custom-collapse-list-' +
                                                idx
                                            }
                                            open={isOpenCollapse}
                                            ListProps={{
                                                sx: {
                                                    backgroundColor:
                                                        'aliceblue',
                                                    mx: 3,
                                                    borderRadius: '5px',
                                                    border: '0.5px dotted rgba(0, 0, 139, 0.2)',
                                                },
                                            }}
                                            options={listOptions.map(
                                                ({ name, location, icon }) => {
                                                    return {
                                                        id: i.name,
                                                        onClick: () => {
                                                            navigate(
                                                                pathname +
                                                                    location
                                                            );
                                                            toggleDrawer();
                                                        },
                                                        icon,
                                                        children: (
                                                            <ListItemText
                                                                primary={name}
                                                            />
                                                        ),
                                                    };
                                                }
                                            )}
                                        />
                                    </Fragment>
                                );
                            })}
                        <ListItemButton
                            sx={{ px: 0, mx: 3 }}
                            variant="text"
                            onClick={() => {
                                setIsOpenCollapses((prevValue) => {
                                    let updatedValue = { ...prevValue };
                                    updatedValue.contact =
                                        !updatedValue.contact;
                                    return updatedValue;
                                });
                            }}>
                            <ListItemText
                                primary={'Liên hệ'}
                                primaryTypographyProps={{
                                    variant: 'subtitle2',
                                    color: isOpenCollases.contact
                                        ? 'darkblue'
                                        : 'black',
                                    fontSize: 16,
                                    pl: 1,
                                }}
                            />
                            {isOpenCollases.contact ? (
                                <ExpandLessIcon />
                            ) : (
                                <ExpandMoreIcon />
                            )}
                        </ListItemButton>
                        <CustomCollapseList
                            id={'drawer-custom-collapse-list-6'}
                            alignItems="flex-start"
                            open={isOpenCollases.contact}
                            ListProps={{
                                sx: {
                                    backgroundColor: 'aliceblue',
                                    mx: 3,
                                    borderRadius: '5px',
                                    border: '0.5px dotted rgba(0, 0, 139, 0.2)',
                                },
                            }}
                            useListItemButton={false}
                            options={[
                                {
                                    id: 'phone-contact',
                                    icon: (
                                        <WhatsAppIcon
                                            fontSize="small"
                                            sx={{ mt: -0.2 }}
                                        />
                                    ),
                                    children: (
                                        <ListItemText
                                            primary={
                                                <div>
                                                    <Typography
                                                        component="a"
                                                        href="tel:0704430919"
                                                        fontSize={14}
                                                        fontWeight={600}
                                                        sx={{
                                                            '&:hover': {
                                                                color: 'blue',
                                                                backgroundColor:
                                                                    'transparent',
                                                            },
                                                            textDecoration:
                                                                'none',
                                                            color: 'blue',
                                                        }}>
                                                        070.443.0919
                                                    </Typography>
                                                    <Typography
                                                        fontSize={14}
                                                        fontWeight={600}
                                                        color="rgba(0,0,0,0.6)">
                                                        (Zalo | 08:00 - 21:30)
                                                    </Typography>
                                                </div>
                                            }
                                        />
                                    ),
                                },
                                {
                                    id: 'open-time',
                                    icon: <ScheduleIcon fontSize="small" />,
                                    children: (
                                        <ListItemText
                                            primary={
                                                <Typography fontSize={14}>
                                                    Thứ 2 - Chủ nhật | 06:30 -
                                                    22:15
                                                </Typography>
                                            }
                                        />
                                    ),
                                },
                                {
                                    id: 'address-contact',
                                    icon: <PinDropIcon fontSize="small" />,
                                    children: (
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    aria-label="googlemap-icon"
                                                    component="a"
                                                    href="https://www.google.com/maps/place/Nh%C3%A0+Thu%E1%BB%91c+T%C6%B0+Nh%C3%A2n+Kh%C3%A1nh+Trang/@10.8158692,106.5945157,16.77z/data=!4m6!3m5!1s0x31752b97cd17fb6f:0xb3ee2a6fe105213!8m2!3d10.8158685!4d106.5966005!16s%2Fg%2F11xhbyt7_"
                                                    fontSize={14}
                                                    sx={{
                                                        '&:hover': {
                                                            color: 'blue',
                                                            backgroundColor:
                                                                'transparent',
                                                        },
                                                        textDecoration: 'none',
                                                        color: 'black',
                                                        maxWidth: '95%',
                                                    }}>
                                                    124 Nguyễn Thị Tú, P. Bình
                                                    Hưng Hoà B, Q. Bình Tân, TP.
                                                    HCM
                                                </Typography>
                                            }
                                        />
                                    ),
                                },
                                {
                                    id: 'social-media-contact',
                                    icon: <ShareIcon fontSize="small" />,
                                    children: (
                                        <ListItemText
                                            primary={
                                                <Typography fontSize={14}>
                                                    Xem các bài chia sẻ hay về
                                                    thuốc và bệnh tại{' '}
                                                    <CustomButton
                                                        useIconButton
                                                        aria-label="facebook-icon"
                                                        component="a"
                                                        href="https://www.facebook.com/nhathuockhanhtrang/"
                                                        size="small">
                                                        <FacebookIcon
                                                            fontSize="small"
                                                            sx={{
                                                                color: '#4267b2',
                                                                '&:hover': {
                                                                    color: '#4267b2',
                                                                },
                                                            }}
                                                        />
                                                    </CustomButton>
                                                    <CustomButton
                                                        useIconButton
                                                        aria-label="youtube-icon"
                                                        component="a"
                                                        href="https://www.youtube.com/@duocsitantam124/about"
                                                        size="small">
                                                        <YouTubeIcon
                                                            fontSize="small"
                                                            sx={{
                                                                color: 'red',
                                                                '&:hover': {
                                                                    color: 'red',
                                                                },
                                                            }}
                                                        />
                                                    </CustomButton>
                                                </Typography>
                                            }
                                        />
                                    ),
                                },
                            ]}
                        />
                    </List>
                </Box>
            </Drawer>
        </Box>
    );
}
