import { Stack, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import cateImageJson from 'data/cateImage.json';

import { CustomAvatar } from 'UI';

export default function CategoryCardItem({
    id = Math.random() * 1000,
    cateLv1Path = '',
    name = '',
    slug = '/',
    ...otherProps
}) {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            sx={{
                minHeight: 68,
                borderRadius: '10px',
                backgroundColor: 'white',
            }}>
            <CustomAvatar
                src={cateImageJson?.[slug]}
                variant="rounded"
                styleProps={{ p: 1 }}
                alt=""
                size={60}
                id={id + '-custom-avatar'}
            />
            <Stack width="100%">
                <Typography
                    component="h2"
                    fontSize="16px"
                    sx={{ maxWidth: '95%', whiteSpace: 'normal' }}
                    fontWeight={500}>
                    {name}
                </Typography>
                <Link
                    component={RouterLink}
                    underline="none"
                    sx={{
                        fontSize: '14px',
                        ':hover': {
                            textDecoration: 'underline',
                        },
                    }}
                    to={`/${cateLv1Path}${slug}`}>
                    Xem thêm
                </Link>
            </Stack>
        </Stack>
    );
}
